import { useDispatch, useSelector } from "react-redux";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { DynamicTable } from "../../../tables/DynamicTable";
import { useNavigate } from "react-router-dom";
import PageName from "../../../component/PageName";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { userLogOut } from "../../../store/features/userSlice";
import VehicleCard from "../VehicleCard";
import Pagination from "../../../component/Pagination";

const Vehicle = () => {
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allRawData, setallRawData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  //------------------------------------All vehicle Details----------------------------//
  const driverManageData = async () => {
    setDataLoading(true);
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `vehicle/all?&page=${pageNumber}&perPage=${25}&status=1`,
      });
      if (response && response.data && response.data.data) {
        setallRawData(response.data.data);
        settotalCount(response.data.totalCount);
        setDataLoading(false);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
    // console.log(response);
  };

  useEffect(() => {
    if (isLogged) {
      driverManageData();
    }
  }, [isLogged, deleteDeatils,pageNumber]);

  const tableHead = [
    "S.No.",
    "Vehicle ID",
    "Location",
    "Owner Name",
    "Vehicle Model",
    "Action",
  ];

  const [allDriver, setAllDriver] = useState([]);

  useEffect(() => {
    const modifiedData = allRawData.map((vehicle) => {
      return {
        item01: vehicle.vehicleId,
        item02: vehicle.location,
        item03: vehicle.ownerName,
        item04: vehicle.additionalCmt,
        item05: vehicle.vehicleModel,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: vehicle.vehicleId,
      };
    });
    setAllDriver(modifiedData);
  }, [allRawData]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Vehicle" href={RoutePath.AddVehicle} />
        <VehicleCard isActive="active" />
        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allDriver}
            DeleteUrl={"vehicle/delete"}
            eyeButton={RoutePath.EditVehicleDetailLink}
            editButton={RoutePath.EditVehicleDetailLink}
            isDataLoading={dataLoading}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
