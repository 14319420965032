import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialogBox from "../../../component/DeleteDialogBox";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import Dropdown from "../../../component/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  formateDate,
  formatDateToISO,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import { useParams } from "react-router-dom";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";
import { useDispatch } from "react-redux";
// import AutoDropDown from "../../../component/AutoDropDown";
// import AutoInputField from "../../../component/AutoInputField";
import { userLogOut } from "../../../store/features/userSlice";
import CustomerDropDown from "../../../component/dropdown/CustomerDropDown";

let initialValues = {
  startStation: "",
  endStation: "",
  firstName: "",
  lastName: "",
  customerName: "",
  mobile: "",
  email: "",
  locality: "",
  bookingDate: "",
  proposedDeliveryDate: "",
  senderName: "",
  senderGgt: "",
  senderLocality: " ",
  fromState: "",
  fromCity: "",
  fromPincode: "",
  receiverName: "",
  receiverGgt: "",
  receiverLocality: "",
  toState: "",
  toCity: "",
  toPincode: " ",
  receiptNo: "",
  refNo: "",
  insurance: "",
  vppAmount: "",
  toPay: "",
  weight: "",
  amount: "",
  additionalCmt: "",
  freight: "",
  ins_vpp: "",
  cgst: "",
  sgst: "",
  igst: "",
  billTotal: "",
  grandTotal: "",
};

const EditBookOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allBookingRaw, setAllBookingRaw] = useState();
  const [startStationId, setStartStationId] = useState();
  const [endStationId, setEndStationId] = useState();
  const [fromOrderId, setFromOrderId] = useState();
  const [toOrderId, setToOrderId] = useState();
  // const [customerId, setCustomerId] = useState(0);
  const [newcustomerId,setNewcustomerId]= useState();

  useEffect(() => {
    if (startStationId) {
      setFieldValue("startStation", startStationId);
    }
    if (endStationId) {
      setFieldValue("endStation", endStationId);
    }
  }, [startStationId, endStationId]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const userCreateSchema = Yup.object({
    startStation: Yup.string().required("Start Station should not be empty"),
    endStation: Yup.string().required(
      "Destination Station should not be empty"
    ),
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    mobile: Yup.number().required("Mobile Number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    bookingDate:  Yup.date()
    .required("Booking date should not be empty")
    .min(today, "Booking date should be today or a future date"),
    proposedDeliveryDate: Yup.date()
    .required("Delivery date should not be empty")
    .min(today, "Delivery date should be today or a future date"),
    senderName: Yup.string().required("Sender Name should not be empty"),
    senderGgt: Yup.string().required("GST No. should not be empty"),
    senderLocality: Yup.string().required("Locality should not be empty"),
    fromState: Yup.string().required("State should not be empty"),
    fromCity: Yup.string().required("City should not be empty"),
    fromPincode: Yup.number().required("Pin code should not be empty"),
    receiverName: Yup.string().required("Receiver Name should not be empty"),
    receiverGgt: Yup.string().required("GST NO. should not be empty"),
    receiverLocality: Yup.string().required("Locality should not be empty"),
    toState: Yup.string().required("State should not be empty"),
    toCity: Yup.string().required("City should not be empty"),
    toPincode: Yup.number().required("Pin code should not be empty"),
    // receiptNo: Yup.number().required("Receipt No.*"),
    // refNo: Yup.number().required("Ref No.*"),
    // insurance: Yup.number().required("Insurance No.*"),
    // vppAmount: Yup.number().required("VPP Amount*"),
    // toPay: Yup.number().required("To Pay/PAid*"),
    // weight: Yup.number().required("weight*"),
    // amount: Yup.number().required("Amount*"),
    freight: Yup.number().required("Freight*"),
    ins_vpp: Yup.number().required("INS/VPP*"),
    cgst: Yup.number().required("CGST*"),
    sgst: Yup.number().required("SGST*"),
    igst: Yup.number().required("IGST*"),
    billTotal: Yup.number().required("Billl T*"),
    grandTotal: Yup.number().required("Grand Total*"),
  });

  //API Call for updatation of from details and to details--------------------------------------
  const addressUpdate = async (orderAddressId, type) => {
    // console.log("orderAddressId:", orderAddressId);
    let data = new FormData();
    if (type === "receiver") {
      // Appending receiver's address details
      data.append("personName", values.receiverName );
      data.append("address", values.receiverLocality);
      // data.append("address2", ""); // Assuming no value for address2
      data.append("city", values.toCity );
      data.append("state", values.toState);
      data.append("pincode", values.toPincode);
      data.append("gstNumber", values.receiverGgt);
    } else if (type === "sender") {
      // Appending sender's address details
      data.append("personName", values.senderName);
      data.append("address", values.senderLocality );
      // data.append("address2", ""); // Assuming no value for address2
      data.append("city", values.fromCity );
      data.append("state", values.fromState);
      data.append("pincode", values.fromPincode);
      data.append("gstNumber", values.senderGgt);
    }
   
    try {
      const response = await getAxiosWithToken({
        method: "patch",
        url: `order/address/update/${orderAddressId}`, // API endpoint for updating address
        data: data,
      });

      // Handle API response
      if (response.data.success) {
        alertResponseMsgSuccess(response.data.msg);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alertResponseMsgError(error.response?.data?.msg || "An error occurred.");
    }
  };

  let { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userCreateSchema,

      onSubmit: async (values, action) => {
        setButtonLoader(true);

        // there we are comparing all value with response valuse from api
        // let data = await valuesCheck(values, allBookingRaw);
        const productsWithNoId = products.filter(
          (product) => !product.orderProductId
        );
       
        let updatedvalue = await valuesCheck(values, allBookingRaw);
        let data = new FormData();
        for (const key in updatedvalue) {
          if (updatedvalue.hasOwnProperty(key)) {
            const value = updatedvalue[key];
            // console.log(`Appending ${key}: ${value}`);
            data.append(key, value);
          }
        }
        if (productsWithNoId.length > 0) {
          productsWithNoId.forEach((product, index) => {
            for (const key in product) {
              if (product.hasOwnProperty(key)) {
                data.append(
                  `newProductDetails[${index}][${key}]`,
                  product[key]
                );
              }
            }
          });
        }

        let bookingOrderId;
        if (params && params.bookingOrderId && params.bookingOrderId !== " ") {
          bookingOrderId = params.bookingOrderId;
        }
        //------Condition checking if valure update--------------------------------
        if (
          values.senderName !== allBookingRaw.fromAddress.personName ||
          values.senderGgt !== allBookingRaw.fromAddress.gstNumber ||
          values.senderLocality !== allBookingRaw.fromAddress.address ||
          values.fromState !== allBookingRaw.fromAddress.state ||
          values.fromCity !== allBookingRaw.fromAddress.city ||
          values.fromPincode !== allBookingRaw.fromAddress.pincode
        ) {
          // Call the addressUpdate for the sender only if the values have changed
          await addressUpdate(fromOrderId, "sender");
        }
        if (
          values.receiverName !== allBookingRaw.toAddress.personName ||
          values.receiverGgt !== allBookingRaw.toAddress.gstNumber ||
          values.receiverLocality !== allBookingRaw.toAddress.address || // Corrected this line
          values.toState !== allBookingRaw.toAddress.state ||
          values.toCity !== allBookingRaw.toAddress.city ||
          values.toPincode !== allBookingRaw.toAddress.pincode
        ) {
          // Call the addressUpdate for the receiver only if the values have changed
          await addressUpdate(toOrderId, "receiver");
        }
        if(newcustomerId){
          data.append("customerId", newcustomerId);
        }

        try {
          const response = await getAxiosWithToken({
            method: "Patch",
            url: `booking/update/${bookingOrderId}`, // Adjust the API endpoint as needed
            data: data,
          });
          // API response 200 201
          if (response.data.success) {
            alertResponseMsgSuccess(response.data.msg);
            action.resetForm();
            navigate(-1);
            setButtonLoader(false);
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(error.response.data.msg);
          setButtonLoader(false);
        }
        //update address
      },
    });

  const getBookingDeatils = async (bookingOrderId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `booking/details/${bookingOrderId}`,
      });
      if (response && response.data && response.data.data) {
        setAllBookingRaw(response.data.data.bookingDetails);
        setFromOrderId(
          response.data.data.bookingDetails.fromAddress.orderAddressId
        );
        setToOrderId(
          response.data.data.bookingDetails.toAddress.orderAddressId
        );
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  useEffect(() => {
    let bookingDetail = allBookingRaw;
    if (bookingDetail) {
      values.startStation = bookingDetail.startStation;
      values.endStation = bookingDetail.endStation;
      values.firstName = bookingDetail.firstName;
      values.middleName = bookingDetail.middleName;
      values.lastName = bookingDetail.lastName;
      values.mobile = bookingDetail.mobile;
      values.email = bookingDetail.email;
      values.locality = bookingDetail.locality;
      values.bookingDate = formatDateToISO(
        formateDate(bookingDetail.bookingDate)
      );
      values.proposedDeliveryDate = formatDateToISO(
        formateDate(bookingDetail.proposedDeliveryDate)
      );
      values.senderName = bookingDetail.fromAddress.personName;
      values.senderGgt = bookingDetail.fromAddress.gstNumber;
      values.senderLocality = bookingDetail.fromAddress.address;
      values.fromState = bookingDetail.fromAddress.state;
      values.fromCity = bookingDetail.fromAddress.city;
      values.fromPincode = bookingDetail.fromAddress.pincode;
      values.receiverName = bookingDetail.toAddress.personName;
      values.receiverGgt = bookingDetail.toAddress.gstNumber;
      values.receiverLocality = bookingDetail.toAddress.address;
      values.toState = bookingDetail.toAddress.state;
      values.toCity = bookingDetail.toAddress.city;
      values.toPincode = bookingDetail.toAddress.pincode;
      values.additionalCmt = bookingDetail.additionalCmt;
      values.freight = bookingDetail.freight;
      values.ins_vpp = bookingDetail.ins_vpp;
      values.cgst = bookingDetail.cgst;
      values.sgst = bookingDetail.sgst;
      values.igst = bookingDetail.igst;
      values.billTotal = bookingDetail.billTotal;
      values.grandTotal = bookingDetail.grandTotal;
    }
  }, [allBookingRaw]);

  useEffect(() => {
    if (params && params.bookingOrderId && params.bookingOrderId !== " ") {
      getBookingDeatils(params.bookingOrderId);
    } else {
      window.location.href = "/booking";
    }
  }, [params]);

  // addresUpdate();

  const [lastIndexValue, setLastIndexValue] = useState(0);
  const [productCount, setProductCount] = useState(1);
  const [products, setProducts] = useState([{ id: 1 }]);

  useEffect(() => {
    if (allBookingRaw && allBookingRaw.products) {
      const allProduct = allBookingRaw.products;
      setProducts(
        allProduct.map((product, index) => ({
          ...product,
          id: index + 1,
          isChange: true,
        }))
      );
      setLastIndexValue(allProduct.length);
      setProductCount(allProduct.length);
    }
  }, [allBookingRaw]);

  const addRowInProduct = () => {
    const newId = lastIndexValue + 1;
    setLastIndexValue(newId);
    setProductCount(productCount + 1);
    setProducts((prevProducts) => [...prevProducts, { id: newId }]);
  };

  const productUpdate = async (product) => {
    setButtonLoader(true);

    const orderProductIdToFilter = await product.orderProductId;
    const newProductArray = await allBookingRaw.products.filter(
      (item) => item.orderProductId === orderProductIdToFilter
    );
    let data = await valuesCheck(product, newProductArray[0]);
    try {
      const response = await getAxiosWithToken({
        method: "PATCH",
        url: `order/product/update/${product.orderProductId}`,
        data: data,
      });
      // console.log(response);
      if (response.data.success) {
        alertResponseMsgSuccess(response.data.msg);
        setButtonLoader(false);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.log(error);
      // Check if error.response exists before accessing its properties
      if (error.response && error.response.data && error.response.data.msg) {
        alertResponseMsgError(error.response.data.msg);
      } else {
        alertResponseMsgError("An error occurred"); // Provide a generic error message
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});
  const [deleteModelOrderId, setDeleteModelOrderId] = useState("");

  const removeRowInProduct = (orderProductId) => {
    if (!products || products.length === 0) {
      return;
    }
    const productToRemove = products.find(
      (product) => product.id === orderProductId
    );
    if (productToRemove) {
      if (productToRemove.orderProductId) {
        setOpen(!open);
        setDeleteModelData({
          deleteUrl: `order/product/delete/${productToRemove.orderProductId}`,
          headerText: `This is Delete Confirmation`,
          paraText: `Tihs ID ${productToRemove.orderProductId} will be deleted.`,
        });
        setDeleteModelOrderId(productToRemove.orderProductId);
      } else {
        const updatedProducts = products.filter(
          (product) => product.id !== orderProductId
        );
        // Update IDs for the remaining products
        const updatedProductsWithNewIds = updatedProducts.map(
          (product, index) => ({ ...product, id: index + 1 })
        );
        setLastIndexValue(lastIndexValue - 1);
        setProductCount(productCount - 1);
        setProducts(updatedProductsWithNewIds);
      }
    }
  };
  const [allItemAmount, setAllItemAmount] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [cgstTotal, setCgstTotal] = useState(0);
  const [sgstTotal, setSgstTotal] = useState(0);
  const [igstTotal, setIgstTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleProductChange = async (e, productId) => {
    const { name, value } = e.target;
    if (name === "amount" && isNaN(value)) {
      return false;
    }

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [name]: value } : product
      )
    );
  };

  useEffect(() => {
    handleGstCalculate();
  }, [products]);

  const handleGstCalculate = async () => {
    let amount = 0;

    await products.map((product) => {
      amount =
        parseInt(amount) + (product.amount ? parseInt(product.amount) : 0);
    });
    setAllItemAmount(amount);
  };
  const freightAmount = values.freight;
  const ins_vppAmount = values.ins_vpp;

  useEffect(() => {
    if (
      freightAmount !== null &&
      ins_vppAmount !== null &&
      allItemAmount !== null
    ) {
      const totalAmount =
        Number(freightAmount) + Number(ins_vppAmount) + allItemAmount;
      setTotalBill(totalAmount);
    }
  }, [freightAmount, ins_vppAmount, allItemAmount]);
  values.billTotal = `${totalBill || null}`;
  //------------------------------------------gst calculate-----------------------------------------//
  const cgstValueInPercentage = values.cgst;
  const sgstValueInPercentage = values.sgst;
  const igstValueInPercentage = values.igst;

  useEffect(() => {
    const gstFunction = (gstPercentage) => {
      return (totalBill / 100) * gstPercentage;
    };

    if (
      cgstValueInPercentage <= 100 &&
      sgstValueInPercentage <= 100 &&
      igstValueInPercentage <= 100
    ) {
      const cgst = gstFunction(cgstValueInPercentage);
      const roundedCgst = cgst.toFixed(2);
      setCgstTotal(roundedCgst);

      const sgst = gstFunction(sgstValueInPercentage);
      const roundedSgst = sgst.toFixed(2);
      setSgstTotal(roundedSgst);

      const igst = gstFunction(igstValueInPercentage);
      const roundedIgst = igst.toFixed(2);
      setIgstTotal(roundedIgst);
    } else {
      // Handle error or invalid input
      console.error("Invalid GST percentage provided.");
    }
  }, [
    cgstValueInPercentage,
    sgstValueInPercentage,
    igstValueInPercentage,
    totalBill,
  ]);

  useEffect(() => {
    if (
      cgstTotal !== null &&
      sgstTotal &&
      igstTotal !== null &&
      totalBill !== null
    ) {
      const GrandTotalAmount =
        Number(cgstTotal) + Number(sgstTotal) + Number(igstTotal) + totalBill;
      setGrandTotal(GrandTotalAmount);
    }
  }, [cgstTotal, sgstTotal, igstTotal, totalBill]);

  values.grandTotal = `${grandTotal || null}`;
  //-------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    if (isDeleted) {
      const updatedProducts = products.filter(
        (product) => product.orderProductId !== deleteModelOrderId
      );
      // Update IDs for the remaining products
      const updatedProductsWithNewIds = updatedProducts.map(
        (product, index) => ({ ...product, id: index + 1 })
      );
      setLastIndexValue(lastIndexValue - 1);
      setProductCount(productCount - 1);
      setProducts(updatedProductsWithNewIds);
    }
  }, [isDeleted]);
  //------------------------------------------------------------------------------------
   // -------------------------get custtomer detail( firstName, middleNale, lastMane)---------------------//

   const [allCustomerRaw, setAllCustomerRaw] = useState([]);
  //  console.log("allCustomerRaw", allCustomerRaw);
 
   const userManageData = async () => {
     const response = await getAxiosWithToken({
       method: "get",
       url: "customer/all",
     });
     if (response && response.data && response.data.data) {
       setAllCustomerRaw(response.data.data);
     }
     // console.log(response);
   };
 
   useEffect(() => {
     userManageData();
   }, []);
  //-------------------------Customer Details---------------------------------------------- 
  //-------------------------Customer Details---------------------------------------------- 
  let [CustomerDetailsApiRaw, setCustomerDetailsApiRaw] = useState({});

  const [customerData,setcustomerData]= useState();


  // console.log("customerData",customerData)
  const getCustomerDeatil = async (newcustomerId)=>{
    if(newcustomerId){
      try{
        const response = await getAxiosWithToken({
          method: "get",
          url:`customer/details/${newcustomerId}`,
        });
        if (response && response.data && response.data.data) {
          setcustomerData(response.data.data)
          values.firstName = `${
            response.data.data.firstName ? response.data.data.firstName : ""
          }`;
          values.middleName = `${
            response.data.data.middleName ? response.data.data.middleName : ""
          }`;
          values.lastName = `${
            response.data.data.lastName ? response.data.data.lastName : ""
          }`;
          values.mobile = `${
            response.data.data.mobile ? response.data.data.mobile : ""
          }`;
          values.email = `${
            response.data.data.email ? response.data.data.email : ""
          }`;

          return {
            firstName: response.data.data?.firstName,
            middleName: response.data.data?.middleName,
            lastName: response.data.data?.lastName,
            mobile: response.data.data?.mobile,
            email: response.data.data?.email,
          };
        } else{
          setCustomerDetailsApiRaw({
                  firstName: "",
                  middleName: "",
                  lastName: "",
                });
        }
        if(response){
          console.log(response.data.data)
         
        }
      }catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(()=>{
    getCustomerDeatil(newcustomerId);
  },[newcustomerId])

  useEffect(() => {
    if (CustomerDetailsApiRaw.firstName) {
      values.firstName = `${
        CustomerDetailsApiRaw.firstName ? CustomerDetailsApiRaw.firstName : ""
      }`;
      values.middleName = `${
        CustomerDetailsApiRaw.middleName ? CustomerDetailsApiRaw.middleName : ""
      }`;
      values.lastName = `${
        CustomerDetailsApiRaw.lastName ? CustomerDetailsApiRaw.lastName : ""
      }`;
      values.mobile = `${
        CustomerDetailsApiRaw.mobile ? CustomerDetailsApiRaw.mobile : ""
      }`;
      values.email = `${
        CustomerDetailsApiRaw.email ? CustomerDetailsApiRaw.email : ""
      }`;
    } else {
      // console.log(CustomerDetailsApiRaw);
      values.customerName = " ";
      values.mobile = " ";
      values.email = " ";
    }
  }, [CustomerDetailsApiRaw]);
 

  return (
    <div>
      <div className="p-8">
        <BackButton btnName="Edit Book Order" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            {/* <div className='div col-span-6 text-lg font-semibold '>Vehicle Details</div> */}
            <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Start Station
              </label>
              <Dropdown
                label={"Station"}
                name={"startStation"}
                SetStationId={setStartStationId}
                value={values.startStation || ""}
              />
              <InputError
                error={errors.startStation}
                touched={touched.startStation}
              />
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Destination Station
              </label>
              <div className="w-full">
                <Dropdown
                  label={"Station"}
                  name={"startStation"}
                  SetStationId={setEndStationId}
                  value={values.endStation || ""}
                />
                <InputError
                  error={errors.endStation}
                  touched={touched.endStation}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Date of Booking
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="bookingDate"
                  value={values.bookingDate || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Booking"
                />
                <InputError
                  error={errors.bookingDate}
                  touched={touched.bookingDate}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Proposed Date of Delivery
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="proposedDeliveryDate"
                  value={values.proposedDeliveryDate || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Proposed Date of Delivery"
                />
                <InputError
                  error={errors.proposedDeliveryDate}
                  touched={touched.proposedDeliveryDate}
                />
              </div>
            </div>
            {/* <label className="col-span-6 block my-2 text-sm font-medium text-gray-900">
              Customer Name
            </label> */}
            <div className="col-span-6 py-2 items-center">
            <CustomerDropDown setNewcustomerId={setNewcustomerId}/>
            {/* <select
                defaultValue={customerId}
                name="customerId"
                onChange={handleChangeCustomer}
                className=" w-full rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value={0} hidden disabled>
                  Select the customer
                </option>
                <option value={"none"}>None</option>
                {allCustomerRaw.map((option, index) => (
                  <option key={index} value={option.username}>
                    {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName}
                  </option>
                ))}
              </select> */}
            </div>
            {/*there was select customer option  */}
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="firstName"
                  value={values.firstName || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="First Name"
                />
                <InputError
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="middleName"
                  value={values.middleName || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Middle Name"
                />
                <InputError
                  error={errors.middleName}
                  touched={touched.middleName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="lastName"
                  value={values.lastName || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Last Name"
                />
                <InputError
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={values.mobile || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Email Address
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={values.email || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold mb-5 mt-5 ">
              From <p className="text-sm">(Address)</p>{" "}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderName"
                  value={values.senderName || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="User Name"
                />
                <InputError
                  error={errors.senderName}
                  touched={touched.senderName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                GST Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderGgt"
                  value={values.senderGgt || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="GST Number"
                />
                <InputError
                  error={errors.senderGgt}
                  touched={touched.senderGgt}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderLocality"
                  value={values.senderLocality || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.senderLocality}
                  touched={touched.senderLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"fromState"}
                  value={values.fromState || " "}
                />
                <InputError
                  error={errors.fromState}
                  touched={touched.fromState}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"fromCity"}
                  value={values.fromCity || " "}
                  stateId={values.fromState}
                />
                <InputError
                  error={errors.fromCity}
                  touched={touched.fromCity}
                />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="fromPincode"
                  value={values.fromPincode || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.fromPincode}
                  touched={touched.fromPincode}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold mb-5 mt-5 ">
              To <p className="text-sm">(Address)</p>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverName"
                  value={values.receiverName || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Receiver Name"
                />
                <InputError
                  error={errors.receiverName}
                  touched={touched.receiverName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                GST Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverGgt"
                  value={values.receiverGgt || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="GST Number"
                />
                <InputError
                  error={errors.receiverGgt}
                  touched={touched.receiverGgt}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverLocality"
                  value={values.receiverLocality || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.receiverLocality}
                  touched={touched.receiverLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className="  block mb-2 text-sm font-medium text-gray-900">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"toState"}
                  value={values.toState || " "}
                />

                <InputError error={errors.toState} touched={touched.toState} />
              </div>
            </div>
            <div className="col-span-3 gap-6  py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"toCity"}
                  value={values.toCity || " "}
                  stateId={values.toState}
                />

                <InputError error={errors.toCity} touched={touched.toCity} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="toPincode"
                  value={values.toPincode || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.toPincode}
                  touched={touched.toPincode}
                />
              </div>
            </div>
          </div>
          <div className=" text-lg font-semibold text-left mb-5 mt-10">
            <p className="text-xl font-bold">Product Details</p>
          </div>
          <div className="w-100 mb-2">
            {products.map((product) => (
              <div
                key={product.id}
                id="createDivBox"
                className="grid md:grid-flow-col items-end gap-x-4 mb-2 w-100"
              >
                <div className="py-2 items-center">
                  <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                    S. No.
                  </label>
                  <div className="w-12">
                    <input
                      value={product.id + "."}
                      disabled
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    />
                  </div>
                </div>
                <div className=" col-span-1 py-2  items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Receipt No.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="receiptNo"
                      value={product.receiptNo || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Receipt No."
                    />
                    <InputError
                      error={errors.receiptNo}
                      touched={touched.receiptNo}
                    />
                  </div>
                </div>
                <div className=" col-span-1 py-2  items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Ref. No.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="refNo"
                      value={product.refNo || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Ref. No."
                    />
                    <InputError error={errors.refNo} touched={touched.refNo} />
                  </div>
                </div>
                <div className="col-span-1  py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Insurance
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="insurance"
                      value={product.insurance || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Insurance"
                    />
                    <InputError
                      error={errors.insurance}
                      touched={touched.insurance}
                    />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    VPP Amount
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="vppAmount"
                      value={product.vppAmount || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="VPP Amount"
                    />
                    <InputError
                      error={errors.vppAmount}
                      touched={touched.vppAmount}
                    />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    To Pay/Paid
                  </label>
                  <div className="w-full ">
                    <select
                      name="toPay"
                      value={product.toPay || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    >
                      <option value="">None</option>
                      <option value="Pay">Pay</option>
                      <option value="Paid">Paid</option>
                    </select>
                    <InputError error={errors.toPay} touched={touched.toPay} />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Weight Kgs.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="weight"
                      value={product.weight || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Weight Kgs."
                    />
                    <InputError
                      error={errors.weight}
                      touched={touched.weight}
                    />
                  </div>
                </div>
                <div className="col-span-2 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Amount
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="amount"
                      value={product.amount || " "}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Amount"
                    />
                    <InputError
                      error={errors.amount}
                      touched={touched.amount}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex gap-3 justify-center m-2">
                  {product.orderProductId && (
                    <div className="col-span-2 flex gap-3 justify-center">
                      {product.isChange ? (
                        <div onClick={() => productUpdate(product)}>
                          <PrimeButton
                            btnName="Update"
                            btnType="button"
                            className="bg-none hover:bg-green-800"
                            buttonLoader={buttonLoader}
                          />
                        </div>
                      ) : (
                        <PrimeButton
                          btnName="Update"
                          btnType="button"
                          className="bg-gray-600 hover:bg-gray-600 cursor-not-allowed"
                        />
                      )}
                      <div
                        className={product?.id}
                        onClick={() => removeRowInProduct(product?.id)}
                      >
                        <PrimeButton
                          btnName="Remove"
                          btnType="button"
                          className="bg-red-700 hover:bg-red-800"
                        />
                      </div>
                    </div>
                  )}

                  {/* Additional code for the Remove button outside the condition */}
                  {product.orderProductId ? (
                    ""
                  ) : (
                    <div
                      // className={product?.id}
                      onClick={() => removeRowInProduct(product?.id)}
                    >
                      <PrimeButton
                        btnName="Remove"
                        btnType="button"
                        className="bg-red-700 hover:bg-red-800"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div onClick={addRowInProduct} className="w-full mb-4">
            <PrimeButton
              btnName="+ Add Item"
              btnType="button"
              className=" sm:w-full"
            />
          </div>
          <div className="grid md:grid-cols-6 justify-between p-0">
            <div className=" col-span-4 w-full text-left py-2">
              <label className="block mb-2  font-medium text-gray-900 text-left">
                Additional Comments
              </label>
              <textarea
                name="additionalCmt"
                id=""
                cols="40"
                rows="10"
                className="rounded-inputBRedius bg-inputBgColor w-full h-full p-2"
                placeholder="Additional Comments"
                value={values.additionalCmt || " "}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="col-span-2 items-center">
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  FREIGHT
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="freight"
                    value={values.freight || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="FREIGHT"
                  />
                  <InputError
                    error={errors.freight}
                    touched={touched.freight}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36  text-sm font-medium text-gray-900">
                  INS/VPP
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="ins_vpp"
                    value={values.ins_vpp || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="INS/VPP"
                  />
                  <InputError
                    error={errors.ins_vpp}
                    touched={touched.ins_vpp}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Bill Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="billTotal"
                    value={values.billTotal || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder=" Bill T"
                  />
                  <InputError
                    error={errors.billTotal}
                    touched={touched.billTotal}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  CGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="cgst"
                    value={values.cgst || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="CGST"
                  />
                  <InputError error={errors.cgst} touched={touched.cgst} />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  SGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="sgst"
                    value={values.sgst || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="SGST"
                  />
                  <InputError error={errors.sgst} touched={touched.sgst} />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  IGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="igst"
                    value={values.igst || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="IGST"
                  />
                  <InputError error={errors.igst} touched={touched.igst} />
                </div>
              </div>

              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Grand Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="grandTotal"
                    value={values.grandTotal || " "}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Grand Total"
                  />
                  <InputError
                    error={errors.grandTotal}
                    touched={touched.grandTotal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
          </div>
        </form>
      </div>
      <DeleteDialogBox
        open={open}
        setOpen={setOpen}
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
        data={deleteModelData}
      />
    </div>
  );
};
export default EditBookOrder;
