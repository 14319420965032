import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { useNavigate } from "react-router-dom";
import PageName from "../../../component/PageName";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import DriverCard from "../DriverCard";
import Pagination from "../../../component/Pagination";
import SearchBar from "../../../component/props/SearchBar";

const DeactiveDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableHead = ["S.No.", "Driver ID", "Name", "Contact", "Action"];
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);

  const [dataLoading, setDataLoading] = useState(false);
  const [allDriverRaw, setallDriverRaw] = useState([]);
  const [allDriver, setAllDriver] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const driverManageData = async () => {
    // setDataLoading(true)
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `driver/all?&page=${pageNumber}&perPage=${25}&status=2&search=${
          searchValue || " "
        }`,
      });
      if (response && response.data && response.data.data) {
        setallDriverRaw(response.data.data);
        settotalCount(response.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }

    // console.log(response);
  };

  useEffect(() => {
    if (isLogged) {
      driverManageData();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  useEffect(() => {
    if (allDriverRaw.length <= 0) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  }, [allDriverRaw]);

  useEffect(() => {
    const modifiedData = allDriverRaw.map((driver) => {
      return {
        item01: driver.username,
        item02: driver.firstName
          ? `${driver.firstName} ${driver.middleName} ${driver.lastName}`
          : `${driver.firstName} ${driver.lastName}`,
        item03: driver.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: driver.username,
      };
    });
    setAllDriver(modifiedData);
  }, [allDriverRaw]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div>
        <PageName pageName="Manage Driver" href={RoutePath.AddDriverForm} />
        <DriverCard isActive="deactive" allDriverRaw={allDriverRaw} />
        <SearchBar handleInputChange={handleInputChange} value={searchValue} />

        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allDriver}
            DeleteUrl={"driver/delete"}
            eyeButton={RoutePath.EditDriverDetailLink}
            editButton={RoutePath.EditDriverDetailLink}
            isDataLoading={dataLoading}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default DeactiveDriver;
