import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { PrimeButton } from "../../../component/PrimeButton";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import React, { useRef } from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";

const ShareDetail = ({ unqiueKey, pageName }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [allBookingRaw, setAllBookingRaw] = useState();

  const [fromOrderId, setFromOrderId] = useState();
  const [toOrderId, setToOrderId] = useState();
  const [productDetail, setproductDetail] = useState([]);

  // const [customerId, setCustomerId] = useState(0);
  // const [newcustomerId,setNewcustomerId]= useState();
  // console.log("allBookingRaw",allBookingRaw)
  // console.log("fromOrderId",fromOrderId)
  //-----------------------------------booking gst details---------------------------------------------------
  const getBookingDeatils = async (bookingOrderId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `booking/details/${bookingOrderId}`,
      });
      if (response && response.data && response.data.data) {
        setAllBookingRaw(response.data.data.bookingDetails);
        setFromOrderId(response.data.data.bookingDetails.fromAddress);
        setToOrderId(response.data.data.bookingDetails.toAddress);
        setproductDetail(response.data.data.bookingDetails.products);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          // await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  // console.log("unqiueKey",unqiueKey)
  useEffect(() => {
    if (unqiueKey && pageName === "booking" && unqiueKey !== " " && open) {
      getBookingDeatils(unqiueKey);
    } else {
      // window.location.href = "/booking";
    }
  }, [unqiueKey, open]);

  //----------------------------------- quotation details---------------------------------------------------
  const getquotationDeatils = async (bookingOrderId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `quotation/details/${bookingOrderId}`,
      });
      if (response && response.data && response.data.data) {
        setAllBookingRaw(response.data.data.quotationDetails);
        setFromOrderId(response.data.data.quotationDetails.fromAddress);
        setToOrderId(response.data.data.quotationDetails.toAddress);
        setproductDetail(response.data.data.quotationDetails.products);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          // await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  // console.log("unqiueKey",unqiueKey)
  useEffect(() => {
    if (unqiueKey && pageName === "quotation" && unqiueKey !== " ") {
      getquotationDeatils(unqiueKey);
    } else {
      // window.location.href = "/booking";
    }
  }, [unqiueKey]);

  // Function to handle button click and open the confirmation popup
  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  // Function to handle cancellation of the action
  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };
  const tableHeadbooking = [
    "No. ",
    "receiptNo",
    "refNo",
    "insurance",
    "vppAmount",
    "toPay",
    "weight",
    "amount",
  ];
  const tableHeadqut = [
    "No. ",
    "No. of Parcel",
    "toPay",
    "Weight (Kgs)",
    "amount",
  ];
  //--------------------------product head change accoerding to page name------------------------------------------
  const [tableHead, settableHead] = useState([]);
  const [toggal, settoggal] = useState(false);
  //if toggal is true it will show booking gst details and flase for quotaion details

  useEffect(() => {
    if (pageName === "booking") {
      settableHead(tableHeadbooking);
      settoggal(true);
    } else {
      settableHead(tableHeadqut);
    }
  }, [pageName]);

  //--------------------------------------print button----------------------------------------------
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const printContent = document.getElementById("print-section").innerHTML;

    // Get the Tailwind CSS stylesheets dynamically
    const tailwindStyles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return styleSheet.ownerNode.outerHTML; // Capture linked or inline styles
        } catch (e) {
          console.warn("Could not access styles", e);
          return ""; // Fallback for any restricted styles
        }
      })
      .join("\n");

    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <title>Print</title>
            ${tailwindStyles}
        </head>
        <body class="bg-white text-black">
            ${printContent}
        </body>
        </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  //--------------------------Share Details -------------------------------------------------------------------------

  //--------------------------------Share API------------------------------------------------------------
  console.log("unqiueKey", )
  const [loading, setLoading] = useState(false);
  const shareBill = async () => {
    setLoading(true);
    try {
      // Making an API request using Axios
      const response = await getAxiosWithToken({
        method: "post",
        url: `share/booking/${unqiueKey}`,
      });

      // Check if the response is valid and log the result
      if (response?.data) {
        console.log("Response received:", response.data);
        alertResponseMsgSuccess(response.data.msg);
      } else {
        console.warn("No data received in the response:", response);
      }
    } catch (error) {
      // Log error details for better debugging
      // alertResponseMsgSuccess(error)

      console.error("Error occurred while sharing the bill:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <>
        {/* The Activate/Deactivate Button */}
        <button
          onClick={handleButtonClick}
          className={`px-2 rounded-borderRedius py-1 text-textColor`}
        >
          <SendIcon />
        </button>
        {/* Confirmation Popup */}
        {open && (
          <div
            className="fixed inset-0 flex items-center justify-center overflow-x-scroll bg-black bg-opacity-50 z-50"
            onClick={handleCancel} // Close the popup when clicking the backdrop
          >
            <div
              className="absolute bg-white p-6 rounded-lg shadow-lg max-w-5xl w-full top-4"
              onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
            >
              <div className="bg-white">
                {/* {loading && <div className=""></div>} */}
                <div>
                  <div className="col-span-2 mb-5 flex justify-between gap-6">
                    <div onClick={handlePrint}>
                      <PrimeButton btnName="Print" />
                    </div>
                    {/* <ShareButton shareUrl="https://www.example.com" /> */}
                    {pageName === "booking" && <div
                      onClick={shareBill}
                    >
                      <PrimeButton
                        btnName={`${loading ? "Loading..." : "Share"}`}
                      />
                    </div>}
                    
                    {/* <button
                      onClick={handleShare}
                      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                    >
                      Share as PDF
                    </button> */}
                  </div>
                  <div id="print-section" className="bg-backgroundColor p-4 ">
                    <div className="col-span-2 mb-5"></div>
                    <div className="grid grid-cols-2 gap-3 text-gray-600 ">
                      <div className="col-span-2 flex justify-between text-xs rounded-3xl p-2 bg-gray-200">
                        <div className="flex items-center gap-2  p-2  ">
                          <label className="block font-bold text-gray-900">
                            Order Id
                          </label>
                          <p className="">
                            {" "}
                            {toggal
                              ? allBookingRaw?.bookingOrderId
                              : allBookingRaw?.quotationOrderId}
                          </p>
                        </div>
                        <div className="flex items-center gap-2  p-2  ">
                          <label className="block font-bold text-gray-900">
                            From Station
                          </label>
                          <p className="">{allBookingRaw?.startStationName}</p>
                        </div>
                        <div className="flex items-center gap-2  p-2  ">
                          <label className="block font-bold text-gray-900">
                            To Station
                          </label>
                          <p className="">{allBookingRaw?.endStationName}</p>
                        </div>

                        <div className="flex items-center gap-2  p-2 ">
                          <label className="block  font-bold text-gray-900">
                            Date
                          </label>
                          <p className="">
                            {new Date(
                              allBookingRaw?.bookingDate
                            ).toLocaleDateString("en-GB")}
                          </p>
                        </div>
                      </div>

                      <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200 ">
                        <div className="text-left py-1 px-3">
                          <label className="block font-bold text-gray-900">
                            From
                          </label>
                          <div className=" font-semibold">
                            <p>{fromOrderId?.personName}</p>
                            {toggal && <p>GST No.: {fromOrderId?.gstNumber}</p>}
                          </div>
                          <p>{fromOrderId?.address}</p>
                          <div className="flex gap-2">
                            <p>{fromOrderId?.cityName}</p>
                            <p>{fromOrderId?.stateName}</p>
                            <p>{fromOrderId?.pincode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200">
                        <div className="text-left p-1 px-3">
                          <label className="block font-bold text-gray-900">
                            To
                          </label>
                          <div className=" font-semibold">
                            <p>{toOrderId?.personName}</p>
                            {toggal && <p>GST No.: {toOrderId?.gstNumber}</p>}
                          </div>
                          <p>{toOrderId?.address}</p>
                          <div className="flex gap-2">
                            <p>{toOrderId?.cityName}</p>
                            <p>{toOrderId?.stateName}</p>
                            <p>{toOrderId?.pincode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="relative overflow-auto col-span-2 justify-between text-xs rounded-3xl p-2 bg-gray-200">
                        <table className="table-auto w-full">
                          <thead>
                            <tr className="border-b-2  border-white">
                              {tableHead.map((head, index) => (
                                <th
                                  key={index}
                                  className="p-2 text-left uppercase"
                                >
                                  {head}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          {toggal ? (
                            <tbody>
                              {productDetail.map((data, index) => (
                                <tr
                                  key={index}
                                  className="border-b-2 text-left border-white"
                                >
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">
                                    {data.receiptNo}
                                  </td>
                                  <td className="px-4 py-2">{data.refNo}</td>
                                  <td className="px-4 py-2">
                                    {data.insurance}
                                  </td>
                                  <td className="px-4 py-2">
                                    {" "}
                                    {data.vppAmount}
                                  </td>
                                  <td className="px-4 py-2">{data.toPay}</td>
                                  <td className="px-4 py-2">{data.weight}</td>
                                  <td className="px-4 py-2"> {data.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              {productDetail.map((data, index) => (
                                <tr
                                  key={index}
                                  className="border-b-2 text-left border-white"
                                >
                                  <td className="px-4 py-2">{index + 1}</td>

                                  <td className="px-4 py-2">
                                    {" "}
                                    {data.noOfParcel}
                                  </td>
                                  <td className="px-4 py-2">{data.toPay}</td>
                                  <td className="px-4 py-2">{data.weight}</td>
                                  <td className="px-4 py-2"> {data.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                        <div className="flex justify-between text-xs p-2">
                          <div className=" p-2 flex gap-2 rounded-primeryBRedius">
                            <div className="col-span-2 sm:col-span-1 text-xs  p-2  border-t-2 border-white">
                              <div className=" text-left ">
                                <label className="block font-bold text-gray-900">
                                  Thank you for the business!
                                </label>
                                <p>
                                  Please pay within 15 days of receiving this
                                  invoice.
                                </p>
                                <p>
                                  Note: {allBookingRaw?.additionalCmt || "NA"}
                                </p>
                              </div>
                            </div>
                            <div className="col-span-2 sm:col-span-2 text-xs  p-2  border-t-2 border-white">
                              <h1 className="text-gray-900 px-2 font-bold text-left">
                                Customer Details
                              </h1>
                              <div className=" flex text-left p-2">
                                <table className="">
                                  <tbody>
                                    <tr className="text-left">
                                      <th className="text-gray-900 px-2">
                                        Name :
                                      </th>
                                      <td>
                                        {allBookingRaw?.firstName +
                                          " " +
                                          allBookingRaw?.middleName +
                                          " " +
                                          allBookingRaw?.lastName}
                                      </td>
                                    </tr>
                                    <tr className="text-left">
                                      <th className="text-gray-900 px-2">
                                        Email :
                                      </th>
                                      <td>{allBookingRaw?.email || "NA"}</td>
                                    </tr>
                                    <tr className="text-left">
                                      <th className="text-gray-900 px-2">
                                        Contact :
                                      </th>
                                      <td>{allBookingRaw?.mobile || "NA"}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {toggal ? (
                            <table className="">
                              <tbody>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">Bill Total</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.billTotal || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">Freight</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.freight || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">INS/VPP</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.ins_vpp || "NA"}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">CGST %</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.cgst || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">SGST %</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.sgst || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">IGST %</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.igst || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">Grand Total</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.grandTotal || "NA"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <table className="">
                              <tbody>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">s. Tax %</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.sTax || "NA"}
                                  </td>
                                </tr>
                                <tr className="text-left border-b-2 border-white">
                                  <th className="px-4 py-2">Grand Total</th>
                                  <td className="px-4 py-2">
                                    {allBookingRaw?.grandTotal || "NA"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 flex justify-end gap-3 text-xs rounded-3xl p-4 bg-gray-200">
                        <p className="flex-auto text-start font-bold">
                          Bharat Parcel Services, IN
                        </p>
                        <p className="font-bold">+91 7017866149</p>
                        <p className="font-bold">bps@email.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default ShareDetail;
