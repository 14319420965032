import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimeButton, BackButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import ShowHideBtn from "../../../component/ShowHideBtn";
import CityName from "../../../component/dropdown/CityName";
import StateName from "../../../component/dropdown/StateName";
// import { useSelector } from "react-redux";
// import AutoInputField from "../../../component/AutoInputField";
import Dropdown from "../../../component/Dropdown";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  stationId: "",
  mobile: "",
  email: "",
  password: "",
  address1: "",
  district: "",
  pincode: "",
  idProofNo: "",
  idProofDoc: "",
  userPhoto: "",
  state: "",
  city: "",
};

const AddAdmin = () => {
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [stationId, SetStationId] = useState();
  useEffect(() => {
    if (stationId) {
      setFieldValue("stationId", stationId); // Update Formik state
    }
  }, [stationId]);
  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    mobile: Yup.number().required("Mobile number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    stationId: Yup.string().required("Station should not be empty"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(
        8,
        '"Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"'
      )
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    address1: Yup.string().required("address1 should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    state: Yup.string().required("State Code should not be empty"),
    city: Yup.string().required("City Code should not be empty"),
    idProofNo: Yup.number().required("ID Number should not be empty"),
    idProofDoc: Yup.string().required("ID should not be empty"),
    userPhoto: Yup.string().required("Sub Admin's Photo should not be empty"),
  });
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ConstomerCreateSchema,
      onSubmit: async (values, action) => {
        setButtonLoader(true);
        let data = new FormData();
        data.append("firstName", values.firstName);
        data.append("middleName", values.middleName);
        data.append("lastName", values.lastName);
        data.append("stationId", stationId);
        data.append("mobile", values.mobile);
        data.append("email", values.email);
        data.append("password", values.password);
        data.append("address1", values.address1);
        data.append("district", values.district);
        data.append("state", values.state);
        data.append("city", values.city);
        data.append("pincode", values.pincode);
        data.append("idProofNo", values.idProofNo);
        if (photo) {
          data.append("userPhoto", photo);
        }
        if (IdDoc) {
          data.append("idProofDoc", IdDoc);
        }
        // console.log("data :", data)
        // return false;
        try {
          const response = await getAxiosWithToken({
            method: "POST",
            url: "sub/admin/add", // Adjust the API endpoint as needed
            data: data,
            reqType: "formData",
          });
          // API response 200 201
          if (response.data.success) {
            alertResponseMsgSuccess(response.data.msg);
            setButtonLoader(false);
            navigate("/sub/admin");
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(error.response.data.msg);
          setButtonLoader(false);
          // alertResponseMsgError(error.response.msg);
        }
      },
    });

  //password visibility----------------------------------------------------------------------------------------//
  const [showPassword, setShowPassword] = useState(false);
  const PasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //----------------------------------------------- upload Document -------------------------------------------//
  const [photo, setPhoto] = useState(null);
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "userPhoto") {
      let Image = files[0];
      if (Image.size > 2000000) {
        // console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        Image.type.includes("image/jpeg") ||
        Image.type.includes("image/jpg") ||
        Image.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(Image);
        setPhotoPerview(imageUrl);
        setPhoto(Image);
        setFieldValue("userPhoto", Image); // Update Formik state
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
    if (name === "idProofDoc") {
      let IDProof = files[0];
      if (IDProof.size > 2000000) {
        console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        IDProof.type.includes("image/jpeg") ||
        IDProof.type.includes("image/jpg") ||
        IDProof.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(IDProof);
        setIdDocPerview(imageUrl);
        setIdDoc(IDProof);
        setFieldValue("idProofDoc", IDProof); // Update Formik state
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };
  return (
    <div className="px-8 py-5">
      <BackButton btnName="Admin Details" />
      <form onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
          <div className="col-span-2 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium">
              First Name
            </label>
            <div className="w-full">
              <input
                type="text"
                name="firstName"
                value={"" || values.firstName}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="First Name"
              />
              <InputError
                error={errors.firstName}
                touched={touched.firstName}
              />
            </div>
          </div>
          <div className="col-span-2 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium">
              Middle Name
            </label>
            <div className="w-full">
              <input
                type="text"
                name="middleName"
                value={"" || values.middleName}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="Middle Name"
              />
              <InputError
                error={errors.middleName}
                touched={touched.middleName}
              />
            </div>
          </div>
          <div className="col-span-2 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium">
              Last Name
            </label>
            <div className="w-full">
              <input
                type="text"
                name="lastName"
                value={"" || values.lastName}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="Last Name"
              />
              <InputError error={errors.lastName} touched={touched.lastName} />
            </div>
          </div>
          <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium">
              Station
            </label>
            <div className="w-full ">
              <Dropdown
                label={"Station"}
                name={"startStation"}
                SetStationId={SetStationId}
              />
              <InputError
                error={errors.stationId}
                touched={touched.stationId}
              />
            </div>
          </div>

          <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Email ID
            </label>
            <div className="w-full">
              <input
                type="email"
                name="email"
                value={"" || values.email}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Email"
              />
              <InputError error={errors.email} touched={touched.email} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Password
            </label>
            <div className="w-full">
              <div className="w-full flex items-center gap-1">
                <input
                  type={showPassword ? "text" : "password"} // Toggle between 'password' and 'text'
                  name="password"
                  value={"" || values.password}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="password"
                />
                {values.password && (
                  <div onClick={PasswordVisibility}>
                    <ShowHideBtn isShow={showPassword} />
                  </div>
                )}
              </div>
              <InputError error={errors.password} touched={touched.password} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Contact Number
            </label>
            <div className="w-full">
              <input
                type="text"
                name="mobile"
                value={"" || values.mobile}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Contact Number"
              />
              <InputError error={errors.mobile} touched={touched.mobile} />
            </div>
          </div>
          <div className="div col-span-6 font-semibold text-left text-lg">
            Addresss
          </div>
          <div className="col-span-6 gap-6  lg:flex py-2  items-center">
            <label className="w-36 md:flex-none text-sm font-medium ">
              Address / Street
            </label>
            <div className="w-full">
              <input
                type="text"
                name="address1"
                value={"" || values.address1}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Address / Street"
              />
              <InputError error={errors.address1} touched={touched.address1} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              District
            </label>
            <div className="w-full">
              <input
                type="text"
                name="district"
                value={"" || values.district}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="District"
              />
              <InputError error={errors.district} touched={touched.district} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              State
            </label>
            <div className="w-full">
              <StateName
                handleChange={handleChange}
                name={"state"}
                value={values.state}
              />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              City
            </label>
            <div className="w-full">
              <CityName
                handleChange={handleChange}
                name={"city"}
                value={values.city}
                stateId={values.state}
              />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Pin Code
            </label>
            <div className="w-full">
              <input
                type="text"
                name="pincode"
                value={"" || values.pincode}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Pin Code"
              />
              <InputError error={errors.pincode} touched={touched.pincode} />
            </div>
          </div>
          <div className=" col-span-6 font-semibold text-left text-lg">
            Documents
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              ID Proof
            </label>
            <div className="w-full">
              <input
                type="text"
                name="idProofNo"
                value={"" || values.idProofNo}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Id Proof No."
              />
              <InputError
                error={errors.idProofNo}
                touched={touched.idProofNo}
              />
            </div>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="idProofDoc"
                onChange={handleChangeFile}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Id Proof"
                accept="image/png, image/jpeg, image/jpg"
              />
              <InputError
                error={errors.idProofDoc}
                touched={touched.idProofDoc}
              />

              {idDocPerview ? <img src={idDocPerview} alt="Id Proof" /> : null}
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Upload Admin's Photo
            </label>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="userPhoto"
                onChange={handleChangeFile}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Sub Admin's Photo"
                accept="image/png, image/jpeg, image/jpg"
              />
              <InputError
                error={errors.userPhoto}
                touched={touched.userPhoto}
              />

              {photoPerview ? <img src={photoPerview} alt="dd" /> : null}
            </div>
          </div>
        </div>
        <div className="mt-12">
          <PrimeButton btnName="Submit" buttonLoader={buttonLoader} />
        </div>
      </form>
    </div>
  );
};

export default AddAdmin;
