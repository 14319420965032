import React, { useState, useEffect, useRef } from "react";
import InputError from "../../component/InputError";
import profile from "../../img/profile.jpg";
import { useNavigate, useParams } from "react-router-dom";
// import Dropdown from "../../component/Dropdown";
import { BackButton, PrimeButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../function/utilies";
import StateName from "../../component/dropdown/StateName";
import CityName from "../../component/dropdown/CityName";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../store/features/userSlice";


const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  address1: "",
  district: "",
  pincode: "",
  idProofNo: "",
  idProofsrc: "",
  userPhoto: "",
  state: "",
  city: "",
  status:"",
};

const EditCustomerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const{apiUrl}= useSelector((state) => state.user)
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allCustomerRaw, setAllCustomerRaw] = useState();

  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First name should not be empty"),
    // middleName: Yup.string().required("Middle name should not be empty"), // Uncomment if needed
    lastName: Yup.string().required("Last name should not be empty"),
    mobile: Yup.number().required("Mobile number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    address1: Yup.string().required("Address should not be empty"),
    state: Yup.string().required("State should not be empty"),
    city: Yup.string().required("City should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin code should not be empty"),
    idProofNo: Yup.number().required("ID number should not be empty"),
    // idProofDoc: Yup.string().required("ID document should not be empty"),
    // userPhoto: Yup.string().required("Customer's photo should not be empty"),
  });

  // Abhi vihcle wala kaam start kro  ok
  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,
    onSubmit: async (values, action) => {
      setButtonLoader(true);
      // there we are comparing all vlue with response valuse from api
      let updatedvalue = await valuesCheck(values, allCustomerRaw);
      // console.log("allRawData",allCustomerRaw)
      // console.log("updatedvalue",updatedvalue)
      let data = new FormData();
      for (const key in updatedvalue) {
        if (updatedvalue.hasOwnProperty(key)) {
          const value = updatedvalue[key];
          // console.log(`Appending ${key}: ${value}`);
          data.append(key, value);
        }
      }
      if(photo){
        data.append("userPhoto", photo)
      }
      if(IdDoc){
        data.append("idProofDoc", IdDoc)
      }
      let username;
      if (params && params.username && params.username !== " ") {
        username = params.username;
      }
      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `customer/update/${username}`, // Adjust the API endpoint as needed
          data: data,
          reqType:'formData'
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          // action.resetForm();
          setButtonLoader(false);
          navigate(-1);
        } else {
          alertResponseMsgError(response.data.msg);
          setButtonLoader(false);

        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        setButtonLoader(false);

        // alertResponseMsgError(error.response.msg);
      }
    },
  });

  const getCustomerDeatils = async (username) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `customer/details/${username}`, // Adjust the API endpoint as needed
      });
      // console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setAllCustomerRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (allCustomerRaw) {
      // console.log(allCustomerRaw);
      values.firstName = allCustomerRaw.firstName;
      values.middleName = allCustomerRaw.middleName;
      values.lastName = allCustomerRaw.lastName;
      values.mobile = allCustomerRaw.mobile;
      values.email = allCustomerRaw.email;
      values.address1 = allCustomerRaw.address1;
      values.district = allCustomerRaw.district;
      values.pincode = allCustomerRaw.pincode;
      values.state = allCustomerRaw.state;
      values.city = allCustomerRaw.city;
      values.idProofNo = allCustomerRaw.idProofNo;
      values.document = allCustomerRaw.document;
      values.profilepic = allCustomerRaw.profilepic;
      values.idProofsrc = allCustomerRaw.idProofsrc;
      values.status = allCustomerRaw.status;


    }
  }, [allCustomerRaw]);


  const valueRef = useRef(values);

  useEffect(() => {
    if (params && params.username && params.username !== " ") {
      valueRef.current = valuesEmpty(values, "");

      getCustomerDeatils(params.username);
    } else {
      window.location.href = "/customers";
    }
  }, [params]);

  //----------------------------------------------- upload Document -------------------------------------------//
  const [photo, setPhoto] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "Photo") {
      let userImage = files[0];
      if (userImage.size > 2000000) {
        alertResponseMsgError("Image Should be less then 2 MB");
        return false;
      }
      if (
        userImage.type.includes("image/jpeg") ||
        userImage.type.includes("image/jpg") ||
        userImage.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(userImage);
        setPhotoPerview(imageUrl);
        setPhoto(userImage);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
    if (name === "idProofDoc") {
      let IDProof = files[0];
      if (IDProof.size > 2000000) {
        console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        IDProof.type.includes("image/jpeg") ||
        IDProof.type.includes("image/jpg") ||
        IDProof.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(IDProof);
        setIdDocPerview(imageUrl);
        setIdDoc(IDProof);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };

  //------------------------------Profile Picture--------------------------------
  const imageUrl = values.profilepic ? `${apiUrl}/${values.profilepic}` : profile;
  //------------------------------Id Picture--------------------------------
  const IDimageUrl = values.idProofsrc ? `${apiUrl}/${values.idProofsrc}` : profile;

  
   const handleImageClick = (uploadFile) => {
    console.log("its working ",uploadFile)
    const imageUrl = uploadFile ? `${uploadFile}` : profile;

    //  window.open method if you want to open the image in a new tab
    window.open(imageUrl, "_blank");
  };


  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Customer Details" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={imageUrl}
                  alt="user.name"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
              Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={"" || values.mobile}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="mobile"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>
          
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="w-36 md:flex-none text-sm font-medium">
                Status
              </label>
              <div className="w-full">
                <select
                  name="status"
                  value={"" || values.status}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  {/* <option value="2">Deactive</option> */}
                  <option value="2">Blacklist</option>
                </select>
                <InputError error={errors.status} touched={touched.status} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                Address / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address1"
                  value={"" || values.address1}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Address / Street"
                />
                <InputError
                  error={errors.address1}
                  touched={touched.address1}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
                <InputError error={errors.state} touched={touched.state} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
                <InputError error={errors.city} touched={touched.city} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              ID Proof
            </label>
            <div className="w-full">
              <input
                type="text"
                name="idProofNo"
                value={"" || values.idProofNo}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Id Proof No."
              />
              <InputError
                error={errors.idProofNo}
                touched={touched.idProofNo}
              />
            </div>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="idProofDoc"
                onChange={handleChangeFile}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Id Proof"
                accept="image/png, image/jpeg, image/jpg"
              />
              <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <img
            onClick={() => handleImageClick(IDimageUrl)}
              src={IDimageUrl}
              alt="user"
              className=" p-1 rounded-inputBRedius bg-slate-100 ring-1 ring-primeryBgColor cursor-pointer"
              loading="lazy"
            />
          </div>
              {idDocPerview ? <img src={idDocPerview}  alt="Id Proof" /> : null}
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Upload Constomer's Photo
            </label>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="Photo"
                onChange={handleChangeFile}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Customer's Photo"
                accept="image/png, image/jpeg, image/jpg"
              />

              {photoPerview ? <div><img src={photoPerview}  alt="dd" /></div> : null}
            </div>
          </div>
          </div>
          <div className=" text-white mt-4 ">
            <div className="mt-12">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCustomerDetail;
