import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
import totaldriver from "../../img/totaldriver.svg";
// import fuel from "../../../img/fuel.svg";
// import maintenance from "../../../img/maintenance.svg";
// import TowTruck from "../../img/TowTruck.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";
import customer from "../../img/customer.svg";


const AdminCard = ({ isActive,allRawData,allRawData2}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  // const [active, setSctive] = useState([]);
  // const [cancelled, setcancelled] = useState([]);
  const [total, settotal] = useState();
  // const [availabe, setAvailabe] = useState([]);
  const [deactivate, setdeactivate] = useState([]);
  const [blacklist, setblacklist] = useState([]);
  const [superAdmin, setsuperAdmin] = useState([]);

  

  // console.log("total", total);
  //   console.log("request", request);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=admin",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      // setSctive(allBookingRaw.active);
      // setcancelled(allBookingRaw.cancelled);
      settotal(allBookingRaw?.subadmin?.active);
      // setAvailabe(allBookingRaw?.available);
      setdeactivate(allBookingRaw?.subadmin?.deactivate);
      setblacklist(allBookingRaw?.subadmin?.blacklisted);
      setsuperAdmin(allBookingRaw?.admin?.active);

    }
  }, [allBookingRaw]);

  console.log("allBookingRaw",allBookingRaw)
  return (
    <>
      <div className="grid sm:grid-cols-2 md:grid-cols-4  justify-items-stretch gap-6 p-4 cursor-pointer">
          <div>
            <Link to={RoutePath.Admin}>
              <Statusbar
                isActive={isActive ==="sub"}
                src={totaldriver}
                count={total?.count || 0}
                title="supervisor"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.DeactiveAdmin}>
              <Statusbar
                src={customer}
                isActive={isActive ==="deactive"}
                count={deactivate?.count || 0}
                title="Decative supervisor"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.BlockAdmin}>
              <Statusbar
                src={customer}
                count={blacklist?.count || 0}
                isActive={isActive ==="block"}
                title="Block supervisor"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.SuperAdmin}>
              <Statusbar
                src={customer}
                isActive={isActive ==="super"}
                count={superAdmin?.count || 0}
                title=" Admin"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
        </div>
    </>
  );
};

export default AdminCard;
