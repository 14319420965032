import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrimeButton, BackButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  formateDate,
  formatDateToISO,
  valuesEmpty,
  valuesCheck,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";

const initialValues = {
  registrationNo: "", // Updated
  registrationVaildDate: "",
  registrationExpDate: "",
  vehicleModel: "",
  manufactureYear: "",
  ownedBy: "",
  location: "",
  purchaseDate: "",
  purchaseFrom: "",
  purchaseUnder: "",
  purchasePrice: "",
  depreciation: "",
  currentValue: "",
  insuranceProvider: "",
  policyNumber: "",
  policyType: "",
  policyStartDate: "",
  policyEndDate: "",
  policypremium: "",
  lastFitnessDate: "",
  currentFitnessDate: "",
  firstRegVaildUpto: "",
  renewalDate: "",
  renewalVaildUpto: "",
  addcomment: "",
};


const EditVehicleDetail = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allRawData, setallRawData] = useState();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userCreateSchema = Yup.object({
    registrationNo: Yup.string().required(
      "Registration number should not be empty"
    ), // Updated
    registrationVaildDate: Yup.string().required(
      "Registration date should not be empty"
    ),
    registrationExpDate: Yup.date()
      .required("Reg. expiry date should not be empty")
      .min(today, "Date should be today or a future date"),
    vehicleModel: Yup.string().required("Make/Model should not be empty"),
    manufactureYear: Yup.number().required(
      "Year of manufacture should not be empty"
    ),
    ownedBy: Yup.string().required("Owned by should not be empty"),
    location: Yup.string().required("Current location should not be empty"),
    purchaseDate: Yup.string().required("Date of purchase should not be empty"),
    purchaseFrom: Yup.string().required("Name should not be empty"),
    purchaseUnder: Yup.string().required("Purchased under should not be empty"),
    purchasePrice: Yup.number().required("Purchase price should not be empty"),
    depreciation: Yup.string().required("Depreciation should not be empty"),
    currentValue: Yup.number().required("Current value should not be empty"),
    insuranceProvider: Yup.string().required(
      "Current Insurance Provider should not be empty"
    ),
    policyNumber: Yup.number().required("Policy No.*"),
    policyType: Yup.string().required("Policy Type*"),
    policyStartDate: Yup.string().required("Policy Start Date No.*"),
    policyEndDate: Yup.date()
      .required("Policy End Date*")
      .min(today, "Date should be today or a future date"),
    policypremium: Yup.string().required("Policy Premium*"),
    lastFitnessDate: Yup.string().required(
      "Last Fitness Renewal Date*"
    ),
    currentFitnessDate: Yup.date()
      .required("Current Fitness Valid Upto*")
      .min(today, "Date should be today or a future date"),
    firstRegVaildUpto: Yup.string().required("First Reg. Valid Upto*"),
    renewalDate: Yup.date()
      .required("Renewal date*")
      .min(today, "Date should be today or a future date"),
    renewalVaildUpto: Yup.date()
      .required("Renewal Valid Upto*")
      .min(today, "Date should be today or a future date"),
  });
  
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      // setButtonLoader(true);
      let updatedvalue = await valuesCheck(values, allRawData);
      // console.log("allRawData",allRawData)
      // console.log("updatedvalue",updatedvalue)
      let data = new FormData();
      for (const key in updatedvalue) {
        if (updatedvalue.hasOwnProperty(key)) {
          const value = updatedvalue[key];
          console.log(`Appending ${key}: ${value}`);
          data.append(key, value);
        }
      }

      let vehicleId;
      if (params && params.vehicleId && params.vehicleId !== " ") {
        vehicleId = params.vehicleId;
      }
      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `vehicle/update/${vehicleId}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        // console.log(response);
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
          navigate(-1);
        } else {
          alertResponseMsgError(response.data.msg);
          setButtonLoader(false);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);

        setButtonLoader(false);
      }
    },
  });
  console.log("errors",errors)
  const getDeatils = async (vehicleId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `vehicle/details/${vehicleId}`, // Adjust the API endpoint as needed
      });
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setallRawData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (allRawData) {
      // console.log(allRawData);
      values.registrationNo = allRawData.registrationNo;
      values.registrationVaildDate = formatDateToISO(
        formateDate(allRawData.registrationVaildDate)
      );
      values.vehicleModel = allRawData.vehicleModel;
      values.registrationExpDate = formatDateToISO(
        formateDate(allRawData.registrationExpDate)
      );
      values.manufactureYear = allRawData.manufactureYear;
      values.ownedBy = allRawData.ownerName;
      values.location = allRawData.location;
      values.purchaseDate = formatDateToISO(
        formateDate(allRawData.purchaseDate)
      );
      values.purchaseFrom = allRawData.purchaseFrom;
      values.purchaseUnder = allRawData.purchaseUnder;
      values.purchasePrice = allRawData.purchasePrice;
      values.depreciation = allRawData.depreciation;
      values.currentValue = allRawData.currentValue;
      values.insuranceProvider = allRawData.insuranceProvider;
      values.policyNumber = allRawData.policyNumber;
      values.policyType = allRawData.policyType;
      values.policyStartDate = formatDateToISO(
        formateDate(allRawData.policyStartDate)
      );
      values.policyEndDate = formatDateToISO(
        formateDate(allRawData.policyEndDate)
      );
      values.policypremium = allRawData.policypremium;
      values.lastFitnessDate = formatDateToISO(
        formateDate(allRawData.lastFitnessDate)
      );
      values.currentFitnessDate = formatDateToISO(
        formateDate(allRawData.currentFitnessDate)
      );
      values.firstRegVaildUpto = formatDateToISO(
        formateDate(allRawData.firstRegVaildUpto)
      );
      values.renewalDate = formatDateToISO(formateDate(allRawData.renewalDate));
      values.renewalVaildUpto = formatDateToISO(
        formateDate(allRawData.renewalVaildUpto)
      );
      values.additionalCmt = allRawData.additionalCmt;
      values.status = allRawData.status;
    }
  }, [allRawData]);

  //to clean priveous data from cach
  const valueRef = useRef(values);
  useEffect(() => {
    if (params && params.vehicleId && params.vehicleId !== " ") {
      valueRef.current = valuesEmpty(values, "");
      getDeatils(params.vehicleId);
    } else {
      window.location.href = "/vehicle";
    }
  }, [params]);

  //year start for current year
  const currentYear = new Date().getFullYear();
  const endYear = 1995;
  const years = Array.from(
    { length: currentYear - endYear + 1 },
    (_, index) => currentYear - index
  );

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Vehicle Details" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="div col-span-6 text-lg font-semibold  ">
              Vehicle Details
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="md:flex-none text-sm font-medium ">
                Registration Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="registrationNo"
                  value={"" || values.registrationNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Registration Number"
                />
                <InputError
                  error={errors.registrationNo}
                  touched={touched.registrationNo}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="w-36 md:flex-none text-sm font-medium">
                Status
              </label>
              <div className="w-full">
                <select
                  name="status"
                  value={"" || values.status}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">Deactive</option>
                  <option value="3">Blacklist</option>
                </select>
                <InputError error={errors.status} touched={touched.status} />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Registration Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="registrationVaildDate"
                  value={"" || values.registrationVaildDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.registrationVaildDate}
                  touched={touched.registrationVaildDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Reg. Expiry Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="registrationExpDate"
                  value={"" || values.registrationExpDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.registrationExpDate}
                  touched={touched.registrationExpDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Make/Model
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="vehicleModel"
                  value={"" || values.vehicleModel}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Make/Model"
                />
                <InputError
                  error={errors.vehicleModel}
                  touched={touched.vehicleModel}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Year of Manufacture
              </label>
              <div className="w-full">
                <select
                  id="yearDropdown"
                  name="manufactureYear"
                  value={"" || values.manufactureYear}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                >
                  <option value="">Selelct</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <InputError
                  error={errors.manufactureYear}
                  touched={touched.manufactureYear}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Owned By
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="ownedBy"
                  value={"" || values.ownedBy}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Owned By"
                />
                <InputError
                  error={errors.ownedBy}
                  touched={touched.ownedBy}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Location
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="location"
                  value={"" || values.location}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Location"
                />
                <InputError
                  error={errors.location}
                  touched={touched.location}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Date of Purchase
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="purchaseDate"
                  value={"" || values.purchaseDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Purchase"
                />
                <InputError
                  error={errors.purchaseDate}
                  touched={touched.purchaseDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased from
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchaseFrom"
                  value={"" || values.purchaseFrom}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased from"
                />
                <InputError
                  error={errors.purchaseFrom}
                  touched={touched.purchaseFrom}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased under
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchaseUnder"
                  value={"" || values.purchaseUnder}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased under"
                />
                <InputError
                  error={errors.purchaseUnder}
                  touched={touched.purchaseUnder}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchase Price (in INR)
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchasePrice"
                  value={"" || values.purchasePrice}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchase Price"
                />
                <InputError
                  error={errors.purchasePrice}
                  touched={touched.purchasePrice}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                % of Depreciation
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="depreciation"
                  value={"" || values.depreciation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Depreciation"
                />
                <InputError
                  error={errors.depreciation}
                  touched={touched.depreciation}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Value
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentValue"
                  value={"" || values.currentValue}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Value"
                />
                <InputError
                  error={errors.currentValue}
                  touched={touched.currentValue}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Insurance Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Insurance Provider
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="insuranceProvider"
                  value={"" || values.insuranceProvider}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Insurance Provider"
                />
                <InputError
                  error={errors.insuranceProvider}
                  touched={touched.insuranceProvider}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyNumber"
                  value={"" || values.policyNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Number"
                />
                <InputError
                  error={errors.policyNumber}
                  touched={touched.policyNumber}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Type
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyType"
                  value={"" || values.policyType}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Type"
                />
                <InputError
                  error={errors.policyType}
                  touched={touched.policyType}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Start Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyStartDate"
                  value={"" || values.policyStartDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Start Date"
                />
                <InputError
                  error={errors.policyStartDate}
                  touched={touched.policyStartDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy End Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyEndDate"
                  value={"" || values.policyEndDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy End Date"
                />
                <InputError
                  error={errors.policyEndDate}
                  touched={touched.policyEndDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Premium
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policypremium"
                  value={"" || values.policypremium}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Premium"
                />
                <InputError
                  error={errors.policypremium}
                  touched={touched.policypremium}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Fitness Details
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Last Fitness Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="lastFitnessDate"
                  value={"" || values.lastFitnessDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Last Fitness Renewal Date"
                />
                <InputError
                  error={errors.lastFitnessDate}
                  touched={touched.lastFitnessDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Current Fitness Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="currentFitnessDate"
                  value={"" || values.currentFitnessDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Fitness Valid Upto"
                />
                <InputError
                  error={errors.currentFitnessDate}
                  touched={touched.currentFitnessDate}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Registration Renewal Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                First Reg. Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="firstRegVaildUpto"
                  value={"" || values.firstRegVaildUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="First Reg. Valid Upto"
                />
                <InputError
                  error={errors.firstRegVaildUpto}
                  touched={touched.firstRegVaildUpto}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalDate"
                  value={"" || values.renewalDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Date"
                />
                <InputError
                  error={errors.renewalDate}
                  touched={touched.renewalDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalVaildUpto"
                  value={"" || values.renewalVaildUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Valid Upto"
                />
                <InputError
                  error={errors.renewalVaildUpto}
                  touched={touched.renewalVaildUpto}
                />
              </div>
            </div>
            <div className="col-span-6 mt-4 items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Additional Comments
              </label>
              <textarea
                name="additionalCmt"
                id=""
                cols="30"
                rows="5"
                placeholder="Additional Comments"
                value={"" || values.additionalCmt}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
              ></textarea>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditVehicleDetail;
