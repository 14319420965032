import React, { useEffect, useState, useRef } from "react";
import { BackButton, PrimeButton } from "../../component/PrimeButton";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../function/utilies";
import InputError from "../../component/InputError";

const initialValues = {
  name: "",
  contactNumber: "",
  email: "",
  address: "",
};

const EditContactList = () => {
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);
  const params = useParams();
  const [allContactRaw, setAllContactRaw] = useState();

  const contactListSchema = Yup.object({
    name: Yup.string().required("Constomer name should not be empty"),
    contactNumber: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    address: Yup.string().required("address should not be empty"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: contactListSchema,

    onSubmit: async (values, action) => {
      setButtonLoader(true);
      let data = await valuesCheck(values, allContactRaw);
      let contactId;
      if (params && params.contactId && params.contactId !== " ") {
        contactId = params.contactId;
      }
      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `contact/list/update/${contactId}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
          navigate(-1)
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getContactDeatils = async (contactId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `contact/list/details/${contactId}`, // Adjust the API endpoint as needed
      });
      // console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setAllContactRaw(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (allContactRaw) {
      // console.log(allContactRaw);
      values.name = allContactRaw.name;
      values.contactNumber = allContactRaw.contactNumber;
      values.email = allContactRaw.email;
      values.address = allContactRaw.address;
    }
  }, [allContactRaw]);
  const valueRef = useRef(values);

  useEffect(() => {
    console.log(params);
    if (params && params.contactId && params.contactId !== " ") {
      valueRef.current = valuesEmpty(values, "");

      getContactDeatils(params.contactId);
    } else {
      // window.location.href = "/contact";
    }
  }, [params]);

  return (
    <div>
      <div className="p-4">
        <BackButton btnName="Edit Contact" />{" "}
        <form onSubmit={handleSubmit}>
          <div className="gap-6  mx-4 sm:mx-16 text-left p-12 text-mainTxtColor  shadow rounded-primeryBRedius mt-12">
            <div className="md:flex py-2 items-center mb-4">
              <label className="w-36 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="name"
                  value={"" || values.name}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 caret-pink-500 "
                  placeholder=" Name"
                />
                <InputError error={errors.name} touched={touched.name} />
              </div>{" "}
            </div>
            <div className="md:flex py-2 items-center mb-4 ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contactNumber"
                  value={"" || values.contactNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Number"
                />
                <InputError
                  error={errors.contactNumber}
                  touched={touched.contactNumber}
                />
              </div>{" "}
            </div>
            <div className="md:flex py-2 items-center mb-4 ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Email
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Email "
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>{" "}
            </div>
            <div className=" md:flex py-2 items-center mb-4 ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Address
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address"
                  value={"" || values.address}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Address"
                />
                <InputError error={errors.address} touched={touched.address} />
              </div>{" "}
            </div>
            <div className="py-2 flex justify-center mt-4">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContactList;
