import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import profile from "../img/profile.jpg";
// import SearchIcon from "@mui/icons-material/Search";
// import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ProfileDropDown from "./ProfileDropDown";
import { RoutePath } from "../router/RoutePath";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [UserDetail, setUserDetail] = useState([]);
  const [stationDetail, setStationDetail] = useState([]);
  const { userDetails } = useSelector((state) => state.user);

  // console.log("UserDetail", UserDetail);
  useEffect(() => {
    if (userDetails !== null && userDetails.data) {
      setUserDetail(userDetails.data);
      setStationDetail(userDetails.stationDetails);
    }
  }, [userDetails]);
  return (
    <div className="w-full fixed top-0 right-0 z-20 bg-white shadow">
      <div className="lg:grid lg:grid-cols-12 grid grid-cols-5 transition-transform  lg:translate-y-0  justify-items-end w-full lg:w-auto lg:ml-72  p-2">
        <div className="col-auto lg:hidden "></div>
        <div className="lg:col-span-8 col-span-5 pl-16 lg:pl-0 w-full">
          {/* <div className="relative mb-4 flex gap-2 items-center w-full ">
            <input
              type="search"
              className="relative block w-full min-w-0 flex-auto rounded-xl border-2 border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
            <button title="Delete">
              <SearchIcon />
            </button>
          </div> */}
        </div>
        <div className="lg:col-span-2 col-span-2 flex justify-around gap-4 p-3  ">
          {/* <div className="relative inline-flex bg-blue-300 text-blue-500 bg-opacity-50 p-1 mx-2 rounded-xl">
              
              <SmsOutlinedIcon />
            </div> */}
          {/* <div className="relative inline-flex bg-red-300 text-red-500 bg-opacity-50 p-1 mx-2 rounded-xl">
              <div className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-400 px-2.5 py-1 text-center align-baseline text-xs  leading-none text-white">
                9+
              </div>
              <SettingsOutlinedIcon />
            </div> */}
        </div>
        {/*group work for group-hover:block */}
        <div className="lg:col-span-2 col-span-3 group">
          <Link to={RoutePath.ActiveUserDetail}>
            <div className=" flex  items-center text-mainTxtColor gap-3 px-3 ">
              <div className="group-hover:text-primeryBgColor">
                <strong className="flex items-center gap-1">
                  Hello, 
                  {UserDetail?.firstName ? (
                    <span>{UserDetail.firstName}</span>
                  ) : (
                    <span className=" text-red-500">
                      Deactive
                    </span>
                  )}
                </strong>

                <p className="text-sm">
                  {UserDetail ? stationDetail.stationName : "Your Station"}
                </p>
              </div>
              <img
                src={
                  `${UserDetail?.profilepic ? `https://api.bharatparcel.org/${UserDetail.profilepic}`: profile}`
                }
                alt="user.name"
                className="w-12 h-12 rounded-full bg-slate-100 ring-2 ring-white group-hover:ring-primeryBgColor "
                loading="lazy"
              />
            </div>
          </Link>
          <div className="hidden bg-gray-200 shadow-md z-10 group-hover:block">
            <ProfileDropDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
