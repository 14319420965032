import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";

const CityName = ({ name, handleChange, value = "", stateId }) => {
  const [allCity, setAllCity] = useState([]);
  const getCityName = async (stateId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `get/city/${stateId}`,
      });
      if (response && response.data && response.data.data) {
        setAllCity(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if(stateId){
      getCityName(stateId);
    }
  }, [stateId]);

  return (
    <div>
      <select
        value={value}
        name={name}
        onChange={handleChange}
        id="state"
        className=" w-full rounded-inputBRedius bg-inputBgColor p-2"
      >
        <option value="">Select City</option>
        {allCity.length > 0 ? (
          allCity.map((city, index) => (
            <option key={index} value={city.cityId}>
              {city.name}
            </option>
          ))
        ) : (
          <option value="">Select State First</option>
        )}
      </select>
    </div>
  );
};

export default CityName;

//<CityName handleChange={handleChange} name={"city"} value={values.city} stateId={values.state}/> 