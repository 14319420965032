import { AvailablityCard } from "./StatusBar";
import { useDispatch, useSelector } from "react-redux";
import truck from "../img/truck.svg";
import driver from "../img/driver.svg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../router/RoutePath";
import { getAxiosWithToken } from "../axios/AxiosObj";
import { userLogOut } from "../store/features/userSlice";
const AvailablityCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [driverData, setdriverData] = useState([]);
  const { isLogged } = useSelector((state) => state.user);
  //------------------------------------------------------------------------------------------------
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=customer",
      });
      if (response.data.success) {
        setAllDataRaw(response.data.data.customer);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  const vehicleFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=vehicle",
      });
      if (response.data.success) {
        setVehicleData(response.data.data.vehicle);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  const driverFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=driver",
      });
      if (response.data.success) {
        setdriverData(response.data.data.driver);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
      vehicleFetchDeatils();
      driverFetchDeatils();
    }
  }, [isLogged]);

  return (
    <div className="w-full">
      <div>
        <div className="grid lg:grid-cols-3 justify-items-stretch gap-6 p-4">
          <Link to={RoutePath.ManageCustomer}>
            <AvailablityCard
              src={driver}
              count={allDataRaw?.active?.count}
              title="Customer"
              rating={`Total : ${allDataRaw?.total?.count}`}
            />
          </Link>
          <Link to={RoutePath.Vehicle}>
            <AvailablityCard
              src={truck}
              count={vehicleData?.total?.recentCount}
              title="Vehicles Available"
              rating={`Total : ${vehicleData?.total?.count || 0}`}
            />
          </Link>
          <Link to={RoutePath.Driver}>
            <AvailablityCard
              src={driver}
              count={driverData?.active?.count}
              title="Drivers Available"
              rating={`Total : ${driverData?.total?.count || 0}`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AvailablityCards;
