import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../store/features/userSlice";
import SearchIcon from "@mui/icons-material/Search";

const CustomerDropDown = ({setNewcustomerId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.user);
  const [showDropdown, setShowDropdown] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const dropdownRef = useRef(null);

  const [allCustomerRaw, setAllCustomerRaw] = useState([]);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "customer/all",
      });
      if (response.data.success) {
        // setBookingCount(response.data.dataCount);
        setAllCustomerRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged]);

  const searchById = (customerId) => {
    setcustomerId(customerId);
    setNewcustomerId(customerId.username);
    };

  const [filteredData, setFilteredData] = useState([]);
  const [selectOption, setSelectOption] = useState('');

  useEffect(() => {
    // Check if allCustomerRaw is available
    if (allCustomerRaw) {
      const lowerCaseSearchTerm = selectOption.trim().toLowerCase();

      const filteredItems = !selectOption
        ? allCustomerRaw.slice(0,6) // Return all 06 items if no selection is made
        : allCustomerRaw.filter(item => 
            item.mobile?.toLowerCase().includes(lowerCaseSearchTerm)
          );
      setFilteredData(filteredItems);
    }
  }, [selectOption, allCustomerRaw]);

  const searchHandle = (event) => {
    setSelectOption(event.target.value);
  };

  //-------------------------------------------dropdown---------------------------------------//

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div ref={dropdownRef} className="w-full mb-4">
      <label className="col-span-6 block my-2 text-sm font-medium text-gray-900">
              Customer Number
            </label>
        <div className=" flex items-center w-full rounded-inputBRedius bg-inputBgColor ">
          <input
            onClick={handleDropdown}
            id="textbox_id"
            type="search"
            onChange={searchHandle}
            placeholder="search for customer"
            value={selectOption || "  "}
            className="w-full rounded-inputBRedius bg-inputBgColor p-2"
          />
          <div
            onClick={handleDropdown}
            className="pr-2 flex justify-center cursor-pointer"
          >
            <SearchIcon />
          </div>
        </div>
        <div
          className={`relative w-full z-20 ${
            showDropdown ? "block" : "hidden"
          }`}
        >
          {filteredData && (
            <div className="absolute w-full bg-white border border-gray-300 rounded mt-1">
              <ul onClick={handleDropdown} className="max-h-60 overflow-y-auto">
                {filteredData.map((option) => (
                  <li
                    key={option.username}
                    onClick={() => searchById(option || "")}
                    className="hover:bg-primeryBgColor hover:text-white p-2 cursor-pointer"
                  >
                    {option.mobile}
                    {/* {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName} */}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDropDown;
