import React, { useEffect, useState,useRef } from "react";
import { PrimeButton, BackButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../function/utilies";
import InputError from "../../component/InputError";
// import Dropdown from "../../component/Dropdown";
import StateName from "../../component/dropdown/StateName";
import CityName from "../../component/dropdown/CityName";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../store/features/userSlice";


const initialValues = {
  stationName: "",
  email: "",
  number: "",
  address: "",
  pincode: "",
  state: "",
  city: "",
};

const EditStation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allStationRaw, setAllStationRaw] = useState();
  // const [isChange, setIsChange] = useState(false);

  const ConstomerCreateSchema = Yup.object({
    stationName: Yup.string().required("Station Name name should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    number: Yup.number().required("number should not be empty"),
    address: Yup.string().required("address should not be empty"),
    state: Yup.string().required("State should not be empty"),
    city: Yup.string().required("City should not be empty"),

    pincode: Yup.number().required("Pin Code should not be empty"),
    // IDNumber: Yup.number().required("ID Number should not be empty"),
    // document: Yup.string().required("Upload Document"),
    // photo: Yup.string().required("Upload Photo"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,

    onSubmit: async (values, action) => {
      setButtonLoader(true);

      let data = await valuesCheck(values, allStationRaw)

      let stationId;
      if (params && params.stationId && params.stationId !== " ") {
        stationId = params.stationId
      }
      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `station/update/${stationId}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);  
          setButtonLoader(false);
          navigate(-1);
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        // alertResponseMsgError(error.response.msg);
      }
    },
  });

  //------------------------------get station Detail-----------------------------------//

  const getStationDetail = async(stationId)=>{
    try{
      const response = await getAxiosWithToken({
        method:"get",
        url:`station/details/${stationId}`,
      });
      if(response && response.data && response.data.data){
        setAllStationRaw(response.data.data);
      }
    }catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  }

  useEffect(() => {
    if (allStationRaw) {
      values.stationName= allStationRaw.stationName;
      values.number = allStationRaw.number;
      values.email = allStationRaw.email;
      values.address = allStationRaw.address
      values.pincode = allStationRaw.pincode;
      values.state = allStationRaw.state;
      values.city = allStationRaw.city;
    }
  }, [allStationRaw]);

  const valueRef = useRef(values);

  useEffect(() => {
    if (params && params.stationId && params.stationId !== " ") {
      valueRef.current = valuesEmpty(values, "");
      getStationDetail(params.stationId);
    } else {
      window.location.href = "/station";
    }
  }, [params]);

  return (
    <div className="px-8 py-5">
      <BackButton btnName="Edit Station Details" />
      <form onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
        <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label for="stationName" className=" w-36 md:flex-none text-sm font-medium">Station Name</label>
            <div className="w-full">
              <input
                type="text"
                name="stationName"
                value={"" || values.stationName}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="Station Name"
              />
              <InputError
                error={errors.stationName}
                touched={touched.stationName}
              />
            </div>
          </div>
          
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Contact
            </label>
            <div className="w-full">
              <input
                type="text"
                name="number"
                value={"" || values.number}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Number"
              />
              <InputError error={errors.number} touched={touched.number} />
            </div>
          </div>
          <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Email ID
            </label>
            <div className="w-full">
              <input
                type="email"
                name="email"
                value={"" || values.email}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Email"
              />
              <InputError error={errors.email} touched={touched.email} />
            </div>
          </div>
          <div className="div col-span-6 font-semibold text-left text-lg">
            Addresss
          </div>
          <div className="col-span-6 gap-6  lg:flex py-2  items-center">
            <label className="w-36 md:flex-none text-sm font-medium ">
              Address / Street
            </label>
            <div className="w-full">
              <input
                type="text"
                name="address"
                value={"" || values.address}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Address / Street"
              />
              <InputError error={errors.address} touched={touched.address} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              State
            </label>
            <div className="w-full">
            <StateName handleChange={handleChange}name={"state"} value={values.state}/>
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              City
            </label>
            <div className="w-full">
            <CityName handleChange={handleChange} name={"city"} value={values.city} stateId={values.state}/> 
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Pin Code
            </label>
            <div className="w-full">
              <input
                type="text"
                name="pincode"
                value={"" || values.pincode}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Pin Code"
              />
              <InputError error={errors.pincode} touched={touched.pincode} />
            </div>
          </div>
         
        </div>
        <div className="mt-12">
          <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
        </div>
      </form>
    </div>
  );
};

export default EditStation;
