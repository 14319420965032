import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../../../component/PageName";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import Pagination from "../../../component/Pagination";
import AdminCard from "../AdminCard";

const DeactiveAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableHead = ["S.No", "Admin ID", "Name", "Contact", "Action"];
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [dataLoading, setDataLoading] = useState(false);
  const [adminCount, setAdminCount] = useState(0);
  const [allAdminRaw, setallAdminRaw] = useState([]);
  const [allCustomer, setAllCustomer] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const ManageData = async () => {
    setDataLoading(true);
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `sub/admin/all?&page=${pageNumber}&perPage=${25}&status=2`,
      });
      if (response && response.data && response.data.data) {
        setallAdminRaw(response.data.data);
        setAdminCount(response.data.count || 0);
        settotalCount(response.data?.totalCount);
        setDataLoading(false);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
          setDataLoading(false);
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
          setDataLoading(false);
        }
      }
    }

    // console.log(response);
  };

  useEffect(() => {
    if (isLogged) {
      ManageData();
    }
  }, [isLogged, deleteDeatils, pageNumber]);

  useEffect(() => {
    const modifiedData = allAdminRaw.map((admin) => {
      return {
        item01: admin.username,
        item02: admin.firstName
          ? `${admin.firstName} ${admin.middleName} ${admin.lastName}`
          : `${admin.firstName} ${admin.lastName}`,
        item03: admin.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: admin.username,
      };
    });
    setAllCustomer(modifiedData);
  }, [allAdminRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Admin" href={RoutePath.AddAdmin} />
        <AdminCard isActive="deactive"/>

        {/* <div className="grid sm:grid-cols-2  justify-items-stretch gap-6 p-4 cursor-pointer">
          <div>
            <Link to={RoutePath.Admin}>
              <Statusbar
                isActive={true}
                src={totaldriver}
                count={adminCount || 0}
                title="Sub Admin"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.SuperAdmin}>
              <Statusbar
                src={customer}
                count={"Admin" || 0}
                title="Super Admin"
                // rating="4% (30 days)"
              />
            </Link>
          </div>
        </div> */}
        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allCustomer}
            DeleteUrl={"sub/admin/delete"}
            eyeButton={RoutePath.EditAdminDetailLink}
            editButton={RoutePath.EditAdminDetailLink}
            isDataLoading={dataLoading}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default DeactiveAdmin;
