import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import DeleteDialogBox from "../../../component/DeleteDialogBox";
import Dropdown from "../../../component/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  formateDate,
  formatDateToISO,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import { useParams } from "react-router-dom";
import CityName from "../../../component/dropdown/CityName";
import StateName from "../../../component/dropdown/StateName";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import CustomerDropDown from "../../../component/dropdown/CustomerDropDown";

let initialValues = {
  startStation: "",
  endStation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  locality: "",
  quotationDate: "",
  proposedDeliveryDate: "",
  senderName: "",
  senderGgt: "",
  senderLocality: " ",
  senderState: "",
  senderCity: "",
  receiverName: "",
  receiverGgt: "",
  receiverLocality: "",
  receiverState: "",
  receiverCity: "",
  toPincode: " ",
  noOfParcel: "",
  toPay: "",
  weight: "",
  amount: "",
  additionalCmt: "",
  sTax: "",
  grandTotal: "",
};

const EditCustomerQuotationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allBookingRaw, setAllBookingRaw] = useState();
  const [startStationId, setStartStationId] = useState();
  const [endStationId, setEndStationId] = useState();
  const [fromOrderId, setFromOrderId] = useState();
  const [toOrderId, setToOrderId] = useState();
  const [newcustomerId,setNewcustomerId]= useState();



  useEffect(() => {
    if (startStationId) {
      setFieldValue("startStation", startStationId);
    }
    if (endStationId) {
      setFieldValue("endStation", endStationId);
    }
  }, [startStationId, endStationId]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userCreateSchema = Yup.object({
    startStation: Yup.string().required("Start station should not be empty"),
    endStation: Yup.string().required(
      "Destination station should not be empty"
    ),
    customerName: Yup.string().required("Mobile name should not be empty"),
    mobile: Yup.number().required("Contact number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    quotationDate: Yup.date()
      .required("Booking date should not be empty")
      .min(today, "Booking date should be today or a future date"),
    proposedDeliveryDate: Yup.date()
      .required("Delivery date should not be empty")
      .min(today, "Delivery date should be today or a future date"),
    firstName: Yup.string().required("First Name Date should not be empty"),
    lastName: Yup.string().required("Last Name Date should not be empty"),
    senderName: Yup.string().required("Sender Name should not be empty"),
    senderLocality: Yup.string().required("Locality should not be empty"),
    receiverName: Yup.string().required("Receiver Name should not be empty"),
    receiverLocality: Yup.string().required("Locality should not be empty"),
    sTax: Yup.number().required("sTax*"),
    grandTotal: Yup.number().required("Grand Total*"),
  });
  //API Call for updatation of from details and to details--------------------------------------
  const addressUpdate = async (orderAddressId, type) => {
    console.log("orderAddressId:", orderAddressId);
    let data = new FormData();
    if (type === "receiver") {
      // Appending receiver's address details
      data.append("personName", values.receiverName);
      data.append("address", values.receiverLocality);
      // data.append("address2", ""); // Assuming no value for address2
      data.append("city", values.receiverCity);
      data.append("state", values.receiverState);
      data.append("pincode", values.toPincode);
    } else if (type === "sender") {
      // Appending sender's address details
      data.append("personName", values.senderName);
      data.append("address", values.senderLocality);
      // data.append("address2", ""); // Assuming no value for address2
      data.append("city", values.senderCity);
      data.append("state", values.senderState);
      data.append("pincode", values.senderPincode);
    }

    try {
      const response = await getAxiosWithToken({
        method: "patch",
        url: `order/address/update/${orderAddressId}`, // API endpoint for updating address
        data: data,
      });

      // Handle API response
      if (response.data.success) {
        alertResponseMsgSuccess(response.data.msg);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alertResponseMsgError(error.response?.data?.msg || "An error occurred.");
    }
  };

  let { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userCreateSchema,

      onSubmit: async (values, action) => {
        setButtonLoader(true);
        // console.log("submisdasdasdasdt")

        const productsWithNoId = products.filter(
          (product) => !product.orderProductId
        );

        // const data = {
        //   startStation: values.startStation,
        //   endStation: values.endStation,
        //   customerName: values.customerName,
        //   mobile: values.mobile,
        //   email: values.email,
        //   locality: values.locality,
        //   bookingDate: values.bookingDate,
        //   proposedDeliveryDate: values.deliveryDate,
        //   fromCustomerName: values.senderName,
        //   fromAddress: values.senderLocality,
        //   fromCity: values.city,
        //   fromState: values.state,
        //   fromPincode: values.senderPincode,
        //   toCustomerName: values.receiverName,
        //   toAddress: values.receiverLocality,
        //   toState: "toState",
        //   toCity: "DemotoCity",
        //   toPincode: values.toPincode,
        //   additionalCmt: values.amount,
        //   freight: values.freight,
        //   cgst: values.cgst,
        //   sgst: values.sgst,
        //   igst: values.igst,
        //   bill_t: values.bill_t,
        //   grandTotal: values.grandTotal,
        //   newProductDetails: productsWithNoId,
        // };
        let updatedvalue = await valuesCheck(values, allBookingRaw);
        let data = new FormData();
        for (const key in updatedvalue) {
          if (updatedvalue.hasOwnProperty(key)) {
            const value = updatedvalue[key];
            // console.log(`Appending ${key}: ${value}`);
            data.append(key, value);
          }
        }
        if (productsWithNoId.length > 0) {
          productsWithNoId.forEach((product, index) => {
            for (const key in product) {
              if (product.hasOwnProperty(key)) {
                data.append(
                  `newProductDetails[${index}][${key}]`,
                  product[key]
                );
              }
            }
          });
        }

        let quotationOrderId;
        if (
          params &&
          params.quotationOrderId &&
          params.quotationOrderId !== " "
        ) {
          quotationOrderId = params.quotationOrderId;
        }
        //------Condition checking if valure update--------------------------------
        if (
          values.senderName !== allBookingRaw.fromAddress.personName ||
          values.senderLocality !== allBookingRaw.fromAddress.address ||
          values.senderState !== allBookingRaw.fromAddress.state ||
          values.senderCity !== allBookingRaw.fromAddress.city ||
          values.senderPincode !== allBookingRaw.fromAddress.pincode
        ) {
          // Call the addressUpdate for the sender only if the values have changed
          await addressUpdate(fromOrderId, "sender");
        }
        if (
          values.receiverName !== allBookingRaw.toAddress.personName ||
          values.receiverLocality !== allBookingRaw.toAddress.address || // Corrected this line
          values.receiverState !== allBookingRaw.toAddress.state ||
          values.receiverCity !== allBookingRaw.toAddress.city ||
          values.toPincode !== allBookingRaw.toAddress.pincode
        ) {
          // Call the addressUpdate for the receiver only if the values have changed
          await addressUpdate(toOrderId, "receiver");
        }
        if(newcustomerId){
          data.append("customerId", newcustomerId);
        }
        try {
          const response = await getAxiosWithToken({
            method: "Patch",
            url: `quotation/update/${quotationOrderId}`, // Adjust the API endpoint as needed
            data: data,
            reqType: "formData",
          });
          // API response 200 201
          if (response.data.success) {
            alertResponseMsgSuccess(response.data.msg);
            setButtonLoader(false);
            navigate(-1);
          } else {
            alertResponseMsgError(response.data.msg);
            setButtonLoader(false);
          }
        } catch (error) {
          console.error(error);
          setButtonLoader(false);
          alertResponseMsgError(error.response.data.msg);
        }
        //update address
      },
    });
  const getBookingDeatils = async (quotationOrderId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `quotation/details/${quotationOrderId}`,
      });
      if (response && response.data && response.data.data) {
        setAllBookingRaw(response.data.data.quotationDetails);
        setFromOrderId(
          response.data.data.quotationDetails.fromAddress.orderAddressId
        );
        setToOrderId(
          response.data.data.quotationDetails.toAddress.orderAddressId
        );
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };


  useEffect(() => {
    let bookingDetail = allBookingRaw;
    if (bookingDetail) {
      values.startStation = bookingDetail.startStation;
      values.endStation = bookingDetail.endStation;
      values.firstName = bookingDetail.firstName;
      values.middleName = bookingDetail.middleName;
      values.lastName = bookingDetail.lastName;
      values.mobile = bookingDetail.mobile;
      values.email = bookingDetail.email;
      values.locality = bookingDetail.locality;
      values.quotationDate = formatDateToISO(
        formateDate(bookingDetail.quotationDate)
      );
      values.proposedDeliveryDate = formatDateToISO(
        formateDate(bookingDetail.proposedDeliveryDate)
      );
      values.senderName = bookingDetail.fromAddress.personName;
      values.senderLocality = bookingDetail.fromAddress.address;
      values.senderState = bookingDetail.fromAddress.state;
      values.senderCity = bookingDetail.fromAddress.city;
      values.senderPincode = bookingDetail.fromAddress.pincode;
      values.receiverName = bookingDetail.toAddress.personName;
      values.receiverLocality = bookingDetail.toAddress.address;
      values.receiverState = bookingDetail.toAddress.state;
      values.receiverCity = bookingDetail.toAddress.city;
      values.toPincode = bookingDetail.toAddress.pincode;
      values.additionalCmt = bookingDetail.additionalCmt;
      values.sTax = bookingDetail.sTax;
      values.grandTotal = bookingDetail.grandTotal;
    }
  }, [allBookingRaw]);

  console.log("values.fromState",values.senderState );


  // console.log("quotaion",values.quotationDate);

  //------------------ Address API------------------------------------------------------

  useEffect(() => {
    if (params && params.quotationOrderId && params.quotationOrderId !== " ") {
      getBookingDeatils(params.quotationOrderId);
    } else {
      window.location.href = "/quotation";
    }
  }, [params]);

  const [lastIndexValue, setLastIndexValue] = useState(0);
  const [productCount, setProductCount] = useState(1);
  const [products, setProducts] = useState([{ id: 1 }]);

  useEffect(() => {
    if (allBookingRaw && allBookingRaw.products) {
      const allProduct = allBookingRaw.products;
      setProducts(
        allProduct.map((product, index) => ({
          ...product,
          id: index + 1,
          isChange: true,
        }))
      );
      setLastIndexValue(allProduct.length);
      setProductCount(allProduct.length);
    }
  }, [allBookingRaw]);

  const addRowInProduct = () => {
    const newId = lastIndexValue + 1;
    setLastIndexValue(newId);
    setProductCount(productCount + 1);

    setProducts((prevProducts) => [...prevProducts, { id: newId }]);
  };
  // ---------------------------- update Product--------------------------

  const productUpdate = async (product) => {
    setButtonLoader(true);

    const orderProductIdToFilter = await product.orderProductId;
    const newProductArray = await allBookingRaw.products.filter(
      (item) => item.orderProductId === orderProductIdToFilter
    );
    let data = await valuesCheck(product, newProductArray[0]);

    try {
      const response = await getAxiosWithToken({
        method: "PATCH",
        url: `order/product/update/${product.orderProductId}`,
        data: data,
      });
      // console.log(response);
      if (response.data.success) {
        alertResponseMsgSuccess(response.data.msg);
        setButtonLoader(false);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.log(error);
      // Check if error.response exists before accessing its properties
      if (error.response && error.response.data && error.response.data.msg) {
        alertResponseMsgError(error.response.data.msg);
      } else {
        alertResponseMsgError("An error occurred"); // Provide a generic error message
      }
    }
  };

  // ---------------------------- Delete Product--------------------------

  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});
  const [deleteModelOrderId, setDeleteModelOrderId] = useState("");

  const removeRowInProduct = (orderProductId) => {
    if (!products || products.length === 0) {
      return;
    }
    const productToRemove = products.find(
      (product) => product.id === orderProductId
    );
    if (productToRemove) {
      if (productToRemove.orderProductId) {
        setOpen(!open);
        setDeleteModelData({
          deleteUrl: `order/product/delete/${productToRemove.orderProductId}`,
          headerText: `This is Delete Confirmation`,
          paraText: `Tihs ID ${productToRemove.orderProductId} will be deleted.`,
        });
        setDeleteModelOrderId(productToRemove.orderProductId);
      } else {
        const updatedProducts = products.filter(
          (product) => product.id !== orderProductId
        );
        // Update IDs for the remaining products
        const updatedProductsWithNewIds = updatedProducts.map(
          (product, index) => ({ ...product, id: index + 1 })
        );
        setLastIndexValue(lastIndexValue - 1);
        setProductCount(productCount - 1);
        setProducts(updatedProductsWithNewIds);
      }
    }
  };

  //------------------------------------------------------calculate total amount and taxes--------------------------------------//
  const [allItemAmount, setAllItemAmount] = useState(0);
  const [sTaxTotal, setSTaxTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const handleProductChange = (e, productId) => {
    const { name, value } = e.target;
    if (name === "amount" && isNaN(value)) {
      return false;
    }
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [name]: value } : product
      )
    );
  };

  useEffect(() => {
    handleGstCalculate();
  }, [products]);
  const handleGstCalculate = async () => {
    let amount = 0;

    await products.map((product) => {
      amount =
        parseInt(amount) + (product.amount ? parseInt(product.amount) : 0);
    });
    setAllItemAmount(amount);
  };
  const staxAmount = values.sTax;
  useEffect(() => {
    const taxFunction = (staxAmount) => {
      return (allItemAmount / 100) * staxAmount;
    };
    if (staxAmount <= 100) {
      const sTex = taxFunction(staxAmount);
      const roundedTax = sTex.toFixed(2);
      setSTaxTotal(roundedTax);
    } else {
      // Handle error or invalid input
      console.error("Invalid GST percentage provided.");
    }
  }, [staxAmount, allItemAmount]);
  useEffect(() => {
    if (sTaxTotal !== null && allItemAmount !== null) {
      const totalAmount = Number(sTaxTotal) + allItemAmount;
      setGrandTotal(totalAmount);
    }
  }, [sTaxTotal, allItemAmount]);
  values.grandTotal = `${grandTotal || null}`;
  //---------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    if (isDeleted) {
      const updatedProducts = products.filter(
        (product) => product.orderProductId !== deleteModelOrderId
      );
      // Update IDs for the remaining products
      const updatedProductsWithNewIds = updatedProducts.map(
        (product, index) => ({ ...product, id: index + 1 })
      );
      setLastIndexValue(lastIndexValue - 1);
      setProductCount(productCount - 1);
      setProducts(updatedProductsWithNewIds);
    }
  }, [isDeleted]);

 
    //-------------------------Customer Details---------------------------------------------- 
  let [CustomerDetailsApiRaw, setCustomerDetailsApiRaw] = useState({});

  const [customerData,setcustomerData]= useState();


  // console.log("customerData",customerData)
  const getCustomerDeatil = async (newcustomerId)=>{
    if(newcustomerId){
      try{
        const response = await getAxiosWithToken({
          method: "get",
          url:`customer/details/${newcustomerId}`,
        });
        if (response && response.data && response.data.data) {
          setcustomerData(response.data.data)
          values.firstName = `${
            response.data.data.firstName ? response.data.data.firstName : ""
          }`;
          values.middleName = `${
            response.data.data.middleName ? response.data.data.middleName : ""
          }`;
          values.lastName = `${
            response.data.data.lastName ? response.data.data.lastName : ""
          }`;
          values.mobile = `${
            response.data.data.mobile ? response.data.data.mobile : ""
          }`;
          values.email = `${
            response.data.data.email ? response.data.data.email : ""
          }`;

          return {
            firstName: response.data.data?.firstName,
            middleName: response.data.data?.middleName,
            lastName: response.data.data?.lastName,
            mobile: response.data.data?.mobile,
            email: response.data.data?.email,
          };
        } else{
          setCustomerDetailsApiRaw({
                  firstName: "",
                  middleName: "",
                  lastName: "",
                });
        }
        if(response){
          console.log(response.data.data)
         
        }
      }catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(()=>{
    getCustomerDeatil(newcustomerId);
  },[newcustomerId])

  useEffect(() => {
    if (CustomerDetailsApiRaw.firstName) {
      values.firstName = `${
        CustomerDetailsApiRaw.firstName ? CustomerDetailsApiRaw.firstName : ""
      }`;
      values.middleName = `${
        CustomerDetailsApiRaw.middleName ? CustomerDetailsApiRaw.middleName : ""
      }`;
      values.lastName = `${
        CustomerDetailsApiRaw.lastName ? CustomerDetailsApiRaw.lastName : ""
      }`;
      values.mobile = `${
        CustomerDetailsApiRaw.mobile ? CustomerDetailsApiRaw.mobile : ""
      }`;
      values.email = `${
        CustomerDetailsApiRaw.email ? CustomerDetailsApiRaw.email : ""
      }`;
    } else {
      // console.log(CustomerDetailsApiRaw);
      values.customerName = " ";
      values.mobile = " ";
      values.email = " ";
    }
  }, [CustomerDetailsApiRaw]);

  return (
    <div>
      <div className="p-8">
        <BackButton btnName="Edit Customer Quotation" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Start Station
              </label>
              <Dropdown
                label={"Station"}
                name={"startStation"}
                SetStationId={setStartStationId}
                value={values.startStation || ""}
              />
              <InputError
                error={errors.startStation}
                touched={touched.startStation}
              />
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Destination Station
              </label>
              <div className="w-full">
                <Dropdown
                  label={"Station"}
                  name={"startStation"}
                  SetStationId={setEndStationId}
                  value={values.endStation || ""}
                />
                <InputError
                  error={errors.endStation}
                  touched={touched.endStation}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Date of Booking
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="quotationDate"
                  value={"" || values.quotationDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Booking"
                />
                <InputError
                  error={errors.quotationDate}
                  touched={touched.quotationDate}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Proposed Date of Delivery
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="proposedDeliveryDate"
                  value={"" || values.proposedDeliveryDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Proposed Date of Delivery"
                />
                <InputError
                  error={errors.proposedDeliveryDate}
                  touched={touched.proposedDeliveryDate}
                />
              </div>
            </div>
            {/* <label className="col-span-6 block my-2 text-sm font-medium text-gray-900">
              Customer Name
            </label> */}
            <div className="col-span-6 py-2 items-center">
            <CustomerDropDown setNewcustomerId={setNewcustomerId}/>

              {/* <select
                defaultValue={customerId}
                name="customerId"
                onChange={handleChangeCustomer}
                className=" w-full rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value={0} hidden disabled>
                  Select the customer
                </option>
                <option value={"none"}>None</option>
                {allCustomerRaw.map((option, index) => (
                  <option key={index} value={option.username}>
                    {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName}
                  </option>
                ))}
              </select> */}
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="firstName"
                  value={"" || values.firstName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="First Name"
                />
                <InputError
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="middleName"
                  value={"" || values.middleName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Middle Name"
                />
                <InputError
                  error={errors.middleName}
                  touched={touched.middleName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="lastName"
                  value={"" || values.lastName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Last Name"
                />
                <InputError
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={"" || values.mobile}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Email Address
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
          </div>

          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold  mb-5 mt-5 ">
              From <p className="text-sm">(Address)</p>{" "}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderName"
                  value={"" || values.senderName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Sender Name"
                />
                <InputError
                  error={errors.senderName}
                  touched={touched.senderName}
                />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderLocality"
                  value={"" || values.senderLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.senderLocality}
                  touched={touched.senderLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"senderState"}
                  value={values.senderState || " "}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"senderCity"}
                  value={values.senderCity || " "}
                  stateId={values.senderState}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderPincode"
                  value={"" || values.senderPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.senderPincode}
                  touched={touched.senderPincode}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold  mb-5 mt-5 ">
              To <p className="text-sm">(Address)</p>{" "}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverName"
                  value={"" || values.receiverName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Receiver Name"
                />
                <InputError
                  error={errors.receiverName}
                  touched={touched.receiverName}
                />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverLocality"
                  value={"" || values.receiverLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.receiverLocality}
                  touched={touched.receiverLocality}
                />
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
              <StateName
                  handleChange={handleChange}
                  name={"receiverState"}
                  value={values.receiverState || " "}
                />
                
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"receiverCity"}
                  value={values.receiverCity || ""}
                  stateId={values.receiverState}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="toPincode"
                  value={"" || values.toPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.toPincode}
                  touched={touched.toPincode}
                />
              </div>
            </div>
          </div>
          <div className="text-lg font-semibold text-left mb-5 mt-5">
            <p className="text-xl font-bold">Product Details</p>
          </div>
          <div className="w-full">
            {products.map((product) => (
              <div key={product.id}>
                <div
                  id="createDivBox"
                  className=" grid md:grid-flow-col items-end  gap-x-4 "
                >
                  <div className="py-2 items-center">
                    <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                      S. No.
                    </label>
                    <div className="w-12">
                      <input
                        value={product.id + "."}
                        disabled
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      />
                    </div>
                  </div>
                  <div className=" col-span-1 py-2  items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      No. of Parcel
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="noOfParcel"
                        value={product.noOfParcel}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="No. of Parcel"
                      />
                      <InputError
                        error={errors.noOfParcel}
                        touched={touched.noOfParcel}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      To Pay/Paid
                    </label>
                    <div className="w-full ">
                      <select
                        name="toPay"
                        value={product.toPay}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      >
                        <option value="">None</option>
                        <option value="Pay">Pay</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <InputError
                        error={errors.toPay}
                        touched={touched.toPay}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      Weight Kgs.
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="weight"
                        value={product.weight}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="Weight Kgs."
                      />
                      <InputError
                        error={errors.weight}
                        touched={touched.weight}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      Amount{" "}
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="amount"
                        value={product.amount}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="Amount"
                      />
                      <InputError
                        error={errors.amount}
                        touched={touched.amount}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex gap-3 justify-center m-2">
                    {product.orderProductId && (
                      <div className="col-span-2 flex gap-3 justify-center m-2">
                        {product.isChange ? (
                          <div onClick={() => productUpdate(product)}>
                            <PrimeButton
                              btnName="Update"
                              btnType="button"
                              className="bg-green-700 hover:bg-green-800"
                              buttonLoader={buttonLoader}
                            />
                          </div>
                        ) : (
                          <PrimeButton
                            btnName="Update"
                            btnType="button"
                            className="bg-gray-600 hover:bg-gray-600 cursor-not-allowed"
                          />
                        )}
                        <div
                          className={product?.id}
                          onClick={() => removeRowInProduct(product?.id)}
                        >
                          <PrimeButton
                            btnName="Remove"
                            btnType="button"
                            className="bg-red-700 hover:bg-red-800"
                          />
                        </div>
                      </div>
                    )}

                    {/* Additional code for the Remove button outside the condition */}
                    {product.orderProductId ? (
                      ""
                    ) : (
                      <div
                        // className={product?.id}
                        onClick={() => removeRowInProduct(product?.id)}
                      >
                        <PrimeButton
                          btnName="Remove"
                          btnType="button"
                          className="bg-red-700 hover:bg-red-800"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div onClick={addRowInProduct} className="w-full mb-4">
            <PrimeButton
              btnName="+ Add Item"
              btnType="button"
              className=" sm:w-full"
            />
          </div>
          <div className="grid md:grid-cols-6 justify-between p-2">
            <div className=" col-span-4 w-full py-2">
              <label className="block mb-2  font-medium text-gray-900 text-left">
                Additional Comments
              </label>
              <textarea
                name="additionalCmt"
                id=""
                cols="40"
                rows="10"
                className="rounded-inputBRedius bg-inputBgColor w-full h-full p-2"
                placeholder="Additional Comments"
                value={"" || values.additionalCmt}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="col-span-2 items-center">
              <div className="lg:flex gap-6 p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  S.Tax
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="sTax"
                    value={"" || values.sTax}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder=" S.Tax"
                  />
                  <InputError error={errors.sTax} touched={touched.sTax} />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Grand Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="grandTotal"
                    value={"" || values.grandTotal}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Grand Total"
                  />
                  <InputError
                    error={errors.grandTotal}
                    touched={touched.grandTotal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
          </div>
        </form>
      </div>
      <DeleteDialogBox
        open={open}
        setOpen={setOpen}
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
        data={deleteModelData}
      />
    </div>
  );
};

export default EditCustomerQuotationDetail;
