import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RoutePath } from "../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import customer from "../../img/customer.svg";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../store/features/userSlice";
import Pagination from "../../component/Pagination";
import CustomerCard from "./CustomerCard";


const ManageCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {  isLogged,deleteDeatils } = useSelector((state) => state.user);
  const tableHead = ["S. No", "Customer ID", "Name", "Contact", "Action"];
  const [dataLoading, setDataLoading] = useState(false);
  const [allCustomerRaw, setAllCustomerRaw] = useState([]);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [allCustomer, setAllCustomer] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  //------------------------------------All Customer Details----------------------------//
  const customerFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `customer/all?&page=${pageNumber}&perPage=${25}&status=1`,
      });
      if (response.data.success) {
        setAllCustomerRaw(response.data.data);
        settotalCount(response.data?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  
  useEffect(() => {
    if (isLogged) {
      customerFetchDeatils();
    }
  }, [isLogged,deleteDeatils,pageNumber]);

  useEffect(() => {
    if (allCustomerRaw?.length <= 0) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  }, [allCustomerRaw]);

  useEffect(() => {
    const modifiedData = allCustomerRaw?.map((customer) => {
      return {
        item01: customer.username,
        item02: customer.firstName
          ? `${customer.firstName || ""} ${customer.middleName || ""} ${customer.lastName || ""}`
          : `${customer.firstName} ${customer.lastName}`,
        item03: customer.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: customer.username,
      };
    });
    // console.log("address",customer.address1);
    setAllCustomer(modifiedData);
  }, [allCustomerRaw]);

    //-------------------------------------------------------------------------------------------------------
    const bookingFetchDeatils = async () => {
      try {
        const response = await getAxiosWithToken({
          method: "get",
          url: "dashboard?type=customer",
        });
        if (response.data.success) {
          setAllDataRaw(response.data.data.customer);
        }
      } catch (error) {
        console.error("Error fetching owner details:", error);
        // alertResponseMsgError('An error occurred');
        if (
          error.response.status &&
          error.response.status === 401 &&
          error.response.statusText &&
          error.response.statusText === "Unauthorized"
        ) {
          navigate("/");
          try {
            await dispatch(userLogOut());
            // Redirect to root page after successful logout
          } catch (error) {
            console.error("Logout failed:", error);
            // Handle error as needed
          }
        }
      }
    };
    useEffect(() => {
      if (isLogged) {
        bookingFetchDeatils();
      }
    }, [isLogged]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Customer" href={RoutePath.AddCustomerForm} />
        <CustomerCard isActive='totoal'/>
        {/* <div className="grid md:grid-cols-2">
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={customer}
            count={allDataRaw?.active?.recentCount || 0}
            title="Total Customer"
            // rating={}
            rating={`${Math.floor(allDataRaw?.total?.percentage)}%`}
          />
        </div>
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={customer}
            count={allDataRaw?.active?.recentCount || 0}
            title="Block Customer"
            // rating={}
            rating={`${Math.floor(allDataRaw?.total?.percentage)}%`}
          />
        </div>
        </div> */}
       
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
          <input type="search" className=" rounded-inputBRedius bg-inputBgColor p-2 " />
            <AddFilterButton href={RoutePath.AddCustomerForm} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allCustomer}
            DeleteUrl={"customer/delete"}
            eyeButton={RoutePath.EditCustomerDetailLink}
            editButton={RoutePath.EditCustomerDetailLink}
            isDataLoading={dataLoading}
          />
           <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        dataLength={totalCount}
      />
        </div>
      </div>
    </div>
  );
};

export default ManageCustomer;
