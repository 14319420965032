import React, { useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Routing from './map/RoutingMachine';
import L from 'leaflet'; // Import Leaflet
import markIcon from '../img/mark.png';
import 'leaflet/dist/leaflet.css';

const Mapper = () => {
  const [map, setMap] = useState(null);
  const [isMapInit, setIsMapInit] = useState(false);
  const [position] = useState({ lat: 17.4, lng: 78.4 });

  const customMarkerIcon = L.icon({
    iconUrl: markIcon,
    iconSize: [40, 40],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const saveMap = (map) => {
    setMap(map);
    setIsMapInit(true);
  };

  return (
    <>
      <div>
        <MapContainer center={position} zoom={13} style={{ height: '85vh', width: '100%' }} whenCreated={saveMap}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
          />
           {isMapInit && (
          <Routing map={map} />
        )}
          <Marker position={position} icon={customMarkerIcon}>
            <Popup>
              <span>Current Position<br/> ID ...........</span>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </>
  );
};

export default Mapper;
