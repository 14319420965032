import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";

const StateName = ({name, handleChange, value = ""}) => {
  const [allState, setAllState] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const getStateName = async () => {
        // setDataLoading(true)
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "get/states",
      });
      if (response && response.data && response.data.data) {
        setAllState(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(()=>{
    getStateName();
  },[dataLoading]);
//   console.log("allState",allState)

  return (
    <div>
      <select  value={value}
        name={name}
        onChange={handleChange} id="state" className=" w-full rounded-inputBRedius bg-inputBgColor p-2">
      <option value="">Select State</option>
        {allState && allState.length > 0 ? allState.map((state, index)=>(
            <option key={index} value={state.stateId}>{state.name}</option>

        )) : <option value='null' >Not Found</option> }
      </select>
    </div>
  );
};

export default StateName;

//component value
{/* <StateName handleChange={handleChange}name={"state"} value={values.state}/> */}