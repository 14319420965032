import { useDispatch, useSelector } from "react-redux";

import { RoutePath } from "../../../router/RoutePath";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import SendIcon from "@mui/icons-material/Send";
import { DynamicTable } from "../../../tables/DynamicTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PageName from "../../../component/PageName";
import { formateDate, formatDateToISO } from "../../../function/utilies";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { userLogOut } from "../../../store/features/userSlice";
import BookingCard from "../BookingCard";
import Pagination from "../../../component/Pagination";
import SearchBar from "../../../component/props/SearchBar";

const BookingRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [allBooking, setAllBooking] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // console.log("totalCount",totalCount)
  //------------------------------------All Booking Details----------------------------//
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `booking/all?status=1&page=${pageNumber}&perPage=${25}&search=${
          searchValue || " "
        }`,
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data);
        settotalCount(response.data?.totalCount);
        // console.log("response.data?.totalCount",response.data?.totalCount)
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  const Head = [
    "S.No.",
    // "Booking ID",
    "Order by",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Contact",
    "Action",
  ];

  // console.log("allBooking",allBookingRaw);

  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      // Format booking date
      let formattedDate = formatDateToISO(formateDate(booking.bookingDate));

      return {
        item01: booking.added_by === "self" ? "Customer" : "Admin",
        item02: formattedDate || "",
        item03: booking.fromPersonName || "",
        item04: booking.fromAddress?.slice(0, 10) || "",
        itemto: "to",
        item05: booking.toPersonName || "",
        item06: booking.toAddress?.slice(0, 10) || "",
        item07: booking.mobile || "",
        item08: booking.toCustomerName || "",
        // img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        cancel: <DoNotDisturbOffOutlinedIcon />,
        share: <SendIcon />,
        pageName: "booking", //to identify the detail booking or quotation

        unqiueKey: booking.bookingOrderId,
      };
    });

    // Update state with modified data
    setAllBooking(modifiedData);
  }, [allBookingRaw]);
  // const dataLength = allBookingRaw.length;

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <PageName pageName="Manage Booking" href={RoutePath.BookOrderForm} />
        {/* <AddFilterButton href={RoutePath.BookOrderForm} /> */}
      </div>
      <BookingCard isActive="booking" />
      <SearchBar handleInputChange={handleInputChange} value={searchValue} />
      <DynamicTable
        tableHeadRow={Head}
        data={allBooking}
        DeleteUrl={"booking/delete"}
        eyeButton={RoutePath.EditBookOrderLink}
        editButton={RoutePath.EditBookOrderLink}
        cancelUrl={"booking/cancel"}
      />
      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        dataLength={totalCount}
      />
    </div>
  );
};

export default BookingRequest;
