import React from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Link } from 'react-router-dom';

const PageName = ({pageName, href}) => {
  return (
    <div className="flex  items-center justify-between w-full px-5 pt-4">
      <div className="text-left text-xl text-mainTxtColor font-semibold">
        {pageName}
    </div>
    {href && <div className=" flex sm:justify-end justify-center gap-4">
        <Link to={href} className='flex items-center gap-2 bg-primeryBgColor hover:bg-blue-800 py-2 px-3 text-white rounded-xl'>
            <AddCircleOutlineOutlinedIcon/>
            <h1>Add</h1>
          </Link>
        </div>}
    
    </div>
    

  );
};

export default PageName;
