import React, { useRef } from "react";
import {PrimeButton, ShareButton} from "../component/PrimeButton";
import { useNavigate } from "react-router-dom";

const InvoiceBill = () => {
  const tableHead = ["No of Parcel", "To Pay-Paid", "Weight Kgs.", "Amount"];
  const gstTableHead = ["Subtotal", "Round Off (*)", "Grand Total"];
  const gstTableData = ["600.00", "0.00", "600.00"];
  const bankDltHead = ["Bank details", "IFS code", "Swift code", "Account #"];
  const bankDltData = [
    "ABCD BANK",
    "ABCD000XXXX",
    "ABCDUSBBXXX",
    "37474892300011",
  ];

  const tableData = [
    {
      delivery: "Item02",
      spefy: "Specification",
      qty: 20,
      rate: 8000,
      total: 1000,
    },
    {
      delivery: "Item02",
      spefy: "Specification",
      qty: 20,
      rate: 8000,
      total: 1000,
    },
  ];
  const printRef = useRef();

  const handlePrint = () => {
    var printContents = document.getElementById(printRef.current.id).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-start overflow-y-auto backdrop-blur-md fixed top-0 inset-0 z-50 ">
      <div ref={printRef} id="contentToPrint" className="bg-backgroundColor p-4 text-center ">
        <div className="col-span-2 mb-5 flex justify-between gap-6">
          <div onClick={handlePrint}><PrimeButton btnName="Print" /></div>
          <ShareButton shareUrl="https://www.example.com"/>
          <div onClick={() => navigate(-1)}><PrimeButton btnName='Cancle' /></div>
        </div>
        <div className="grid grid-cols-2 gap-3 text-gray-600 ">
        <div className='col-span-2 flex justify-between text-xs rounded-3xl p-2 bg-gray-200'>
        <div className='flex items-center gap-2  p-2  '>
                <label  className="block font-bold text-gray-900">Ref No.</label>
                <p className=''>00000</p>
              </div>
              <div className='flex items-center gap-2  p-2 '>
                <label  className="block  font-bold text-gray-900">Recipt No.</label>
                <p className=''>00000</p>
              </div>
              <div className='flex items-center gap-2  p-2 '>
                <label  className="block  font-bold text-gray-900">Date</label>
                <p className=''>22/12/23</p>
              </div>
        </div>
          <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200">
            <div className="text-left p-3">
              <label className="block font-bold text-gray-900">To</label>
              <p>Company Name</p>
              <p>Company address</p>
              <p>City, Country - 00000</p>
              <p>+0 (000) 123-4567</p>
            </div>
          </div>
          <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200 ">
            <div className="text-left p-3">
              <label className="block font-bold text-gray-900">From</label>
              <p>Bharat Parcel Services</p>
              <p>Business address</p>
              <p>City, Country - 00000</p>
              <p>+0 (000) 123-4567</p>
            </div>
          </div>
          <div className="relative overflow-auto col-span-2 justify-between text-xs rounded-3xl p-2 bg-gray-200">
            <table className="table-auto w-full">
              <thead>
                <tr className="border-b-2  border-white">
                  {tableHead.map((head, index) => (
                    <th key={index} className="p-2 text-left">
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={index} className="border-b-2 text-left border-white">
                    <td className="px-4 py-2 ">
                      <p className="font-bold">{data.delivery}</p> {data.spefy}
                    </td>
                    <td className="px-4 py-2">{data.qty}</td>
                    <td className="px-4 py-2">Rs.{data.rate}</td>
                    <td className="px-4 py-2">Rs.{data.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end text-xs p-2">
              <table className="">
                <tbody>
                  {gstTableHead.map((head, index) => (
                    <tr
                      key={index}
                      className="text-left border-b-2 border-white"
                    >
                      <th className="px-4 py-3">{head}</th>
                      <td className="px-4 py-3">{gstTableData[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 text-xs rounded-3xl p-2 bg-gray-200">
            <div className=" text-left p-3">
              <label className="block font-bold text-gray-900">
                Thank you for the business!
              </label>
              <p>Please pay within 15 days of receiving this invoice.</p>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 text-xs rounded-3xl p-2 bg-gray-200">
            <div className="text-left p-3">
              <table className="">
                <tbody>
                  {bankDltHead.map((head, index) => (
                    <tr key={index} className="text-left">
                      <th className="text-gray-900 px-2">{head}</th>
                      <td>{bankDltData[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-span-2 flex justify-end gap-3 text-xs rounded-3xl p-4 bg-gray-200">
            <p className="flex-auto text-start font-bold">
              Bharat Parcel Services, IN
            </p>
            <p className="font-bold">+91 7017866149</p>
            <p className="font-bold">bps@email.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceBill;
