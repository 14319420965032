import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
// import { Statusbar } from "../../../component/StatusBar";
// import customer from "../../../img/customer.svg";
// import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import PageName from "../../../component/PageName";
import DriverCard from "../DriverCard";
import Pagination from "../../../component/Pagination";
import { useSelector } from "react-redux";

const TotalDriver = () => {
  const tableHead = [
    "S.No.",
    "Driver ID",
    "Name",
    "Location",
    "Contact",
    // "Status",
    "Action",
  ];

  // const [dataLoading, setDataLoading] = useState(false);
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);

  const [allDriverRaw, setallDriverRaw] = useState([]);
  const [allDriver, setAllDriver] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const driverManageData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: `driver/all?&page=${pageNumber}&perPage=${25}`,
    });
    if (response && response.data && response.data.data) {
      setallDriverRaw(response.data.data);
      settotalCount(response.data?.totalCount);
    } else {
      console.error("Logout failed:");
    }
  };

  useEffect(() => {
    if (isLogged) {
      driverManageData();
    }
  }, [isLogged, deleteDeatils, pageNumber]);

  useEffect(() => {
    const modifiedData = allDriverRaw.map((driver) => {
      return {
        item01: driver.username,
        item02: driver.firstName,
        item03: driver.middleName
          ? `${driver.firstName} ${driver.middleName} ${driver.lastName}`
          : `${driver.firstName} ${driver.lastName}`,
        item04: driver.mobile,
        // item05:"Available",
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: driver.username,
      };
    });
    setAllDriver(modifiedData);
  }, [allDriverRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Driver" />
        <DriverCard isActive="total" />

        <div>
          <div className="flex justify-center sm:justify-end items-center px-4"></div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allDriver}
            DeleteUrl={"driver/delete"}
            eyeButton={RoutePath.EditDriverDetailLink}
            editButton={RoutePath.EditDriverDetailLink}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalDriver;
