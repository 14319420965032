import React from 'react';
// import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const ShowHideBtn = ({isShow}) => {
  return (
    <div>
      <div>
      {isShow ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>} 
      </div>
    </div>
  )
}

export default ShowHideBtn;
