import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
// import Dropdown from "../../../component/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
// import AutoInputField from "../../../component/AutoInputField";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";
// import { useSelector } from "react-redux";
import Dropdown from "../../../component/Dropdown";
import CustomerDropDown from "../../../component/dropdown/CustomerDropDown";
// import AutoNameSuggetion from "../../../component/AutoNameSuggetion";

let initialValues = {
  startStation: "",
  endStation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  locality: "",
  bookingDate: "",
  deliveryDate: "",
  senderName: "",
  senderGgt: "",
  senderLocality: "",
  fromState: "",
  fromCity: "",
  senderPincode: "",
  receiverName: "",
  receiverGgt: "",
  receiverLocality: "",
  toState: "",
  toCity: "",
  toPincode: " ",
  receiptNo: "",
  refNo: "",
  insurance: "",
  vppAmount: "",
  toPay: "",
  weight: "",
  amount: "",
  addComment: "",
  freight: "",
  ins_vpp: "",
  cgst: "",
  sgst: "",
  igst: "",
  billTotal: "",
  grandTotal: "",
};

const BookOrderForm = () => {
  const navigate = useNavigate();
  // const { stationDetails } = useSelector((state) => state.user);
  const [startStationId, setStartStationId] = useState();
  const [endStationId, setEndStationId] = useState();
  const [newcustomerId, setNewcustomerId] = useState();

  useEffect(() => {
    if (startStationId) {
      setFieldValue("startStation", startStationId);
    }
    if (endStationId) {
      setFieldValue("endStation", endStationId);
    }
  }, [startStationId, endStationId]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userCreateSchema = Yup.object({
    startStation: Yup.string().required("Start station should not be empty"),
    endStation: Yup.string().required(
      "Destination station should not be empty"
    ),
    firstName: Yup.string().required("First name should not be empty"),
    lastName: Yup.string().required("Last name should not be empty"),
    mobile: Yup.number().required("Mobile number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    bookingDate:  Yup.date()
    .required("Booking date should not be empty")
    .min(today, "Booking date should be today or a future date"),
    deliveryDate: Yup.date()
    .required("Delivery date should not be empty")
    .min(today, "Delivery date should be today or a future date"),
    senderName: Yup.string().required("Sender name should not be empty"),
    senderGgt: Yup.string().required("GST number should not be empty"),
    senderLocality: Yup.string().required("Locality should not be empty"),
    fromState: Yup.string().required("State should not be empty"),
    fromCity: Yup.string().required("City should not be empty"),
    senderPincode: Yup.number().required("Pin code should not be empty"),
    receiverName: Yup.string().required("Receiver name should not be empty"),
    receiverGgt: Yup.string().required("GST number should not be empty"),
    receiverLocality: Yup.string().required("Locality should not be empty"),
    toState: Yup.string().required("State should not be empty"),
    toCity: Yup.string().required("City should not be empty"),
    toPincode: Yup.number().required("Pin code should not be empty"),
    freight: Yup.number().required("Freight should not be empty"),
    ins_vpp: Yup.number().required("INS/VPP should not be empty"),
    billTotal: Yup.number().required("Bill total should not be empty"),
    grandTotal: Yup.number().required("Grand total should not be empty"),
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: userCreateSchema,
      onSubmit: async (values, action) => {
        let customerIdApi = newcustomerId;
        if (
          customerIdApi === "none" ||
          customerIdApi === "" ||
          customerIdApi == null ||
          !newcustomerId
        ) {
          customerIdApi = null;
        }

        const data = {
          startStation: startStationId,
          endStation: endStationId,
          customerId: customerIdApi,
          customerName: values.customerName,
          mobile: values.mobile,
          email: values.email,
          locality: values.locality,
          bookingDate: values.bookingDate,
          proposedDeliveryDate: values.deliveryDate,
          fromCustomerName: values.senderName,
          fromGstNumber: values.senderGgt,
          fromAddress: values.senderLocality,
          fromCity: values.fromCity,
          fromState: values.fromState,
          fromPincode: values.senderPincode,
          toCustomerName: values.receiverName,
          toGstNumber: values.receiverGgt,
          toAddress: values.receiverLocality,
          toState: values.toState,
          toCity: values.toCity,
          toPincode: values.toPincode,
          additionalCmt: values.addComment,
          freight: values.freight,
          ins_vpp: values.ins_vpp,
          billTotal: values.billTotal,
          cgst: values.cgst,
          sgst: values.sgst,
          igst: values.igst,
          grandTotal: values.grandTotal,
          productDetails: products,
        };

        // console.log("customeproducts :", products[0].amount);
        // return false;
        try {
          const response = await getAxiosWithToken({
            method: "POST",
            url: "booking/add", // Adjust the API endpoint as needed
            data: data,
          });
          // API response 200 201
          if (response.data.success) {
            alertResponseMsgSuccess(response.data.msg);
            // action.resetForm();
            navigate("/booking");
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(error.response.data.msg);
        }
      },
    });

  //-------------------------Customer Details----------------------------------------------
  let [CustomerDetailsApiRaw, setCustomerDetailsApiRaw] = useState({});

  const [customerData, setcustomerData] = useState();

  // console.log("customerData",customerData)
  const getCustomerDeatil = async (newcustomerId) => {
    if (newcustomerId) {
      try {
        const response = await getAxiosWithToken({
          method: "get",
          url: `customer/details/${newcustomerId}`,
        });
        if (response && response.data && response.data.data) {
          setcustomerData(response.data.data);
          values.firstName = `${
            response.data.data.firstName ? response.data.data.firstName : ""
          }`;
          values.middleName = `${
            response.data.data.middleName ? response.data.data.middleName : ""
          }`;
          values.lastName = `${
            response.data.data.lastName ? response.data.data.lastName : ""
          }`;
          values.mobile = `${
            response.data.data.mobile ? response.data.data.mobile : ""
          }`;
          values.email = `${
            response.data.data.email ? response.data.data.email : ""
          }`;

          return {
            firstName: response.data.data?.firstName,
            middleName: response.data.data?.middleName,
            lastName: response.data.data?.lastName,
            mobile: response.data.data?.mobile,
            email: response.data.data?.email,
          };
        } else {
          setCustomerDetailsApiRaw({
            firstName: "",
            middleName: "",
            lastName: "",
          });
        }
        if (response) {
          console.log(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getCustomerDeatil(newcustomerId);
  }, [newcustomerId]);

  useEffect(() => {
    if (CustomerDetailsApiRaw.firstName) {
      values.firstName = `${
        CustomerDetailsApiRaw.firstName ? CustomerDetailsApiRaw.firstName : ""
      }`;
      values.middleName = `${
        CustomerDetailsApiRaw.middleName ? CustomerDetailsApiRaw.middleName : ""
      }`;
      values.lastName = `${
        CustomerDetailsApiRaw.lastName ? CustomerDetailsApiRaw.lastName : ""
      }`;
      values.mobile = `${
        CustomerDetailsApiRaw.mobile ? CustomerDetailsApiRaw.mobile : ""
      }`;
      values.email = `${
        CustomerDetailsApiRaw.email ? CustomerDetailsApiRaw.email : ""
      }`;
    } else {
      // console.log(CustomerDetailsApiRaw);
      values.customerName = " ";
      values.mobile = " ";
      values.email = " ";
    }
  }, [CustomerDetailsApiRaw]);

  // -------------------------------------------Product detail-----------------------------------------------------//

  const [productCount, setProductCount] = useState(1);
  const [products, setProducts] = useState([{ id: 1 }]);

  const addRowInProduct = () => {
    setProductCount(productCount + 1);
    setProducts([...products, { id: productCount + 1 }]);
  };

  const removeRowInProduct = (productId) => {
    if (productCount > 1) {
      // Filter out the product with the specified id
      // console.log(productId);
      const updatedProducts = products.filter(
        (product) => product.id !== productId
      );
      const updatedProductsWithNewIds = updatedProducts.map(
        (product, index) => ({
          ...product,
          id: index + 1,
        })
      );
      // console.log(updatedProducts);
      // console.log(updatedProductsWithNewIds);
      setProductCount(productCount - 1);
      setProducts(updatedProductsWithNewIds);
    }
  };
  const [allItemAmount, setAllItemAmount] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [cgstTotal, setCgstTotal] = useState(0);
  const [sgstTotal, setSgstTotal] = useState(0);
  const [igstTotal, setIgstTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleProductChange = async (e, productId) => {
    const { name, value } = e.target;
    if (name === "amount" && isNaN(value)) {
      return false;
    }

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [name]: value } : product
      )
    );
  };

  useEffect(() => {
    handleGstCalculate();
  }, [products]);

  const handleGstCalculate = async () => {
    let amount = 0;

    await products.map((product) => {
      amount =
        parseInt(amount) + (product.amount ? parseInt(product.amount) : 0);
    });
    setAllItemAmount(amount);
  };
  const freightAmount = values.freight;
  const ins_vppAmount = values.ins_vpp;

  useEffect(() => {
    if (
      freightAmount !== null &&
      ins_vppAmount !== null &&
      allItemAmount !== null
    ) {
      const totalAmount =
        Number(freightAmount) + Number(ins_vppAmount) + allItemAmount;
      setTotalBill(totalAmount);
    }
  }, [freightAmount, ins_vppAmount, allItemAmount]);
  values.billTotal = `${totalBill || null}`;
  //------------------------------------------gst calculate-----------------------------------------//
  const cgstValueInPercentage = values.cgst;
  const sgstValueInPercentage = values.sgst;
  const igstValueInPercentage = values.igst;

  useEffect(() => {
    const gstFunction = (gstPercentage) => {
      return (totalBill / 100) * gstPercentage;
    };

    if (
      cgstValueInPercentage <= 100 &&
      sgstValueInPercentage <= 100 &&
      igstValueInPercentage <= 100
    ) {
      const cgst = gstFunction(cgstValueInPercentage);
      const roundedCgst = cgst.toFixed(2);
      setCgstTotal(roundedCgst);

      const sgst = gstFunction(sgstValueInPercentage);
      const roundedSgst = sgst.toFixed(2);
      setSgstTotal(roundedSgst);

      const igst = gstFunction(igstValueInPercentage);
      const roundedIgst = igst.toFixed(2);
      setIgstTotal(roundedIgst);
    } else {
      // Handle error or invalid input
      console.error("Invalid GST percentage provided.");
    }
  }, [
    cgstValueInPercentage,
    sgstValueInPercentage,
    igstValueInPercentage,
    totalBill,
  ]);

  useEffect(() => {
    if (
      cgstTotal !== null &&
      sgstTotal &&
      igstTotal !== null &&
      totalBill !== null
    ) {
      const GrandTotalAmount =
        Number(cgstTotal) +
        Number(sgstTotal) +
        Number(igstTotal) +
        Number(totalBill);
      setGrandTotal(GrandTotalAmount);
    }
  }, [cgstTotal, sgstTotal, igstTotal, totalBill]);

  values.grandTotal = `${grandTotal || null}`;

  return (
    <div>
      <div className="p-8">
        <BackButton btnName="Book Order" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            {/* <div className='div col-span-6 text-lg font-semibold '>Vehicle Details</div> */}
            <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Start Station
              </label>

              <Dropdown
                label={"Station"}
                name={"startStation"}
                SetStationId={setStartStationId}
              />
              <InputError
                error={errors.startStation}
                touched={touched.startStation}
              />
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Destination Station
              </label>

              <div className="w-full">
                <Dropdown
                  label={"Station"}
                  name={"startStation"}
                  SetStationId={setEndStationId}
                />
                <InputError
                  error={errors.endStation}
                  touched={touched.endStation}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Date of Booking
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="bookingDate"
                  value={"" || values.bookingDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Booking"
                />
                <InputError
                  error={errors.bookingDate}
                  touched={touched.bookingDate}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Proposed Date of Delivery
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="deliveryDate"
                  value={"" || values.deliveryDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Proposed Date of Delivery"
                />
                <InputError
                  error={errors.deliveryDate}
                  touched={touched.deliveryDate}
                />
              </div>
            </div>
           
            <div className="col-span-6 py-2 items-center">
              <CustomerDropDown setNewcustomerId={setNewcustomerId} />
              
              
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="firstName"
                  value={"" || values.firstName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="First Name"
                />
                <InputError
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="middleName"
                  value={"" || values.middleName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Middle Name"
                />
                <InputError
                  error={errors.middleName}
                  touched={touched.middleName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="lastName"
                  value={"" || values.lastName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Last Name"
                />
                <InputError
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={values.mobile || " "}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Email Address
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold mb-5 mt-5 ">
              From <p className="text-sm">(Address)</p>{" "}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderName"
                  value={"" || values.senderName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="User Name"
                />
                <InputError
                  error={errors.senderName}
                  touched={touched.senderName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                GST Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderGgt"
                  value={"" || values.senderGgt}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="GST Number"
                />
                <InputError
                  error={errors.senderGgt}
                  touched={touched.senderGgt}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderLocality"
                  value={"" || values.senderLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.senderLocality}
                  touched={touched.senderLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"fromState"}
                  value={values.fromState}
                />
                <InputError
                  error={errors.fromState}
                  touched={touched.fromState}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"fromCity"}
                  value={values.fromCity}
                  stateId={values.fromState}
                />
                <InputError
                  error={errors.fromCity}
                  touched={touched.fromCity}
                />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderPincode"
                  value={"" || values.senderPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.senderPincode}
                  touched={touched.senderPincode}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold mb-5 mt-5 ">
              To <p className="text-sm">(Address)</p>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverName"
                  value={"" || values.receiverName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Receiver Name"
                />
                <InputError
                  error={errors.receiverName}
                  touched={touched.receiverName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                GST Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverGgt"
                  value={"" || values.receiverGgt}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="GST Number"
                />
                <InputError
                  error={errors.receiverGgt}
                  touched={touched.receiverGgt}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverLocality"
                  value={"" || values.receiverLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.receiverLocality}
                  touched={touched.receiverLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className="  block mb-2 text-sm font-medium text-gray-900">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"toState"}
                  value={values.toState}
                />
                <InputError error={errors.toState} touched={touched.toState} />
              </div>
            </div>
            <div className="col-span-3 gap-6  py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"toCity"}
                  value={values.toCity}
                  stateId={values.toState}
                />
                <InputError error={errors.toCity} touched={touched.toCity} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="toPincode"
                  value={"" || values.toPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.toPincode}
                  touched={touched.toPincode}
                />
              </div>
            </div>
          </div>
          <div className="text-lg font-semibold text-left mb-5 mt-10">
            <p className="text-xl font-bold">Product Details</p>
          </div>
          <div className="w-100">
            {products.map((product) => (
              <div
                key={product.id}
                id="createDivBox"
                className="grid md:grid-flow-col items-end gap-x-4 mb-2 w-100"
              >
                <div className="col-span-1 py-2  items-center">
                  <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                    S. No.
                  </label>
                  <div className="md:w-12">
                    <input
                      value={product.id + "."}
                      disabled
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    />
                  </div>
                </div>
                <div className=" col-span-1 py-2  items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Receipt No.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="receiptNo"
                      value={product.receiptNo}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Receipt No."
                    />
                    <InputError
                      error={errors.receiptNo}
                      touched={touched.receiptNo}
                    />
                  </div>
                </div>
                <div className=" col-span-1 py-2  items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Ref. No.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="refNo"
                      value={product.refNo}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Ref. No."
                    />
                    <InputError error={errors.refNo} touched={touched.refNo} />
                  </div>
                </div>
                <div className="col-span-1  py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Insurance
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="insurance"
                      value={product.insurance}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Insurance"
                    />
                    <InputError
                      error={errors.insurance}
                      touched={touched.insurance}
                    />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    VPP Amount
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="vppAmount"
                      value={product.vppAmount}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="VPP Amount"
                    />
                    <InputError
                      error={errors.vppAmount}
                      touched={touched.vppAmount}
                    />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    To Pay/Paid
                  </label>
                  <div>
                    <select
                      name="toPay"
                      value={product.toPay}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    >
                      <option value="">None</option>
                      <option value="Pay">Pay</option>
                      <option value="Paid">Paid</option>
                    </select>
                    <InputError error={errors.toPay} touched={touched.toPay} />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Weight Kgs.
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="weight"
                      value={product.weight}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Weight Kgs."
                    />
                    <InputError
                      error={errors.weight}
                      touched={touched.weight}
                    />
                  </div>
                </div>
                <div className="col-span-1 py-2 items-center">
                  <label className=" block mb-2 text-sm font-medium text-gray-900 text-left">
                    Amount
                  </label>
                  <div className="w-full ">
                    <input
                      type="text"
                      name="amount"
                      value={product.amount}
                      onChange={(e) => handleProductChange(e, product.id)}
                      className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      placeholder="Amount"
                    />
                    <InputError
                      error={errors.amount}
                      touched={touched.amount}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex gap-3 justify-center m-2  ">
                  <div
                    className={product?.id}
                    onClick={() => removeRowInProduct(product?.id)}
                  >
                    <PrimeButton btnName="- Remove" btnType="button" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div onClick={addRowInProduct} className="w-full mb-4">
            <PrimeButton
              btnName="+ Add Item"
              btnType="button"
              className=" sm:w-full"
            />
          </div>
          <div className="grid md:grid-cols-6 justify-between p-0">
            <div className=" col-span-4 w-full py-2">
              <textarea
                name="addComment"
                id=""
                cols="40"
                rows="10"
                className="rounded-inputBRedius bg-inputBgColor w-full h-full p-2"
                placeholder="Additional Comments"
                value={"" || values.addComment}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="col-span-2 items-center">
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  FREIGHT
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="freight"
                    value={"" || values.freight}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="FREIGHT"
                  />
                  <InputError
                    error={errors.freight}
                    touched={touched.freight}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36  text-sm font-medium text-gray-900">
                  INS/VPP
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="ins_vpp"
                    value={"" || values.ins_vpp}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="INS/VPP"
                  />
                  <InputError
                    error={errors.ins_vpp}
                    touched={touched.ins_vpp}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Bill Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="billTotal"
                    value={"" || values.billTotal}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder=" Bill Total"
                  />
                  <InputError
                    error={errors.billTotal}
                    touched={touched.billTotal}
                  />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  CGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="cgst"
                    value={"" || values.cgst}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="CGST"
                  />
                  <InputError error={errors.cgst} touched={touched.cgst} />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  SGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="sgst"
                    value={"" || values.sgst}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="SGST"
                  />
                  <InputError error={errors.sgst} touched={touched.sgst} />
                </div>
              </div>
              <div className="lg:flex gap-6  p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  IGST%
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="igst"
                    value={"" || values.igst}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="IGST"
                  />
                  <InputError error={errors.igst} touched={touched.igst} />
                </div>
              </div>

              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Grand Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="grandTotal"
                    value={"" || values.grandTotal}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Grand Total"
                  />
                  <InputError
                    error={errors.grandTotal}
                    touched={touched.grandTotal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
};
export default BookOrderForm;
