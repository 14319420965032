import React, { useEffect, useState, useMemo } from "react";
import { RoutePath } from "../router/RoutePath";
import { Link } from "react-router-dom";
import LoadingTable from "./LoadingTable";
import DeleteDialogBox from "../component/DeleteDialogBox";
// import Pagination from "./Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import profile from "../img/profile.jpg";
import ShareDetail from "../page/managebooking/bookingRequest/ShareDetail";

export const DynamicTable = ({
  tableHeadRow,
  data,
  eyeButton,
  editButton,
  InvoiceButton,
  DeleteUrl,
  isDataLoading,
  cancelUrl,
}) => {
  const { apiUrl } = useSelector((state) => state.user);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "") => {
    if (key !== "") {
      setOpenDelete(!openDelete);
      setDeleteModelData({
        deleteUrl: `${DeleteUrl}/${key}`,
        headerText: `This is Delete Confirmation`,
        paraText: `This ID ${key} will be deleted.`,
      });
    }
  };

  // Cancellation logic
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelModelData, setCancelModelData] = useState({});
  const handleCancelModel = (key = "") => {
    if (key !== "") {
      setOpenCancel(!openCancel);
      setCancelModelData({
        deleteUrl: `${cancelUrl}/${key}`,
        headerText: `This is Cancel Confirmation`,
        paraText: `This ID ${key} will be canceled.`,
      });
    }
  };
  //----------------------------------------------invoice Image------------------------------------------------------//

  const handleImageClick = (uploadFile) => {
    const imageUrl = uploadFile ? `${apiUrl}/${uploadFile}` : profile;

    //  window.open method if you want to open the image in a new tab
    window.open(imageUrl, "_blank");
  };

  //-------------------------------------- Pagination -------------------------------------------------------------------//

  // const numOfTableRow = 20;
  // const [page, setPage] = useState(1);
  // const dataReverse = data;
  // const dataLength = data.length;
  // const allpages = (dataLength / numOfTableRow).toFixed();
  // const range = page * numOfTableRow - numOfTableRow;
  // const onNextClick = () => {
  //   if (range < allpages) {
  //     setPage(page + 1);
  //   }
  // };
  // const onPreviousClick = () => {
  //   if (range > 0) {
  //     setPage(page - 1);
  //   }
  // };

  //-------------------------------------filer search-----------------------------------------------//
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Function to handle input change
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Memoize filtered items
  const filteredItems = useMemo(() => {
    return data?.filter(
      (item) =>
        item.item01?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.item02?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.item03?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, data]);

  // Use effect to set filtered data
  useEffect(() => {
    // Only update state if filteredItems have actually changed
    if (JSON.stringify(filteredData) !== JSON.stringify(filteredItems)) {
      setFilteredData(filteredItems);
    }
  }, [filteredItems, filteredData]);

  // console.log("filter", filteredData);

  return (
    <div>
      <div className="relative overflow-x-auto px-3 w-full">
        <div className="flex items-center gap-4 justify-end  pb-2 px-1">
          {/* <div className="flex items-center text-mainTxtColor rounded-inputBRedius border bg-inputBgColor px-2">
            <input
              type="search"
              className=" rounded-inputBRedius bg-inputBgColor py-2 outline-none"
              value={searchValue}
              name="username"
              onChange={handleInputChange}
            />
            <div>
              {" "}
              <SearchIcon />
            </div>
          </div> */}
        </div>
        <table className="w-full">
          <thead className="bg-primeryBgColor border-4 border-backgroundColor text-white w-full">
            <tr className="font-bold  w-full ">
              {tableHeadRow.map((head, index) => (
                <th key={index} className="p-2">
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white  text-mainTxtColor p-3">
            {isDataLoading ? (
              <tr>
                <td colSpan={tableHeadRow.length} className="text-center">
                  Data Not Available
                </td>
              </tr>
            ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData?.map((column, index) => (
                <tr key={index} className="border-4 border-backgroundColor ">
                  <td className="px-4 py-1   ">{index + 1}</td>
                  {column.item01 ? (
                    <td className="px-4 py-1">{column.item01}</td>
                  ) : null}
                  {column.item02 ? (
                    <td className="px-4 py-1 ">{column.item02}</td>
                  ) : null}
                  {column.item03 ? (
                    <td className="px-4 py-1">{column.item03}</td>
                  ) : null}
                  {column.item04 ? (
                    <td className="px-4 py-1">{column.item04}</td>
                  ) : null}
                  {column.itemto ? (
                    <td className="px-4 py-1">{column.itemto}</td>
                  ) : null}
                  {column.item05 ? (
                    <td className="px-4 py-1">{column.item05}</td>
                  ) : null}
                  {column.item06 ? (
                    <td className="px-4 py-1">{column.item06}</td>
                  ) : null}
                  {column.item07 ? (
                    <td className="px-4 py-1">{column.item07}</td>
                  ) : null}
                  {column.item08 ? (
                    <td className="px-4 py-1">{column.item08}</td>
                  ) : null}
                  {column.imgBtn ? (
                    <button onClick={() => handleImageClick(column.uploadFile)}>
                      {column.imgBtn}
                    </button>
                  ) : null}

                  {column.img01 || column.img03 ? (
                    <td>
                      <div className="flex justify-center items-center gap-2  text-slate-600">
                        {column.img01 ? (
                          <Link to={eyeButton + "/" + column.unqiueKey}>
                            <div className="hover:text-slate-400" title="View">
                              {column.img01}
                            </div>
                          </Link>
                        ) : null}
                        {column.img02 ? (
                          <Link to={editButton + "/" + column.unqiueKey}>
                            <div className="hover:text-green-600" title="Edit">
                              {column.img02}
                            </div>
                          </Link>
                        ) : null}
                        {column.img03 ? (
                          <div
                            title="Delete"
                            className="hover:text-red-600 cursor-pointer"
                            onClick={() => {
                              handleDeleteModel(
                                column.unqiueKey,
                                column.item01
                              );
                            }}
                          >
                            {column.img03}
                          </div>
                        ) : null}

                        {column.itemLocation ? (
                          <Link to={RoutePath.Mapper} title="Tracker">
                            {column.itemLocation}
                          </Link>
                        ) : null}
                        {column.cancel ? (
                          <button
                            className="hover:text-slate-900 cursor-pointer"
                            title="Cancel"
                            onClick={() => {
                              handleCancelModel(column.unqiueKey);
                            }}
                          >
                            {column.cancel}
                          </button>
                        ) : null}
                        {column.share ? (
                          <button className="hover:text-blue-900" title="Print">
                            <ShareDetail
                              unqiueKey={column.unqiueKey}
                              pageName={column.pageName}
                            />
                            {/* {column.share} */}
                          </button>
                        ) : null}
                      </div>
                    </td>
                  ) : null}
                  {column.checkBox ? (
                    <div className="hover:text-blue-600">
                      <input type="checkbox" />
                      {/* {column.checkBox } */}
                    </div>
                  ) : null}

                  {/* {column.item10 ? (
                    <td className="px-4 py-1 ">
                      <Link to="/gstinvoicebill">{column.item10}</Link>
                    </td>
                  ) : null} */}
                  {column.item10 ? (
                    <Link to={InvoiceButton + "/" + column.unqiueKey}>
                      <div className="hover:text-green-600">
                        {column.item10}
                      </div>
                    </Link>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableHeadRow.length} className="text-center">
                  <LoadingTable />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <Pagination
        nextclick={onNextClick}
        previousclick={onPreviousClick}
        dataLength={dataLength}
        page={allpages}
      /> */}
      <DeleteDialogBox
        open={openDelete}
        setOpen={setOpenDelete}
        data={deleteModelData}
      />

      {/* Dialog for cancel (with "put" method) */}
      <DeleteDialogBox
        method="put"
        open={openCancel}
        setOpen={setOpenCancel}
        data={cancelModelData}
      />
    </div>
  );
};

// export default AvailableDriversTable;
