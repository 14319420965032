import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { Link, useNavigate } from "react-router-dom";
import { userLogOut } from "../../store/features/userSlice";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { RoutePath } from "../../router/RoutePath";

const CustomerDropDown = ({ setNewcustomerId, itemhide }) => {
  // itemhide - use for hide some some item in dropdown like lable, register button, which is not rquired in some page
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.user);
  const [showDropdown, setShowDropdown] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const dropdownRef = useRef(null);

  const [allCustomerRaw, setAllCustomerRaw] = useState([]);
  const [selectOption, setSelectOption] = useState("");
  // console.log("allCustomerRaw", allCustomerRaw);
  // console.log("selectOption", selectOption);
  const trimmedLength = selectOption.trim(); // if input contain any space

  const customerFetchDetails = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `customer/all?page=${1}&perPage=${10}&search=${trimmedLength}`,
      });
      if (response.data.success) {
        // setBookingCount(response.data.dataCount);
        setAllCustomerRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    // Check if user is logged in
    if (isLogged) {
      const timeout = setTimeout(() => {
        customerFetchDetails();
      }, 500); // Delay in milliseconds (e.g., 1000ms = 1 second)
      return () => clearTimeout(timeout);
    }
  }, [isLogged, selectOption]);

  const searchById = (customerId) => {
    setcustomerId(
      customerId.firstName +
        " " +
        customerId.middleName +
        " " +
        customerId.lastName +
        " (" +
        customerId.mobile +
        ")"
    );
    setNewcustomerId(customerId.username);
  };

  const searchHandle = (event) => {
    setSelectOption(event.target.value);
    setcustomerId(event.target.value);
  };
  //-------------------------------------------dropdown---------------------------------------//
  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div ref={dropdownRef} className="w-full">
        {itemhide ? (
          ""
        ) : (
          <label className="col-span-6 block my-2 text-sm font-medium text-gray-900">
            Customer Name/Number
          </label>
        )}

        <div className=" flex items-center w-full rounded-inputBRedius bg-inputBgColor ">
          <input
            onClick={handleDropdown}
            id="textbox_id"
            type="search"
            onChange={searchHandle}
            placeholder="search for customer"
            value={"" || customerId}
            className="w-full rounded-inputBRedius bg-inputBgColor p-2"
          />
          <div
            onClick={handleDropdown}
            className="pr-2 flex justify-center cursor-pointer"
          >
            <SearchIcon />
          </div>
          {itemhide ? (
            ""
          ) : (
            <Link to={RoutePath.AddCustomerForm}>
              <div className="rounded-r-inputBRedius py-2 px-4 flex justify-center cursor-pointer bg-primeryBgColor text-white">
                <ControlPointIcon /> <h3> Register</h3>
              </div>
            </Link>
          )}
        </div>
        <div
          className={`relative w-full z-20 ${
            showDropdown ? "block" : "hidden"
          }`}
        >
          {allCustomerRaw && (
            <div className="absolute w-full bg-white border border-gray-300 rounded mt-1">
              <ul onClick={handleDropdown} className="max-h-60 overflow-y-auto">
                {allCustomerRaw.map((option) => (
                  <li
                    key={option.username}
                    onClick={() => searchById(option || "")}
                    className="hover:bg-primeryBgColor hover:text-white p-2 cursor-pointer"
                  >
                    {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName +
                      " (" +
                      option.mobile +
                      ")"}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDropDown;
