import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
// import TrackVehical from "../../img/TrackVehical.svg";
// import fuel from "../../../img/fuel.svg";
// import maintenance from "../../../img/maintenance.svg";
// import TowTruck from "../../img/TowTruck.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";
import customer from "../../img/customer.svg";

const CustomerCard = ({ isActive,allDataRaw }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  // const [active, setSctive] = useState([]);
  // const [cancelled, setcancelled] = useState([]);
  const [total, settotal] = useState();
  const [availabe, setAvailabe] = useState([]);

  // console.log("total", total);
  //   console.log("request", request);

  // console.log("allBookingRaw",allBookingRaw)

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=customer",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data.customer);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      // setSctive(allBookingRaw.active);
      // setcancelled(allBookingRaw.cancelled);
      settotal(allBookingRaw?.active?.count);
      setAvailabe(allBookingRaw?.deactivate);
    }
  }, [allBookingRaw]);
  return (
    <>
      <div className="grid md:grid-cols-2">
        <div className="p-4">
          <Link to={RoutePath.ManageCustomer}>
          <Statusbar
            isActive={isActive ==="totoal"}
            src={customer}
            count={total || 0}
            title="Active Customer"
            // rating={}
            // rating={`${Math.floor(allDataRaw?.total?.percentage)}%`}
          />
          </Link>
        </div>
        <div className="p-4">
        <Link to={RoutePath.BlockCustomer}>
          <Statusbar
            isActive={isActive ==="block"}
            src={customer}
            count={availabe?.count || 0}
            title="Blacklisted Customer"
            // rating={}
            // rating={`${Math.floor(allDataRaw?.total?.percentage)}%`}
          />
           </Link>
        </div>
        </div>
    </>
  );
};

export default CustomerCard;
