import React from "react";
import TrackVehical from "../../../img/TrackVehical.svg";
import fuel from "../../../img/fuel.svg";
import maintenance from "../../../img/maintenance.svg";
import TowTruck from "../../../img/TowTruck.svg";
import { RoutePath } from "../../../router/RoutePath";
import { AddFilterButton, TitleFilter } from "../../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { Link } from "react-router-dom";
import PageName from "../../../component/PageName";

const Maintenance = () => {
  const tableHead = [
    "S.No.",
    "Date",
    "Vehicle",
    "Booking ID",
    "Station",
    "Description",
    "Amount(in Rs)",
    "Action",
  ];

  const [dataLoading, setDataLoading] = useState(false);
  const [DriverCount, setDriverCount] = useState(0);
  const [allRawData, setallRawData] = useState([]);

  const [allDriver, setAllDriver] = useState([]);

  const driverManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "vehicle/all",
    });
    if (response && response.data && response.data.data) {
      setallRawData(response.data.data);
      setDriverCount(response.data.count || 0);
    }
    // console.log(response);
  };

  useEffect(() => {
    driverManageData();
  }, [dataLoading]);

  useEffect(() => {
    const modifiedData = allRawData.map((vehicle) => {
      return {
        item01: vehicle.vehicleId,
        item02: vehicle.location,
        item03: vehicle.ownerName,
        item04: vehicle.none,
        item05: vehicle.depreciation,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: vehicle.vehicleId,
      };
    });
    setAllDriver(modifiedData);
  }, [allRawData]);

  return (
    <div>
      <div>
      <PageName pageName="Maintenance" />
        <div className="grid sm:grid-cols-2 lg:grid-cols-4  justify-items-stretch gap-6 p-4 cursor-pointer">
          <div>
            <Link to={RoutePath.Vehicle}>
              <Statusbar
                count={DriverCount || 0}
                src={TrackVehical}
                title="Available Vehicles"
                rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.FuelRefill}>
              <Statusbar
                src={fuel}
                count="120 L"
                title="Fuel Refill"
                rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.Maintenance}>
              <Statusbar
                 isActive={true}
                src={maintenance}
                count="Rs 12800"
                title="Maintenance"
                rating="25% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.TotalVehicle}>
              <Statusbar
                src={TowTruck}
                count="862"
                title="Total Vehicles"
                rating="25% (30 days)"
              />
            </Link>
          </div>
          <div></div>
        </div>
        <div>
          <div className="flex justify-center sm:justify-end items-center px-4">
            <TitleFilter />
          </div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allDriver}
            DeleteUrl={"vehicle/delete"}
            eyeButton={RoutePath.EditVehicleDetailLink}
            editButton={RoutePath.EditVehicleDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
