import React, { useEffect, useRef, useState } from "react";
import { PrimeButton, ShareButton } from "../component/PrimeButton";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAxiosWithToken } from "../axios/AxiosObj";
import { formateDate, formatDateToISO } from "../function/utilies";
const GstInvoiceBill = () => {
  const params = useParams();
  const tableHead = [
    "No. of",
    "Insurance",
    "VPP Amount",
    "To Pay/Paid",
    "Weight Kgs.",
  ];
  // const gstTableHead = [
  //   "FREIGHT",
  //   "INV/VPP",
  //   "CGST (0%)",
  //   "SGST (0%)",
  //   "IGST (0%)",
  //   "Bill Total",
  //   "Grand Total",
  // ];
  const gstTableData = [
    "60",
  ];
  const bankDltHead = ["Bank details", "IFS code", "Swift code", "Account #"];
  const bankDltData = [
    "ABCD BANK",
    "ABCD000XXXX",
    "ABCDUSBBXXX",
    "37474892300011",
  ];

  const printRef = useRef();

  const handlePrint = () => {
    var printContents = document.getElementById(printRef.current.id).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const navigate = useNavigate();

  //------------------------------get Detail-----------------------------------//
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceOrders, setInvoiceOrders] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);

  const getStationDetail = async (invoiceId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `invoice/details/${invoiceId}`,
      });
      if (response && response.data && response.data.data) {
        setInvoiceData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (params && params.invoiceId && params.invoiceId !== " ") {
      getStationDetail(params.invoiceId);
    } else {
      window.location.href = "/";
    }
  }, [params]);

  // console.log("invoiceData", invoiceData);
  useEffect(() => {
    if (invoiceData && invoiceData.invoiceDetails) {
      setInvoiceDetail(invoiceData.invoiceDetails);
    }
  }, [invoiceData]);
  useEffect(() => {
    if (invoiceData && invoiceData.orders) {
      setInvoiceOrders(invoiceData.orders);
    }
  }, [invoiceData]);
  //invoce date 
  const formattedDate = formatDateToISO(formateDate(invoiceDetail.created_at));
  //reduser to calculate all values 
  const freight = invoiceOrders.reduce(
    (acc, item) => acc + Number(item.freight),
    0
  );
  const INVVPP = invoiceOrders.reduce(
    (acc, item) => acc + Number(item.ins_vpp),
    0
  );
  const billTotal = invoiceOrders.reduce(
    (acc, item) => acc + Number(item.billTotal),
    0
  );
  const grandTotal = invoiceOrders.reduce(
    (acc, item) => acc + Number(item.grandTotal),
    0
  );
  // console.log("typeOf", typeof invoiceOrders);
  // console.log("totalFreights",totalFreights)
  // console.log("invoiceOrders",totalFreight)
  // console.log("invoiceDetail",invoiceDetail)

  const tableData = [
    {
      delivery: "Item02",
      spefy: "Specification",
      qty: 20,
      rate: 8000,
      total: 1000,
      weight: 100,
    },
  ];

  return (
    <div className="flex justify-center items-start overflow-y-auto backdrop-blur-md fixed top-0 inset-0 z-50 ">
      <div
        ref={printRef}
        id="contentToPrint"
        className="bg-backgroundColor p-4 sm:w-1/2"
      >
        <div className="col-span-2 mb-5 flex justify-between gap-6">
          <div onClick={handlePrint}>
            <PrimeButton btnName="Print" />
          </div>
          {/* <ShareButton shareUrl="https://www.example.com" /> */}
          <div onClick={() => navigate(-1)}>
            <PrimeButton btnName="Cancel" />
          </div>
        </div>
        <div className="col-span-2 mb-5"></div>
        <div className="grid grid-cols-2 gap-3 text-gray-600 ">
          <div className="col-span-2 flex justify-between text-xs rounded-3xl p-2 bg-gray-200">
            <div className="flex items-center gap-2  p-2  ">
              <label className="block font-bold text-gray-900">Recipt No.</label>
              <p className="">{invoiceDetail.invoiceId}</p>
            </div>
            {/* <div className="flex items-center gap-2  p-2 ">
              <label className="block  font-bold text-gray-900">
                Recipt No.
              </label>
              <p className="">00000</p>
            </div> */}
            <div className="flex items-center gap-2  p-2 ">
              <label className="block  font-bold text-gray-900">Date</label>
              <p className="">{formattedDate}</p>
            </div>
          </div>

          <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200 ">
            <div className="text-left p-3">
              <label className="block font-bold text-gray-900">From</label>
              <div className=" font-semibold">
                <p>BHARAT PARCEL SERVICE</p>
                <p>GST Number</p>
              </div>
              <p>Bharat Parcel Services</p>
              <p>Business address</p>
              <p>City, Country - 00000</p>
              <p>+0 (000) 123-4567</p>
            </div>
          </div>
          <div className="col-span-1 flex justify-between text-xs rounded-3xl p-2 bg-gray-200">
            <div className="text-left p-3">
              <label className="block font-bold text-gray-900">To</label>
              <div className=" font-semibold">
                <p>
                  {invoiceDetail.firstName +
                    " " +
                    invoiceDetail.middleName +
                    " " +
                    invoiceDetail.lastName}
                </p>
                <p>GST Number</p>
              </div>
              <p>Address</p>
              <p>City, Country - 00000</p>
              <p>{invoiceDetail.mobile}</p>
            </div>
          </div>
          <div className="relative overflow-auto col-span-2 justify-between text-xs rounded-3xl p-2 bg-gray-200">
            <table className="table-auto w-full">
              <thead>
                <tr className="border-b-2  border-white">
                  {tableHead.map((head, index) => (
                    <th key={index} className="p-2 text-left">
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={index} className="border-b-2 text-left border-white">
                    <td className="px-4 py-2 ">
                      <p className="font-bold">{data.delivery}</p> {data.spefy}
                    </td>
                    <td className="px-4 py-2">{data.qty}</td>
                    <td className="px-4 py-2">Rs.{data.rate}</td>
                    <td className="px-4 py-2">Rs.{data.total}</td>
                    <td className="px-4 py-2">Kgs. {data.weight}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end text-xs p-2">
              <table className="">
                {freight && billTotal ? (
                  <tbody>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">FREIGHT</th>
                      <td className="px-4 py-2">{freight}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">INV/VPP</th>
                      <td className="px-4 py-2">{INVVPP}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">CGST</th>
                      <td className="px-4 py-2">{gstTableData}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">SGST</th>
                      <td className="px-4 py-2">{gstTableData}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">IGST</th>
                      <td className="px-4 py-2">{gstTableData}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">Bill Total</th>
                      <td className="px-4 py-2">{billTotal}</td>
                    </tr>
                    <tr className="text-left border-b-2 border-white">
                      <th className="px-4 py-2">Grand Total</th>
                      <td className="px-4 py-2">{grandTotal}</td>
                    </tr>
                  </tbody>
                ) : null}
                <tbody>
                  <tr className="text-left border-b-2 border-white">
                    <th className="px-4 py-2">s.Tax</th>
                    <td className="px-4 py-2">{freight}</td>
                  </tr>
                  <tr className="text-left border-b-2 border-white">
                    <th className="px-4 py-2">Total</th>
                    <td className="px-4 py-2">{invoiceDetail.allOrderAmount}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="text-left border-b-2 border-white">
                    <th className="px-4 py-2">Paid Amount</th>
                    <td className="px-4 py-2">{invoiceDetail.invoiceTotalAmount}</td>
                  </tr>
                  <tr className="text-left border-b-2 border-white">
                    <th className="px-4 py-2">Remainig Amnount</th>
                    <td className="px-4 py-2">{invoiceDetail.invoiceRemainingAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 text-xs rounded-3xl p-2 bg-gray-200">
            <div className=" text-left p-3">
              <label className="block font-bold text-gray-900">
                Thank you for the business!
              </label>
              <p>Please pay within 15 days of receiving this invoice.</p>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 text-xs rounded-3xl p-2 bg-gray-200">
            <div className="text-left p-3">
              <table className="">
                <tbody>
                  {bankDltHead.map((head, index) => (
                    <tr key={index} className="text-left">
                      <th className="text-gray-900 px-2">{head}</th>
                      <td>{bankDltData[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-span-2 flex justify-end gap-3 text-xs rounded-3xl p-4 bg-gray-200">
            <p className="flex-auto text-start font-bold">
              Bharat Parcel Services, IN
            </p>
            <p className="font-bold">+91 7017866149</p>
            <p className="font-bold">bps@email.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GstInvoiceBill;
