import React from "react";
import { RoutePath } from "../../../router/RoutePath";
// import { AddFilterButton, TitleFilter } from "../../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../../../component/PageName";
import { useSelector } from "react-redux";
import Pagination from "../../../component/Pagination";
import AdminCard from "../AdminCard";
import SearchBar from "../../../component/props/SearchBar";

const SuperAdmin = () => {
  const tableHead = ["S. No", "Super Admin ID", "Name", "Contact", "Action"];
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [superAdminCount, setSuperAdminCount] = useState(0);
  const [allAdminRaw, setAllAdminRaw] = useState([]);
  const [allAdmin, setAllAdmin] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const userManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: `admin/all?&page=${pageNumber}&perPage=${25}&search=${
        searchValue || " "
      }`,
    });
    if (response && response.data && response.data.data) {
      setAllAdminRaw(response.data.data);
      setSuperAdminCount(response.data.count || 0);
      settotalCount(response.data?.totalCount);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isLogged) {
      userManageData();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  useEffect(() => {
    const modifiedData = allAdminRaw.map((admin) => {
      return {
        item01: admin.username,
        item02: admin.firstName
          ? `${admin.firstName} ${admin.middleName || ""} ${admin.lastName}`
          : `${admin.firstName} ${admin.lastName}`,
        item03: admin.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: admin.username,
      };
    });
    setAllAdmin(modifiedData);
  }, [allAdminRaw]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div>
        <PageName
          pageName="Manage Super Admin"
          href={RoutePath.AddSuperAdmin}
        />
        <AdminCard isActive="super" />
        <SearchBar handleInputChange={handleInputChange} value={searchValue} />

        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allAdmin}
            DeleteUrl={"admin/delete"}
            eyeButton={RoutePath.EditSuperAdminDetailLink}
            editButton={RoutePath.EditSuperAdminDetailLink}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
