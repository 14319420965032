import React from "react";
import { RoutePath } from "../../../router/RoutePath";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import SendIcon from "@mui/icons-material/Send";
import { useState, useEffect } from "react";
import { DynamicTable } from "../../../tables/DynamicTable";
import PageName from "../../../component/PageName";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  // alertResponseMsgSuccess,
} from "../../../function/utilies";
import { formateDate, formatDateToISO } from "../../../function/utilies";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import QuotationCard from "../QuotationCard";
import Pagination from "../../../component/Pagination";
import SearchBar from "../../../component/props/SearchBar";

const CustomerQuotation = () => {
  const tableHead = [
    "S.No.",
    "Booking ID",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Contact",
    "Action",
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [rawData, setRawdata] = useState([]);
  const [allQuotation, setAllQuotation] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const manageData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `quotation/all?status=1&page=${pageNumber}&perPage=${25}&search=${
          searchValue || " "
        }`,
      });
      if (
        response !== null &&
        response.data !== null &&
        response.data.data !== null
      ) {
        // alertResponseMsgSuccess(response.data.msg);
        setRawdata(response.data.data);
        settotalCount(response.data?.totalCount);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      manageData();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  useEffect(() => {
    const modifiedData = rawData.map((booking) => {
      // let senderDetails = booking.senderDetails[0];
      // let receiverDtails = booking.receiverDetails[0];
      const formattedDate = formatDateToISO(formateDate(booking.quotationDate));
      return {
        item01: booking.quotationOrderId,
        item02: formattedDate,
        item03: booking.fromPersonName || "",
        item04: booking.fromAddress?.slice(0, 10) || "",
        itemto: "to",
        item05: booking.toPersonName || "",
        item06: booking.toAddress?.slice(0, 10) || "",
        item07: booking.mobile,
        item08: booking.toCustomerName,
        // img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        share: <SendIcon />,
        pageName: "quotation", //to identify the detail booking or quotation
        cancel: <DoNotDisturbOffOutlinedIcon />,
        // itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.quotationOrderId,
      };
    });
    setAllQuotation(modifiedData);
  }, [rawData]);

  const [addform, setAddForm] = useState(false);
  const [activeTableTab, setActiveTableTab] = useState("");

  useEffect(() => {
    setActiveTableTab("availableVehicles");
    setAddForm(true);
  }, []);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div>
        <PageName
          pageName="Customer Quotation"
          href={RoutePath.CustomerQuotationForm}
        />
        <QuotationCard isActive="booking" />
        <SearchBar handleInputChange={handleInputChange} value={searchValue} />

        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allQuotation}
            addform={addform}
            DeleteUrl={"quotation/delete"}
            eyeButton={RoutePath.EditCustomerQuotationDetailLink}
            editButton={RoutePath.EditCustomerQuotationDetailLink}
            cancelUrl={"quotation/cancel"}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerQuotation;
