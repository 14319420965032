import React from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../component/PrimeButton";
import Tracker from "../../../component/Tracker";

const DeliveryDetail = () => {
  const params = useParams();
  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <div className=" flex gap-4 justify-start text-xl font-semibold mb-4 text-mainTxtColor">
          <BackButton btnName="Booking Request Details" />
          <p>- {params?.username}</p>
        </div>
        <form>
          <div className="grid grid-cols-2 gap-3 text-mainTxtColor mb-4 ">
            <div className="col-span-2 sm:flex justify-between text-sm rounded-3xl my-3">
              <div className="flex items-center gap-2 mb-2">
                <label className="block ">Date of Booking :</label>
                <p className="">09 / 12 / 2023</p>
              </div>
              <div className="flex items-center gap-2  ">
                <label className="block ">Proposed Date of Delivery :</label>
                <p className="">15 / 12 / 2023</p>
              </div>
            </div>
            <div className="col-span-2 flex gap-3 text-sm rounded-3xl">
              <label className="block ">Customer Name :</label>
              <p className="block">Customer Name</p>
            </div>
            <div className="col-span-2 flex gap-3 text-sm rounded-3xl">
              <label className="block ">Contact Number :</label>
              <p className="block">7017866149</p>
            </div>
            <div className="col-span-2 flex gap-3 text-sm rounded-3xl mb-5">
              <label className="block ">Email Address :</label>
              <p className="block">kushbhardwaj7017@gmail.com</p>
            </div>
            <div className="col-span-2 w-full bg-red-200"><Tracker/></div>
            <div className="col-span-1   text-sm  text-left p-5 rounded-inputBRedius bg-inputBgColor">
              <label className="block font-bold ">
                Pick up Address
              </label>
              <div className=" font-semibold">
                <p>NAME</p>
                <p>GST Number</p>
              </div>
              <p>
                332, KUCHA GHASI RAM, FATEH PURI, CHANDNI CHOWK, DELHI DL 110006IN
              </p>
              <p>City</p>
            </div>
            <div className="col-span-1   text-sm text-right p-5 rounded-inputBRedius bg-inputBgColor">
              <label className="block font-bold ">Drop Address</label>
              <div className=" font-semibold">
                <p>NAME</p>
                <p>GST Number</p>
              </div>
              <p>
                332, KUCHA GHASI RAM, FATEH PURI, CHANDNI CHOWK, DELHI DL 110006IN
              </p>
              <p>City</p>
            </div>
          </div>
          <div className="text-lg font-semibold text-left ">
            Product Details
          </div>
          <div className=" grid grid-cols-4 lg:grid-flow-col items-end gap-x-4  ">
            <div className="col-span-1 py-2  items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                S.No.{" "}
              </label>
              <input
                value={"1-"}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className=" col-span-1 py-2  items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Receipt No.
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
            <div className=" col-span-1 py-2  items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Ref. No.
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
            <div className="col-span-1 sm:col-span-2 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Insurance{" "}
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
            <div className="col-span-2 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                VPP Amount
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
            <div className="col-span-2 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                To Pay/PAid{" "}
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
            <div className="col-span-2 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Weight Kgs.{" "}
              </label>
              <input
                value={"-------"}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-2 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Amount{" "}
              </label>
              <input
                 value={"-------"}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                 disabled
              />
            </div>
          </div>
          <div className="grid md:grid-cols-6 justify-between  items-center p-2">
            <div className=" col-span-4 w-full py-2">
              <textarea
                name="Additional Comments"
                id=""
                cols="30"
                rows="8"
                className="rounded-inputBRedius bg-inputBgColor w-full p-2"
                placeholder="Additional Comments"
              ></textarea>
            </div>
            <div className="col-span-2 ">
              <div className="lg:flex gap-6  items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  FREIGHT
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6  items-center p-2 ">
                <label className="w-36  text-sm font-medium text-gray-900">
                  INS/VPP
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  CGST%
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  SGST%
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  IGST%
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                 Bill T
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center p-2 ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Grand Total
                </label>
                <input
                  value={"-------"}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  disabled
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeliveryDetail;
