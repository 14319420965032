import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DynamicTable } from "../../tables/DynamicTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PrimeButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  formateDate,
  formatDateToISO,
} from "../../function/utilies";
import InputError from "../../component/InputError";
import PageName from "../../component/PageName";
import { RoutePath } from "../../router/RoutePath";

const initialValues = {
  username: "",
  fromDate: "",
  toDate: "",
  type: "",
  comment:""
};

const CustomerLedger = () => {
  const navigate =useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);

  //--------------------------------------Tabel Data-----------------------------------------//
  const tableHead = [
    "S.No.",
    "Date",
    "Booking ID",
    "Pick up",
    "",
    "Drop",
    "Action",
  ];
  const dataNotFound = [
    {
      item01: "-------",
      item02: "-------",
      item03: "--------",
      item04: "--------",
      item05: "--------",
      item06: "--------",
    },
  ];
  const [finalData, setFinalData] = useState([]);
  const [allBooking, setAllBooking] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [totalAmount, setTotalAmount] = useState("000");

  const ConstomerCreateSchema = Yup.object({
    username: Yup.string().required("Customer Name should not be empty"),
    fromDate: Yup.string().required("Date should not be empty"),
    toDate: Yup.string().required("Date should not be empty"),
    type: Yup.number().required("select order"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,

    onSubmit: async (values, action) => {
      setButtonLoader(true);

      const data = {
        username: values.username,
        fromDate: values.fromDate,
        toDate: values.toDate,
        type: values.type,
      };

      // console.log("data", data);
      // return false
      try {
        const response = await getAxiosWithToken({
          method: "post",
          url: "invoice/booking/all", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setFinalData(response.data.data);
          setTotalAmount(response.data);
          setNotFound(true);
          setButtonLoader(false);

        } else {
          alertResponseMsgError(response.data.msg);
          
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        setButtonLoader(false);

      }
    },
  });

  // console.log("data", finalData);

  useEffect(() => {
    const modifiedData = finalData.map((invoice) => {
      const formattedDate = formatDateToISO(formateDate(`${invoice.bookingDate || invoice.quotationDate}`));
      return {
        item01: formattedDate,
        item02: `${invoice.bookingOrderId || invoice.quotationOrderId}`,
        item03: invoice.startStation,
        itemto: "to",
        item05: invoice.endStation,
        img01: <VisibilityIcon />,
        unqiueKey: `${invoice.bookingOrderId || invoice.quotationOrderId}`,
      };
    });
    setAllBooking(modifiedData);
  }, [finalData]);

  //--------------------------------------Get customer Details-----------------------------------------//

  const [allCustomerRaw, setAllCustomerRaw] = useState([]);
  const userManageData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "customer/all",
    });
    if (response && response.data && response.data.data) {
      setAllCustomerRaw(response.data.data);
    } else{
      navigate("/")
    }
    // console.log(response);
  };

  useEffect(() => {
    userManageData();
  }, []);

  //--------------------------------------Invoice submition-----------------------------------------//
  // const [finalInvoice, setFinalInvoice] = useState([]);
  // const [allBooking, setAllBooking] = useState([]);

  const submitInvoice = async () => {
    setButtonLoader(true);

    let orderIds = []
    if (allBooking && allBooking.length !== 0) {
      await allBooking.map((booking)=>{
        orderIds.push(booking.unqiueKey)
        // console.log(booking)
      })
    }

    const data = {
      username: values.username,
      remainingAmount: values.remainingAmount,
      totalAmount: values.totalAmount,
      allOrderAmount: totalAmount.totalAmount,
      orderIds: orderIds,
      type: values.type,
      comment:values.comment,
    };
    // console.log("data :", data);
    // return false;
    try {
      const response = await getAxiosWithToken({
        method: "post",
        url: "invoice/add", // Adjust the API endpoint as needed
        data: data,
      });
      // API response 200 201
      if (response.data.success) {
        alertResponseMsgSuccess(response.data.msg);
        setButtonLoader(false);
        navigate('/customerinvoice')
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error(error);
      alertResponseMsgError(error.response.data.msg);
      setButtonLoader(false);
    }
  };
  // const orderid = values.oorderIds.map();
  const [remainingAmount, setRemainingAmount] = useState(null);
  const amount = totalAmount.totalAmount || 0;
  const paidAmount = values.totalAmount || 0;
  
  useEffect(() => {
    if (amount !== null && paidAmount !== null) {
      const remaining = amount - paidAmount;
      setRemainingAmount(remaining);
    }
  }, [amount, paidAmount]);
  values.remainingAmount = `${remainingAmount || 0}`;

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl">
        <PageName pageName="Customer Ledger" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-6 mx-4 text-left text-mainTxtColor py-4">
            <div className="col-span-2  items-center">
              <select
                value={values.username}
                name="username"
                onChange={handleChange}
                className=" w-full h-full rounded-inputBRedius bg-inputBgColor p-2  "
              >
                <option value={0} hidden disabled>
                  Select the customer
                </option>
                <option value={"none"}>None</option>
                {allCustomerRaw.map((option, index) => (
                  <option key={index} value={option.username}>
                    {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName}
                  </option>
                ))}
              </select>
              <InputError error={errors.username} touched={touched.username} />
            </div>
            <div className="col-span-1 gap-6  items-center">
              <select
                value={values.type}
                name="type"
                onChange={handleChange}
                className=" w-full h-full rounded-inputBRedius bg-inputBgColor p-2  "
              >
                <option value="" hidden disabled>
                  Order type
                </option>
                <option value="1">Booking Order</option>
                <option value="2">Quotation Order</option>
              </select>
              <InputError error={errors.type} touched={touched.type} />
            </div>
            <div className="col-span-1 gap-6  items-center">
              <input
                type="date"
                name="fromDate"
                value={"" || values.fromDate}
                onChange={handleChange}
                className="w-full h-full  rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="Date"
              />
              <InputError error={errors.fromDate} touched={touched.fromDate} />
            </div>
            <div className="col-span-1  items-center">
              <input
                type="date"
                name="toDate"
                value={"" || values.toDate}
                onChange={handleChange}
                className="w-full h-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Date of Booking"
              />
              <InputError error={errors.toDate} touched={touched.toDate} />
            </div>
            <div className="col-span-1  items-center">
              <PrimeButton
                btnName="Get Invoice"
                className=" sm:w-full h-full p-2"
                buttonLoader={buttonLoader}
              />
            </div>
          </div>
        </form>
        <div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={notFound ? allBooking : dataNotFound}
            eyeButton={RoutePath.EditBookOrderLink}
          />
          <form className=" p-4  items-center">
            <div className=" grid  sm:grid-flow-col gap-3 items-center pb-2">
              <h1 className=" p-2 font-bold">
                Amount: {totalAmount.totalAmount}
              </h1>
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Total
                </label>
                <input
                  type="text"
                  name="totalAmount"
                  value={"" || values.totalAmount}
                  onChange={handleChange}
                  className="w-full h-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Total"
                />
              </div>
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-52 text-sm font-medium text-gray-900">
                  Remaining Amount
                </label>
                <input
                  type="text"
                  name="remainingAmount"
                  value={"" || values.remainingAmount}
                  onChange={handleChange}
                  className="w-full h-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Remaining Amount"
                />
              </div>
            </div>
            <div>
            <label className="block mb-2  font-medium text-gray-900 text-left">
                Additional Comments
              </label>
            <textarea
              className="p-2 w-full h-full rounded-inputBRedius bg-inputBgColor"
              placeholder="Additional Comment"
              id=""
              cols="5"
              rows="3"
              name="comment"
              value={"" || values.comment}
                onChange={handleChange}
            ></textarea>
            </div>
          </form>
        </div>
        <div onClick={submitInvoice} className="px-4">
            <PrimeButton btnType="button" btnName="Submit" buttonLoader={buttonLoader}  className="sm:w-full" />
          </div>
      </div>
    </div>
  );
};

export default CustomerLedger;
