// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}


/* .App[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;;AAGA;;;;;GAKG","sourcesContent":[".App {\n  text-align: center;\n}\n\n\n/* .App[title]:hover::after {\n  content: attr(title);\n  position: absolute;\n  top: -100%;\n  left: 0;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
