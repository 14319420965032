import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ handleInputChange, value }) => {
  return (
    <div className="flex justify-end mr-3">
      <div className="flex items-center max-w-min bg-white border rounded-xl px-1">
        <input
          type="search"
          value={value}
          name="username"
          onChange={handleInputChange}
          className="md:block p-1 rounded-primeryBRedius focus:right-0 outline-none text-appColor"
          placeholder="Search"
        />
        <div className="text-appColor cursor-pointer">
          <SearchIcon />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
