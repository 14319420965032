import React from "react";
import { RoutePath } from "../../router/RoutePath";
// import { AddFilterButton, TitleFilter } from "../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import TrackVehical from "../../img/TrackVehical.svg";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../store/features/userSlice";
import Pagination from "../../component/Pagination";


const Station = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  //------------------------------------All station Details----------------------------//
  const stationFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `station/all?&page=${pageNumber}&perPage=${25}`,
      });
      if (response.data.success) {
        setAllStationRaw(response.data.data);
      setStationCount(response.data.count);
      settotalCount(response.data?.totalCount);

      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      stationFetchDeatils();
    }
  }, [isLogged, deleteDeatils,pageNumber]);
  const tableHead = [
    "S. No",
    "Station ID",
    "Station Name",
    "Contact",
    "Action",
  ];
  const [stationCount, setStationCount] = useState(0);
  const [allstationRaw, setAllStationRaw] = useState([]);
  const [allstation, setAllstation] = useState([]);
  
  useEffect(() => {
    const modifiedData = allstationRaw.map((station) => {
      return {
        item01: station.stationId,
        item02: station.stationName,
        item03: station.number,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: station.stationId,
      };
    });
    // console.log("address",station.address1);
    setAllstation(modifiedData);
  }, [allstationRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Station" href={RoutePath.AddStation} />
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={TrackVehical}
            count={stationCount || 0}
            title="Total station"
            // rating="4% (30 days)"
          />
        </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.AddStation} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allstation}
            DeleteUrl={"station/delete"}
            eyeButton={RoutePath.EditStationLink}
            editButton={RoutePath.EditStationLink}
          />
          <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        dataLength={totalCount}
      />
        </div>
      </div>
    </div>
  );
};

export default Station;
