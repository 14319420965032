import { LineChart } from "@mui/x-charts/LineChart";

const xLabels = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",
];

export default function TotalRevenueChart({ allDataRaw }) {
  const currentDate = new Date();
  const day = currentDate.getDay();
 

  // Safe check for allDataRaw and quotationOrder
  const bookingData = allDataRaw?.chartOrder?.quotationOrder || []; // Default to empty array if undefined

  // Ensure we have data for all days of the week
  const orderValues = xLabels.map((label) => {
    const dayData = bookingData.find((item) => item.day === label);
    return dayData ? dayData.value : 0; // Default to 0 if no data exists for that day
  });

  // Assuming `orderCount` comes from the `allDataRaw` or is passed as a prop (or is dynamically fetched)
  const orderCount = allDataRaw?.orderCount || 0; // Update this with the correct path to your data

  return (
    <div className="text-left">
      <div className="flex justify-between">
        <h1>Quotation Order</h1>
        <div className="flex gap-2 border-2 p-2 rounded-xl border-blue-400">
          {`${xLabels[day]}`}
        </div>
      </div>
      <LineChart
        height={200}
        series={[
          { data: orderValues, label: "Orders", area: true }, // Dynamic order data for the week
          // { data: Array(xLabels.length).fill(orderCount), label: "Order", area: true }, // Static order count for each day
        ]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
        sx={{
          ".MuiLineElement-root": {
            display: "",
          },
        }}
      />
    </div>
  );
}
