import React from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Link } from 'react-router-dom';


export const TitleFilter = (props) => {
  return (
      <div className=" flex justify-around ">
        <div className='flex-auto text-left text-mainTxtColor'>
          <h1 className='text-xl font-semibold'>{props.pageName}</h1>
        </div>
          <div className='   '>
            <select className='bg-primeryBgColor py-2 px-3 text-white rounded-xl'>
            <option >Apply Filter</option>
            <option value="">Date</option>
            <option value="Apply Filter">Letest</option>
            <option value="Apply Filter">Oldest</option>
            </select>
          </div>
        </div>

  );
};

export const AddFilterButton = (props) => {
  return (
  
      <div className=" flex sm:justify-end justify-center gap-4">
        <Link to={props.href} className='flex items-center gap-2 bg-primeryBgColor hover:bg-blue-800 py-2 px-3 text-white rounded-xl'>
            <AddCircleOutlineOutlinedIcon/>
            <h1>Add</h1>
          </Link>
        </div>
        

  );
};

