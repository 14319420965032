import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export const PrimeButton = ({
  btnName,
  btnType = "submit",
  className,
  buttonLoader,
}) => {
  return (
    <div>
      <button
        type={btnType}
        className={` bg-primeryBgColor hover:bg-blue-900 py-2 px-8  w-full sm:w-auto text-white rounded-xl focus:ring-2 ring-blue-400 focus:shadow ${className}`}
      >
        {btnName}
      </button>
      {buttonLoader ? (
        <div className=" flex justify-center items-start overflow-y-auto backdrop-blur-sm fixed top-0 inset-0 z-10  ">
          <div className=" absolute w-24 h-24 top-1/2 sm:right-1/3 border-8 border-dashed rounded-full animate-spin delay-700 border-primeryBgColor "></div>
        </div>
      ) : null}
    </div>
  );
};

export const BackButton = (props) => {
  const { backtoPage, btnName } = props;
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center gap-6 text-xl font-semibold text-left pb-4">
        <Link to={backtoPage} onClick={() => navigate(-1)}>
          <div className="hover:bg-gray-200 rounded-full pb-1 px-2 text-center">
            <KeyboardBackspaceOutlinedIcon />
          </div>
        </Link>
        <h1>{btnName}</h1>
      </div>
    </div>
  );
};

export const PrintButton = (props) => {
  const { id } = props; // Destructure id from props

  const handlePrint = () => {
    // Use the id prop to get the element by id
    var printContents = document.getElementById(id).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div>
      <button
        onClick={handlePrint}
        className="bg-primeryBgColor hover:bg-blue-800 py-2 px-8 text-white rounded-xl"
      >
        Print
      </button>
      <div></div>
    </div>
  );
};

export const ShareButton = ({ shareUrl }) => {
  return (
    <div className="flex gap-3">
      <EmailShareButton url={shareUrl} quote={"Dummy text!"} hashtag="#muo">
        <EmailIcon size={32} round />
      </EmailShareButton>
      <WhatsappShareButton url={shareUrl} quote={"Dummy text!"} hashtag="#muo">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
};
