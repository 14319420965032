import React, { useEffect, useState } from "react";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Statusbar } from "../../../component/StatusBar";
import Delivered from "../../../img/Delivered.svg";
import Icon_Order from "../../../img/Icon_Order.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
// import { getAxiosWithToken } from "../../axios/AxiosObj";
import PageName from "../../../component/PageName";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../router/RoutePath";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import DeliveryCard from "../DeliveryCard";
import Pagination from "../../../component/Pagination";

const FinalDelivery = () => {
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [bookingCount, setBookingCount] = useState(0);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [allBooking, setAllBooking] = useState([]);
  const [totalCount, settotalCount] = useState();

  const [pageNumber, setPageNumber] = useState(1);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `delivery/all?&page=${pageNumber}&perPage=${25}`,
      });
      if (response.data.success) {
        settotalCount(response.data?.totalCount)
        setAllBookingRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils,pageNumber]);

  //------------------------------------------order -----------------------------------///

  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      // let senderDetails = booking.senderDetails[0];
      // let receiverDtails = booking.receiverDetails[0];
      return {
        item01: booking.deliverId,
        // item02:"name",
        item02:
          booking.bookingStartStationName || booking.quotationStartStationName,
        itemto: "to",
        item05:
          booking.bookingEndStationName ||
          booking.quotationEndStationName ||
          "NA",

        // item05: receiverDtails.personName,
        item06:
          (booking.firstName || " ") +
          " " +
          (booking.middleNa || " ") +
          " " +
          (booking.lastName || " "),
        item07: booking.vehicleModel,
        unqiueKey: booking.bookingOrderId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);

  const tableHead = [
    "S. No",
    "Order Id",
    // "Name",
    "Start Station",
    "",
    // "Name",
    "Destination Station",
    "Driver",
    "Vehicle",
    // "Select",
  ];

  return (
    <div>
      <div>
        <PageName pageName="Final Delivery" />
        <DeliveryCard isActive="final" />
        <div>
          <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            {/* <AddFilterButton href={RoutePath.AddCustomerForm} /> */}
            {/* <TitleFilter /> */}
          </div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allBooking}
            // DeleteUrl={"customer/delete"}
            // eyeButton={RoutePath.EditCustomerDetailLink}
            // editButton={RoutePath.EditCustomerDetailLink}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalDelivery;
