import React, { useEffect, useState } from "react";
import { DynamicTable } from "../../tables/DynamicTable";
import { PrintButton } from "../../component/PrimeButton";
import PageName from "../../component/PageName";
import { Statusbar } from "../../component/StatusBar";
import { RoutePath } from "../../router/RoutePath";
import Icon_Order from "../../img/Icon_Order.svg";
// import Order from "../../img/Order.svg";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  // alertResponseMsgSuccess,
} from "../../function/utilies";
import { formateDate, formatDateToISO } from "../../function/utilies";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../store/features/userSlice";
import Pagination from "../../component/Pagination";
import SearchBar from "../../component/props/SearchBar";

const Report = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [rawData, setRawdata] = useState([]);
  const [count, setCount] = useState(0);

  const [alltableData, setAllTable] = useState([]);
  const [totalGrandTotal, setTotalGrandTotal] = useState(0);
  const [finalGrandTotal, setFinlaGrandTotal] = useState(0);

  const [totalGSTTotal, setGSTTotal] = useState(0);
  const [totalQutTotal, setQutTotal] = useState(0);
  const [totalGST, setTotalGST] = useState(0);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  // Calculate total GST whenever totalGrandTotal or totalGSTTotal changes
  useEffect(() => {
    const gst = totalGrandTotal - totalGSTTotal;
    setTotalGST(parseFloat(gst.toFixed(2)));
  }, [totalGrandTotal, totalGSTTotal]);
  useEffect(() => {
    const grandTotal = totalGrandTotal + totalQutTotal;
    setFinlaGrandTotal(parseFloat(grandTotal.toFixed(2)));
  }, [totalGrandTotal, totalQutTotal]);
  const manageData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `delivery/all?&page=${pageNumber}&perPage=${25}&search=${
          searchValue || " "
        }`,
      });
      if (
        response !== null &&
        response.data !== null &&
        response.data.data !== null
      ) {
        // alertResponseMsgSuccess(response.data.msg);
        setRawdata(response.data.data);
        setCount(response.data.count);
        settotalCount(response.data?.totalCount);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      manageData();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  useEffect(() => {
    const modifiedData = rawData.map((booking) => {
      const formattedDate = formatDateToISO(
        formateDate(booking.bookingDate || booking.quotationDate)
      );
      return {
        item01: booking.orderId,
        item02: formattedDate,
        item03: booking.bookingBillTotal || booking.quotationGrandTotal,
        item04: booking.bookingCgst || "NA",
        item05: booking.bookingSgst || "NA",
        item06: booking.bookingIgst || "NA",
        item07: booking.bookingGrandTotal || booking.quotationGrandTotal,
        unqiueKey: booking.bookingOrderId,
      };
    });
    const GSTtotal = rawData.reduce(
      (sum, booking) => sum + parseFloat(booking.bookingBillTotal || 0),
      0
    );
    const total = rawData.reduce(
      (sum, booking) => sum + parseFloat(booking?.bookingGrandTotal || 0),
      0
    );
    const quotationtotal = rawData.reduce(
      (sum, booking) => sum + parseFloat(booking?.quotationGrandTotal || 0),
      0
    );

    setAllTable(modifiedData);
    setTotalGrandTotal(total);
    setGSTTotal(GSTtotal);
    setQutTotal(quotationtotal);
  }, [rawData]);
  const customerHead = [
    "S.No.",
    "Order ID",
    "Date",
    "Amount",
    "CGST%",
    "SGST%",
    "IGST%",
    "Total",
  ];

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <div className="flex justify-center items-end gap-4 pt-4 pr-4">
        <PageName pageName="Report" />
        {/* <PrimeButton btnName="Export" /> */}
        <PrintButton id="contentToPrint" />
      </div>

      <div className="grid sm:grid-cols-1  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div>
          <Link to={RoutePath.Report}>
            <Statusbar
              count={count}
              isActive={true}
              src={Icon_Order}
              title="Booking Report"
              // rating="4%"
            />
          </Link>
        </div>
        {/* <div>
          <Link to={RoutePath.TotalVehicle}>
            <Statusbar
              src={Order}
              count="862"
              title="Final Report"
              rating="25%"
            />
          </Link>
        </div> */}
      </div>
      <div className="bg-backgroundColor rounded-xl  ">
        <SearchBar handleInputChange={handleInputChange} value={searchValue} />

        <div id="contentToPrint">
          <DynamicTable
            tableHeadRow={customerHead}
            data={alltableData}
            eyeButton="/ledgerviewdetail"
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
          <form className="mx-4 p-3 bg-white items-center">
            <div className=" grid  sm:grid-flow-col gap-3 items-center pb-2">
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Total GST
                </label>
                <input
                  type="text"
                  value={totalGST}
                  className=" w-full rounded-inputBRedius bg-inputBgColor p-1"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-52 text-sm font-medium text-gray-900">
                  Total Amount
                </label>
                <input
                  type="text"
                  value={finalGrandTotal}
                  className=" w-full rounded-inputBRedius bg-inputBgColor p-1"
                  placeholder=""
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Report;
