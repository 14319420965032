import React, { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Mapper from '../../component/Mapper';

const TrackerMap = () => {
  const [inputValue, setInputValue] = useState("");


  return (
    <>
      <div className='flex justify-between bg-backgroundColor p-4 gap-5'>
        <h1 className="text-left text-xl font-semibold p-2">Location of Your Order / Vehicle</h1>
        <div className='flex '>
          <select className='px-5 rounded-l-lg bg-primeryBgColor text-white' onChange={(e) => setInputValue(e.target.value)}>
            <option value="Order">Order ID</option>
            <option value="Vehicle">Vehicle</option>
          </select>
          <input
            value={inputValue}
            className='w-full bg-inputBgColor p-2 rounded-r-lg'
            placeholder='Enter here'
            required
          />
        </div>
      </div>
      <div>
        <Mapper/>
      </div>
    </>
  );
};

export default TrackerMap;
