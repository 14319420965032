// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { useDispatch, useSelector } from "react-redux";
// import {setPage} from "../../store/feature/userSlice"

export default function Pagination({  pageNumber,setPageNumber,dataLength }) {
  // const dispatch = useDispatch()
  // const {page}= useSelector((state)=>state.user)

  console.log("dataLength",dataLength)
  const maxPageNumber = Math.ceil(dataLength / 10);


  const previousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextClick = () => {
    console.log("Next workong")
    if (pageNumber < maxPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };
  return (
    <div className="mb-2">
      <div className="flex flex-1 justify-between items-center bg-white px-3 py-2 ">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">{dataLength}</span> results
          </p>
        </div>
        <div className=" flex gap-2">
          <button
            onClick={previousClick}
            
            className=" inline-flex text-center rounded-md active:hover:bg-appBgColor border-gray-300 bg-white py-2 px-3 text-sm font-medium text-gray-700 hover:bg-backgroundColor"
          >
            <ArrowBackIosIcon className="h-3 w-3 p-1" />
            Previous
          </button>
          <button
            onClick={nextClick}
            className=" inline-flex text-center rounded-md active:hover:bg-appBgColor border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 hover:bg-backgroundColor"
          >
            Next
            <ArrowForwardIosIcon className="h-3 w-3 p-1" />
          </button>
        </div>
      </div>
    </div>
  );
}
