import { PrimeButton, BackButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  formateDate,
  formatDateToISO,
  valuesEmpty,
} from "../../function/utilies";
import InputError from "../../component/InputError";
import { useEffect, useState, useRef } from "react";

const initialValues = {
  title: "",
  date: "",
  invoiceNo: "",
  details: "",
  amount: "",
  taxAmount: "",
  totalAmount: "",
  uploadFile: "",
};

const EditExpensesDetail = () => {
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);

  const [allExpenseRaw, setAllExpenseRaw] = useState();

  const userCreateSchema = Yup.object({
    title: Yup.string().required("First Name should not be empty"),
    date: Yup.string().required("Date should not be empty"),
    invoiceNo: Yup.number().required("invoiceNo Number should not be empty"),
    details: Yup.string().required("Details should not be empty"),
    amount: Yup.number().required("Amount should not be empty"),
    taxAmount: Yup.number().required("Tex Amount should not be empty"),
    totalAmount: Yup.number().required("Total Amount should not be empty"),
    // uploadFile: Yup.string().required("Upload uploadFile"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      setButtonLoader(true);

      let updatedvalue = await valuesCheck(values, allExpenseRaw);
      let data = new FormData();
      for (const key in updatedvalue) {
        if (updatedvalue.hasOwnProperty(key)) {
          const value = updatedvalue[key];
          data.append(key, value);
        }
      }
      let expenseId;
      if (params && params.expenseId && params.expenseId !== " ") {
        expenseId = params.expenseId;
      }

      // console.log("data", data);
      // return false;

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `expense/update/${expenseId}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        // alertResponseMsgError(error.response.msg);
      }
    },
  });
  const getExpenseDeatils = async (expenseId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `expense/details/${expenseId}`, // Adjust the API endpoint as needed
      });
      // console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setAllExpenseRaw(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (allExpenseRaw) {
      // console.log(allCustomerRaw);
      values.title = allExpenseRaw.title;
      values.date = formatDateToISO(formateDate(allExpenseRaw.date));
      values.invoiceNo = allExpenseRaw.invoiceNo;
      values.details = allExpenseRaw.details;
      values.amount = allExpenseRaw.amount;
      values.taxAmount = allExpenseRaw.taxAmount;
      values.totalAmount = allExpenseRaw.totalAmount;
      values.uploadFile = allExpenseRaw.uploadFile;
    }
  }, [allExpenseRaw]);

  const valueRef = useRef(values);

  useEffect(() => {
    if (params && params.expenseId && params.expenseId !== " ") {
      valueRef.current = valuesEmpty(values, "");

      getExpenseDeatils(params.expenseId);
    } else {
      window.location.href = "/expense";
    }
  }, [params]);

  //----------------------------------------------- upload invoice -------------------------------------------//
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [invoicePerview, setInvoicePerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "invoiceDoc") {
      let invoice = files[0];
      if (invoice.size > 2000000) {
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        invoice.type.includes("image/jpeg") ||
        invoice.type.includes("image/jpg") ||
        invoice.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(invoice);
        setInvoicePerview(imageUrl);
        setIdDoc(invoice);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };

  return (
    <div className="px-8 py-5">
      <BackButton btnName="Invoice Receipt" />
      <form onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
          <div className="col-span-6 gap-6 py-2  items-center"><img
                  src={`https://bharatapi.ddns.net/${values.uploadFile}`}
                  alt="user"
                  className="w-full p-1 rounded-inputBRedius bg-slate-100 ring-1 ring-primeryBgColor"
                  loading="lazy"
                /></div>
        </div>
        {invoicePerview ? <img src={invoicePerview} alt="dd" /> : null}
        <div className="mt-12">
          <PrimeButton btnName="Update  " buttonLoader={buttonLoader} />
        </div>
      </form>
    </div>
  );
};

export default EditExpensesDetail;
