// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function Pagination({nextclick,previousclick,dataLength, page}) {
  return (
    <div className="px-3">
      <div className="flex flex-1 justify-between items-center bg-white px-3 py-2 ">
        <div>
          <p className="text-sm text-gray-700">
            {/* Showing <span className="font-medium">{page}</span> to{" "}
            <span className="font-medium">10</span> of{" "} */}
            <span className="font-medium">{dataLength}</span> results
          </p>
        </div>
        <div className=" flex gap-2">
          <button onClick={previousclick} className=" inline-flex text-center rounded-md  border-gray-300 bg-white py-2 px-3 text-sm font-medium text-gray-700 hover:bg-backgroundColor">
            <ArrowBackIosIcon className="h-3 w-3 p-1" />
            Previous
          </button>
          <button onClick={nextclick} className=" inline-flex text-center rounded-md  border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 hover:bg-backgroundColor">
            Next
            <ArrowForwardIosIcon className="h-3 w-3 p-1" />
          </button>
        </div>
      </div>
    </div>
  );
}
