import React, { useEffect, useState, useMemo } from "react";
// import { RoutePath } from "../../../router/RoutePath";
import { Link } from "react-router-dom";
import LoadingTable from "../../../tables/LoadingTable";
// import DeleteDialogBox from "../../../component/DeleteDialogBox";
import Pagination from "../../../tables/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
// import profile from "../img/profile.jpg";

export const OrderSelectionTable = ({
  tableHeadRow,
  data,
  eyeButton,
  editButton,
  isDataLoading,
  setOrderIds,
}) => {
  const { apiUrl } = useSelector((state) => state.user);

  //-------------------------------------- Pagination -------------------------------------------------------------------//

  const numOfTableRow = 20;
  const [page, setPage] = useState(1);
  const dataReverse = data?.slice().reverse();
  const dataLength = data.length;
  const allpages = (dataLength / numOfTableRow).toFixed();
  const range = page * numOfTableRow - numOfTableRow;
  const onNextClick = () => {
    if (range < allpages) {
      setPage(page + 1);
    }
  };
  const onPreviousClick = () => {
    if (range > 0) {
      setPage(page - 1);
    }
  };

  //-------------------------------------filer search-----------------------------------------------//
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Function to handle input change
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Memoize filtered items
  const filteredItems = useMemo(() => {
    return dataReverse?.filter(
      (item) =>
        item.item01?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.item05?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, dataReverse]);

  // Use effect to set filtered data
  useEffect(() => {
    // Only update state if filteredItems have actually changed
    if (JSON.stringify(filteredData) !== JSON.stringify(filteredItems)) {
      setFilteredData(filteredItems);
    }
  }, [filteredItems, filteredData]);

  // console.log("filter", filteredData);
  //----------------------Check box -------------------------------------------------------------
  const [selectedItems, setSelectedItems] = useState([]);
  // console.log("selectedItems", selectedItems);
  // Function to handle checkbox toggle
  const handleCheckboxChange = (column) => {
    if (selectedItems.includes(column.unqiueKey)) {
      // If item is already selected, remove it
      setSelectedItems((prev) =>
        prev.filter((key) => key !== column.unqiueKey)
      );
      setOrderIds((prev) => prev.filter((key) => key !== column.unqiueKey));
    } else {
      // Otherwise, add it to the selected items
      setSelectedItems((prev) => [...prev, column.unqiueKey]);
      setOrderIds((prev) => [...prev, column.unqiueKey]);
    }
  };

  return (
    <div>
      <div className="relative overflow-x-auto p-3 w-full">
        <div className="flex items-center gap-4 justify-end  pb-2 px-1">
          <div className="flex items-center text-mainTxtColor rounded-inputBRedius border bg-inputBgColor px-2">
            <input
              type="search"
              className=" rounded-inputBRedius bg-inputBgColor py-2 outline-none"
              value={searchValue}
              name="username"
              onChange={handleInputChange}
              placeholder="Find destination station"
            />
            <div>
              {" "}
              <SearchIcon />
            </div>
          </div>
          <div></div>
        </div>
        <table className="w-full">
          <thead className="bg-primeryBgColor border-4 border-backgroundColor text-white w-full">
            <tr className="font-bold  w-full ">
              {tableHeadRow.map((head, index) => (
                <th key={index} className="p-2">
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white  text-mainTxtColor p-3">
            {isDataLoading ? (
              <tr>
                <td colSpan={tableHeadRow.length} className="text-center">
                  Data Not Available
                </td>
              </tr>
            ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData
                .slice(
                  page * numOfTableRow - numOfTableRow,
                  page * numOfTableRow
                )
                .map((column, index) => (
                  <tr key={index} className="border-4 border-backgroundColor ">
                    {column.checkBox && (
                      <td className="px-4 py-1">
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(column.unqiueKey)}
                          onChange={() => handleCheckboxChange(column)}
                        />
                      </td>
                    )}
                    <td className="px-4 py-1   ">
                      {index + 1 + (page * numOfTableRow - numOfTableRow)}
                    </td>

                    {column.item01 ? (
                      <td className="px-4 py-1">{column.item01}</td>
                    ) : null}
                    {column.item02 ? (
                      <td className="px-4 py-1 ">{column.item02}</td>
                    ) : null}
                    {column.item03 ? (
                      <td className="px-4 py-1">{column.item03}</td>
                    ) : null}
                    {column.item04 ? (
                      <td className="px-4 py-1">{column.item04}</td>
                    ) : null}
                    {column.itemto ? (
                      <td className="px-4 py-1">{column.itemto}</td>
                    ) : null}
                    {column.item05 ? (
                      <td className="px-4 py-1">{column.item05}</td>
                    ) : null}
                    {column.item06 ? (
                      <td className="px-4 py-1">{column.item06}</td>
                    ) : null}
                    {column.item07 ? (
                      <td className="px-4 py-1">{column.item07}</td>
                    ) : null}
                    {column.item08 ? (
                      <td className="px-4 py-1">{column.item08}</td>
                    ) : null}
                    {column.img01 || column.img03 ? (
                      <td>
                        <div className="flex justify-center items-center gap-2  text-slate-600">
                          {column.img01 ? (
                            <Link to={eyeButton + "/" + column.unqiueKey}>
                              <div
                                className="hover:text-slate-400"
                                title="View"
                              >
                                {column.img01}
                              </div>
                            </Link>
                          ) : null}
                          {column.img02 ? (
                            <Link to={editButton + "/" + column.unqiueKey}>
                              <div
                                className="hover:text-green-600"
                                title="Edit"
                              >
                                {column.img02}
                              </div>
                            </Link>
                          ) : null}
                        </div>
                      </td>
                    ) : null}
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={tableHeadRow.length} className="text-center">
                  <LoadingTable />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        nextclick={onNextClick}
        previousclick={onPreviousClick}
        dataLength={dataLength}
        page={allpages}
      />
    </div>
  );
};

// export default AvailableDriversTable;
