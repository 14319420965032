import React from "react";
import { useDispatch } from "react-redux";
import { setDeleteDetail } from "../store/features/userSlice";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { getAxiosWithToken } from "../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../function/utilies";

export default function DeleteDialogBox({
  open,
  setOpen,
  method,  // Optional method prop
  data,
}) {
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(!open);

  const handleDeleteApi = async () => {
    if (open && data && data.deleteUrl) {
      // Set loading state here if needed
      const response = await getAxiosWithToken({
        method: method || "delete",  // Use provided method, fallback to 'delete'
        url: data.deleteUrl,
      });

      if (response.data && response.data.success && response.data.msg) {
        alertResponseMsgSuccess(response.data.msg);
        dispatch(setDeleteDetail(true));

        // Set it to false after 1 second
        setTimeout(() => {
          dispatch(setDeleteDetail(false));
        }, 1000);

        // Optionally reload or close the dialog
        setOpen(!open);
      } else {
        alertResponseMsgError(response.data?.msg || "An error occurred.");
      }
    }
  };

  return (
    <Dialog open={open} handler={handleOpen} size="xs">
      <DialogHeader>
        {data.headerText || "Confirm Deletion"}
      </DialogHeader>
      <DialogBody>
        {data.paraText || "Are you sure you want to delete this item?"}
      </DialogBody>
      <DialogFooter>
        <Button variant="text" color="green" onClick={handleDeleteApi}>
          <span>Confirm</span>
        </Button>
        <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
          <span>Cancel</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
