import React from "react";
import { RoutePath } from "../../router/RoutePath";
// import { AddFilterButton, TitleFilter } from "../../component/TitileFilter";
import Icon_Order from "../../img/Icon_Order.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../store/features/userSlice";
import { alertResponseMsgError } from "../../function/utilies";
import Pagination from "../../component/Pagination";
import SearchBar from "../../component/props/SearchBar";

const ManageExpense = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allExpensesData, setAllExpensesdata] = useState([]);
  const [allExpensesCount, setAllExpensesCount] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [expensesTableData, setExpensesTableData] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const ManageExpenseHead = [
    "S.No.",
    "Invoice",
    "Date",
    "Name",
    "Taxable Amount",
    "Total",
    "Receiving",
    "Action",
  ];

  const expensesData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `expense/all?&page=${pageNumber}&perPage=${25}&search=${
          searchValue || " "
        }`,
      });

      if (response && response.data && response.data.data) {
        setAllExpensesdata(response.data.data);
        setAllExpensesCount(response.data.count || 0);
        settotalCount(response.data?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching expense details:", error);

      // Check if the error is due to unauthorized access
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        // Navigate to login page and perform logout
        try {
          await dispatch(userLogOut());
          navigate("/"); // Redirect to login or root page
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle any additional error as needed
        }
      } else {
        // Handle any other type of error (optional)
        alertResponseMsgError("An error occurred");
      }
    }
  };

  // useEffect to fetch data on component load
  useEffect(() => {
    if (isLogged) {
      expensesData();
    }
  }, [isLogged, deleteDeatils, pageNumber,searchValue]);

  useEffect(() => {
    if (allExpensesData.length <= 0) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  }, [allExpensesData]);

  useEffect(() => {
    const modifiedData = allExpensesData.map((expense) => {
      const allDate = new Date(expense.date); // Convert to Date object if necessary
      const year = allDate.getFullYear();
      const month = (allDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it's zero-based
      const day = allDate.getDate().toString().padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      return {
        item01: expense.invoiceNo,
        item02: formattedDate,
        item03: expense.title,
        item04: expense.taxAmount,
        item05: expense.totalAmount,
        imgBtn: <UploadFileOutlinedIcon />,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: expense.expenseId,
        uploadFile: expense.uploadFile,
      };
    });
    setExpensesTableData(modifiedData);
  }, [allExpensesData]);
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div>
      <div>
        <PageName pageName="Manage Expenses" href={RoutePath.ExpensesForm} />
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={Icon_Order}
            count={allExpensesCount || 0}
            title="Manage Expenses"
            // rating="4% ("
          />
        </div>
        <SearchBar handleInputChange={handleInputChange} value={searchValue} />

        <div>
          <DynamicTable
            tableHeadRow={ManageExpenseHead}
            data={expensesTableData}
            DeleteUrl={"expense/delete"}
            eyeButton={RoutePath.EditExpensesDetailLink}
            editButton={RoutePath.EditExpensesDetailLink}
            invoceRecipt={RoutePath.InvoiceReciptLink}
            isDataLoading={dataLoading}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            dataLength={totalCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageExpense;
