import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
import Icon_Order from "../../img/Icon_Order.svg";
import Delivered from "../../img/Delivered.svg";
import Order from "../../img/Order.svg";
import Group from "../../img/Group.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";

const BookingCard = ({isActive}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [active, setSctive] = useState([]);
  const [cancelled, setcancelled] = useState([]);
  const [request, setrequest] = useState();
  const [revenue, setrevenue] = useState([]);

//   console.log("allBookingRaw", allBookingRaw);
//   console.log("request", request);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=booking",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data.booking);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      setSctive(allBookingRaw.active);
      setcancelled(allBookingRaw.cancelled);
      setrequest(allBookingRaw?.request);
      setrevenue(allBookingRaw.revenue);
    }
  }, [allBookingRaw]);
  return (
    <>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4 cursor-pointer">
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Icon_Order}
            count={request?.count}
            isActive={isActive ==="booking"}
            title="Booking Requests"
            rating={`${Math.floor(request?.percentage)}%`}
          />
        </Link>
        <Link to={RoutePath.ActiveDelivery}>
          <Statusbar
            src={Delivered}
            count={active?.count}
            isActive={isActive ==="active"}
            title="Active Deliveries"
            rating={`${Math.floor(active?.percentage)}%`}
          />
        </Link>
        <Link to={RoutePath.TotalCanceled}>
        <Statusbar
          src={Order}
          isActive={isActive ==="cancelled"}
          count={cancelled?.count}
          title="Total Cancelled"
          rating={`${Math.floor(cancelled?.percentage)}%`}
        />
        </Link>
        <Link to={RoutePath.TotalRevenue}>
        <Statusbar
          src={Group}
          count={`Rs ${revenue?.total}`}
          isActive={isActive ==="revenue"}

          title="Total Revenue"
          rating={`${Math.floor(revenue?.percentage)}%`}
        />
        </Link>
      </div>
    </>
  );
};

export default BookingCard;
