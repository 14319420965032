import React, { useState, useEffect } from "react";
import InputError from "../../../component/InputError";
import profile from "../../../img/profile.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  // valuesEmpty,
} from "../../../function/utilies";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  address1: "",
  city: "",
  state: "",
  district: "",
  pincode: "",
  idProofNo: "",
  dlNumber: "",
  idProofsrc: "",
  profilepic: "",
  status:"",
};
const EditDriverDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [photo, setPhoto] = useState(null);
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allDriverRaw, setAllDriverRaw] = useState();
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  const DriverCreateSchema = Yup.object({
    firstName: Yup.string().required("First name should not be empty"),
    lastName: Yup.string().required("Last name should not be empty"),
    mobile: Yup.number().required("Mobile number should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    address1: Yup.string().required("Address should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin code should not be empty"),
    state: Yup.string().required("State should not be empty"),
    city: Yup.string().required("City should not be empty"),
    dlNumber: Yup.string().required("DL number should not be empty"),
  });
  const {
    errors,
    touched,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: DriverCreateSchema,
    onSubmit: async (values) => {
      setButtonLoader(true);

      let updatedValue = await valuesCheck(values, allDriverRaw);
      let data = new FormData();

      for (const key in updatedValue) {
        if (updatedValue.hasOwnProperty(key)) {
          const value = updatedValue[key];
          data.append(key, value);
        }
      }

      if (photo) {
        data.append("userPhoto", photo);
      }
      if (IdDoc) {
        data.append("idProofDoc", IdDoc);
      }

      const username = params?.username?.trim() ? params.username : "";

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `driver/update/${username}`,
          data: data,
          reqType: "formData",
        });

        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
          navigate(-1); // Go back after successful update
        } else {
          alertResponseMsgError(response.data.msg);
          setButtonLoader(false);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response?.data?.msg || "An error occurred");
        setButtonLoader(false);
      }
    },
  });

  const getDetails = async (username) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `driver/details/${username}`,
      });
      if (response?.data?.data) {
        setAllDriverRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };

  //----------------------------------------------- upload idProofsrc -------------------------------------------//

  // const [userPhotoErr, setuserPhotoErr] = useState(null);

  // values.profilepic= photo
  useEffect(() => {
    if (allDriverRaw) {
      values.firstName = allDriverRaw.firstName;
      values.middleName = allDriverRaw.middleName;
      values.lastName = allDriverRaw.lastName;
      values.mobile = allDriverRaw.mobile;
      values.email = allDriverRaw.email;
      values.address1 = allDriverRaw.address1;
      values.district = allDriverRaw.district;
      values.city = allDriverRaw.city;
      values.state = allDriverRaw.state;
      values.pincode = allDriverRaw.pincode;
      values.dlNumber = allDriverRaw.dlNumber;
      values.idProofNo = allDriverRaw.idProofNo;
      values.idProofsrc = allDriverRaw.idProofsrc;
      values.profilepic = allDriverRaw.profilepic;
      values.status = allDriverRaw.status;
    }
  }, [allDriverRaw]);

  // Fetch driver details based on params
  useEffect(() => {
    if (params?.username?.trim()) {
      getDetails(params.username);
    }
  }, [params]);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    const MAX_FILE_SIZE = 2000000; // 2MB
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file.size > MAX_FILE_SIZE) {
      alertResponseMsgError("Image should be less than 2 MB");
      return false;
    }
    if (!allowedFileTypes.includes(file.type)) {
      alertResponseMsgError("Image format should be jpeg, jpg, or png");
      return false;
    }

    const imageUrl = URL.createObjectURL(file);

    if (name === "userPhoto") {
      setPhotoPerview(imageUrl);
      setPhoto(file);
    } else if (name === "idProofDoc") {
      setIdDocPerview(imageUrl);
      setIdDoc(file);
    }
  };

  //----------------------------------------------invoice Image------------------------------------------------------//

  const handleImageClick = (uploadFile) => {
    console.log("its working ", uploadFile);
    const imageUrl = uploadFile ? `${uploadFile}` : profile;

    //  window.open method if you want to open the image in a new tab
    window.open(imageUrl, "_blank");
  };
  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Driver Details" backtoPage="/managedriver" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={
                    `https://api.bharatparcel.org/${values.profilepic}` ||
                    profile
                  }
                  alt="Driver"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={"" || values.mobile}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="mobile"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="w-36 md:flex-none text-sm font-medium">
                Status
              </label>
              <div className="w-full">
                <select
                  name="status"
                  value={"" || values.status}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">Deactive</option>
                  <option value="3">Blacklist</option>
                </select>
                <InputError error={errors.status} touched={touched.status} />
              </div>
            </div>

            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                address1 / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address1"
                  value={"" || values.address1}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="address1 / Street"
                />
                <InputError
                  error={errors.address1}
                  touched={touched.address1}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
                <InputError error={errors.state} touched={touched.state} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
                <InputError error={errors.city} touched={touched.city} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                DL Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="dlNumber"
                  value={"" || values.dlNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="DL Number"
                />
                <InputError
                  error={errors.dlNumber}
                  touched={touched.dlNumber}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="idProofNo"
                  value={"" || values.idProofNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="ID Number"
                />
                <InputError
                  error={errors.idProofNo}
                  touched={touched.idProofNo}
                />
              </div>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="idProofDoc"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Id Proof"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {idDocPerview ? (
                  <img src={idDocPerview} alt="Id Proof" />
                ) : null}
                <div className="col-span-3 gap-6  lg:flex py-2  items-center">
                  <img
                    onClick={() =>
                      handleImageClick(
                        `https://api.bharatparcel.org/${values.idProofsrc}`
                      )
                    }
                    src={`https://api.bharatparcel.org/${values.idProofsrc}`}
                    alt="user"
                    className=" p-1 rounded-inputBRedius bg-slate-100 ring-1 ring-primeryBgColor cursor-pointer"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Upload Driver's Photo
              </label>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="userPhoto"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Driver Photo"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {photoPerview ? <img src={photoPerview} alt="dd" /> : null}
              </div>
            </div>
          </div>
          <div className=" text-white mt-4 ">
            <div className="mt-12">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDriverDetail;
