import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  {
    label: "In process-recipient city",
    description: "Berlin,germany",
  },
  {
    label: "Transite-sending city",
    description: "Jakarta,indonesia",
  },
  {
    label: "Sent from majalengka",
    description: "Majalengka,indonesia",
  },
];

export default function Tracker({ step }) {
  const [activeStep, setActiveStep] = React.useState(0);
  React.useEffect(() => {
    // This will be called after the initial render
    setActiveStep(2);
  }, []);

  const currentDate = new Date();


  return (
    <Box sx={{ maxWidth: 1500, p: 2 }}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel  sx={{ color:"white", background:"white", borderRadius:5, p:1, my:1}} >
              {step.label} <br />
              {step.description} <br />
              {currentDate.toLocaleString()}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
