import React, { useState } from "react";
import { PrimeButton, BackButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import Dropdown from "../../../component/Dropdown";

const initialValues = {
  fullName: "",
  contact: "",
  email: "",
  locality: "",
  district: "",
  pincode: "",
  IDNumber: "",
  document: "",
  photo: "",
};

const AddStaff = () => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const states = ["State1", "State2", "State3"]; // Add your state names here
  const cities = {
    State1: ["City1A", "City1B", "City1C"],
    State2: ["City2A", "City2B", "City2C"],
    State3: ["City3A", "City3B", "City3C"],
  }; // Add your city names here

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(""); // Reset city when state changes
  };

  const userCreateSchema = Yup.object({
    fullName: Yup.string().required("Username should not be empty"),
    contact: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    locality: Yup.string().required("locality should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    IDNumber: Yup.number().required("ID Number should not be empty"),
    // document: Yup.string().required("Upload Document"),
    // photo: Yup.string().required("Upload Photo"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      let fullName = values.fullName;
      let contact = values.contact;
      let email = values.email;
      let locality = values.locality;
      let district = values.district;
      let pincode = values.pincode;
      let IDNumber = values.IDNumber;
      let document = values.document;
      let photo = values.photo;

      console.log("fullName :", fullName);

      const data = {
        fullName: fullName,
        contact: contact,
        email: email,
        locality: locality,
        district: district,
        pincode: pincode,
        IDNumber: IDNumber,
        document: document,
        photo: photo,
      };

      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "users", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.success) {
          alertResponseMsgSuccess(response.msg);
        } else {
          alertResponseMsgError(response.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <div className="px-8 py-5">
      <BackButton btnName="User Details" />
      <form onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
          <div className="col-span-6 gap-6  lg:flex py-2  items-center">
            <label
              for="name"
              className="w-36 md:flex-none text-sm font-medium  "
            >
              Name
            </label>
            <div className="w-full">
              <input
                type="text"
                name="fullName"
                value={"" || values.fullName}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="User Name"
              />
              <InputError error={errors.fullName} touched={touched.fullName} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Contact Number
            </label>
            <div className="w-full">
              <input
                type="text"
                name="contact"
                value={"" || values.contact}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Contact"
              />
              <InputError error={errors.contact} touched={touched.contact} />
            </div>
          </div>
          <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label
              for="Email"
              className=" w-36 md:flex-none text-sm font-medium "
            >
              Email ID
            </label>
            <div className="w-full">
              <input
                type="email"
                name="email"
                value={"" || values.email}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Email"
              />
              <InputError error={errors.email} touched={touched.email} />
            </div>
          </div>
          <div className="div col-span-6 font-semibold text-left text-lg">
            Addresss
          </div>
          <div className="col-span-6 gap-6  lg:flex py-2  items-center">
            <label
              for="Addresss"
              className="w-36 md:flex-none text-sm font-medium "
            >
              Locality / Street
            </label>
            <div className="w-full">
              <input
                type="text"
                name="locality"
                value={"" || values.locality}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Locality / Street"
              />
              <InputError error={errors.locality} touched={touched.locality} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              State
            </label>
            <div className="w-full">
              <Dropdown options={states} onSelect={handleStateChange} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              City
            </label>
            <div className="w-full">
              <Dropdown
                options={cities[selectedState] || []}
                onSelect={() => {}}
              />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              District
            </label>
            <div className="w-full">
              <input
                type="text"
                name="district"
                value={"" || values.district}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="District"
              />
              <InputError error={errors.district} touched={touched.district} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Pin Code
            </label>
            <div className="w-full">
              <input
                type="text"
                name="pincode"
                value={"" || values.pincode}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Pin Code"
              />
              <InputError error={errors.pincode} touched={touched.pincode} />
            </div>
          </div>
          <div className=" col-span-6 font-semibold text-left text-lg">
            Documents
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              ID Proof
            </label>
            <div className="w-full">
              <input
                type="text"
                name="IDNumber"
                value={"" || values.IDNumber}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="ID Number"
              />
              <InputError error={errors.IDNumber} touched={touched.IDNumber} />
            </div>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="document"
                value={"" || values.document}
                onChange={handleChange}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Document"
              />
              <InputError error={errors.document} touched={touched.document} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Upload User's Photo
            </label>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="document"
                value={"" || values.document}
                onChange={handleChange}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Document"
              />
              <InputError error={errors.photo} touched={touched.photo} />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <PrimeButton btnName="Sumit" />
        </div>
      </form>
    </div>
  );
};

export default AddStaff;
