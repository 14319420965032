import React, { useState } from "react";
import BoxMan from "../img/BoxMan.svg";
import BHARATParcel from "../img/BHARATParcel.svg";
import { useDispatch } from "react-redux";
import { userLogin } from "../store/features/userSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import ShowHideBtn from "../component/ShowHideBtn";
import InputError from "../component/InputError";

const initialValues = {
  username: "",
  password: "",
};

const LoginPage = () => {
  const dispatch = useDispatch();

  const loginSchema = Yup.object({
    username: Yup.string().required("Username should not be empty"),
    password: Yup.string().required("Password should not be empty"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const PasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, action) => {
      let username = values.username;
      let password = values.password;
      await dispatch(userLogin({ username, password }));
      // console.log(rep);
      // if (rep.payload && rep.payload !== false) {
      //   navigate("/");
      //   setTimeout(() => {
      //     window.location.reload(true);
      //   }, 1000);
      // }
    },
  });

  return (
    <div className="bg-bgElement bg-cover ">
      <div className="bg-backgroundColor bg-opacity-75 flex justify-center h-full items-center p-12 ">
        <div className=" bg-backgroundColor flex justify-center gap-9 items-center rounded-3xl">
          <div className=" text-primeryBgColor font-sans ">
            {/* div for side blue background */}
            <div className="md:p-9 p-2 w-full">
              <div className="text-center m-10">
                <h1 className="text-3xl font-bold">Login</h1>
              </div>

              <form className="sm:w-96 p-6 pt-8 " onSubmit={handleSubmit}>
                <div className="mb-5 ">
                  <label
                    htmlFor="name"
                    className="block m-2 text-sm text-left font-semibold"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 bg-primeryBgColor rounded-xl text-white placeholder:text-blue-100 "
                    placeholder="+91 0000000000"
                    value={"" || values.username}
                    onChange={handleChange}
                    name="username"
                  />
                  <InputError
                    error={errors.username}
                    touched={touched.username}
                    className="hidden"
                  />
                </div>

                <div className="mb-5 ">
                  <label className="block m-2 text-sm text-left font-semibold">
                    Password
                  </label>
                  <div className="w-full flex items-center gap-2">
                    <input
                      type={showPassword ? "text" : "password"} // Toggle between 'password' and 'text'
                      name="password"
                      value={"" || values.password}
                      onChange={handleChange}
                      className="w-full p-2 bg-primeryBgColor rounded-xl text-white placeholder:text-blue-100"
                      placeholder="password"
                    />
                    {values.password && (
                      <div onClick={PasswordVisibility}>
                        <ShowHideBtn isShow={showPassword} />
                      </div>
                    )}
                  </div>
                  <InputError
                    error={errors.password}
                    touched={touched.password}
                    className="hidden"
                  />
                </div>

                <div className="mb-5 ">
                  <input
                    type="submit"
                    value="Sign In"
                    id="submitForm"
                    className="w-full p-4 mt-8 bg-primeryBgColor rounded-xl text-white placeholder:text-blue-100 cursor-pointer active:bg-blue-500"
                    placeholder="submit"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className=" relative bg-primeryBgColor rounded-l-full rounded-r-primeryBRedius h-full hidden md:block">
            <img
              src={BHARATParcel}
              alt="name"
              className=" absolute hidden  md:block top-1/3 left-8 "
            />
            <img src={BoxMan} alt="name" className="py-3 px-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
