import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import "lrm-google";

function Routing() {
  const map = useMap();

  useEffect(() => {
    if (map) {
      const waypoints = [
        L.latLng(16.506, 80.648),
        L.latLng(17.384, 78.4866),
        L.latLng(12.971, 77.5945)
      ];

      const options = {
        waypoints,
        lineOptions: {
          styles: [{ color: "blue", opacity: 0.6, weight: 4 }]
        },
        addWaypoints: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: false
      };

      const routingControl = L.Routing.control(options).addTo(map);

      // Clean up when the component unmounts
      return () => {
        if (map.hasLayer(routingControl)) {
          map.removeLayer(routingControl);
        }
      };
    }
  }, [map]);

  // Routing component doesn't render anything, so we return null
  return null;
}

export default Routing;
