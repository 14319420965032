import { useDispatch, useSelector } from "react-redux";
import PieCharts from "../charts/PieChart";
import TotalRevenueChart from "../charts/TotalRevenueChart";
import OrderChart from "../charts/OrderChart";
import CustomerMapchart from "../charts/CustomerMapChart";
import AvailablityCards from "../component/AvailablityCards";
import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
// import PageName from "../component/PageName";
import BookingCard from "../page/managebooking/BookingCard"
import { getAxiosWithToken } from "../axios/AxiosObj";
import { userLogOut } from "../store/features/userSlice";
// import axios from "axios";


const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allDataRaw, setAllDataRaw] = useState([]);

  const { isLogged } = useSelector((state) => state.user);
//  console.log("allDataRaw",allDataRaw);

  //-------------------------------------------------------------------------------------------------------
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard",
      });
      if (response.data.success) {
        setAllDataRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged]);

  // console.log("quotationOrderBooking Order:", allDataRaw?.chartOrder?.quotationOrder);
  // console.log("allDataRaw:", allDataRaw);
  // console.log("chartOrder:", allDataRaw?.chartOrder);
  // console.log("quotationOrder:", allDataRaw?.chartOrder?.quotationOrder);


  return (
    <div>
      <div className="text-left text-xl text-mainTxtColor font-semibold px-4 pt-4">
      Dashboard
    </div>
      {/* <PageName pageName="Dashboard" /> */}
      <BookingCard/>
      {/* <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4">
      <Link to={RoutePath.BookingRequest}>
        <Statusbar
          src={Icon_Order}
          count={orderCount || 0}
          title="Booking Requests"
          rating="4% (30 days)"
        />
        </Link>
        <Statusbar
          src={Delivered}
          count={orderCount || 0}
          title="Active Deliveries"
          rating="4% (30 days)"
        />
        <Statusbar
          src={Order}
          count={orderCount || 0}
          title="Total Cancelled "
          rating="25% (30 days)"
        />
        <Statusbar
          src={Group}
          count={`Rs.${orderCount || 0}`}
          title="Total Revenue"
          rating="25% (30 days)"
        />
      </div> */}
      <AvailablityCards />
      <div className="lg:grid grid-cols-6 gap-6 sm:py-4 lg:mx-4">
        <div className="col-span-3 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <PieCharts orderCount={0} CustomerCount={0} allDataRaw={allDataRaw} />
        </div>
        <div className="col-span-3 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <OrderChart allDataRaw={allDataRaw} />
        </div>
        <div className="col-span-4 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <TotalRevenueChart  allDataRaw={allDataRaw}/>
        </div>
        <div className="col-span-2 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <CustomerMapchart allDataRaw={allDataRaw}/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
