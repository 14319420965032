import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { DynamicTable } from "../../../tables/DynamicTable";
import { useNavigate } from "react-router-dom";
import PageName from "../../../component/PageName";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { userLogOut } from "../../../store/features/userSlice";
import { formateDate, formatDateToISO } from "../../../function/utilies";
import QuotationCard from "../QuotationCard";
import Pagination from "../../../component/Pagination";
import SearchBar from "../../../component/props/SearchBar";

const ActiveQuotation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [allBooking, setAllBooking] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  //------------------------------------All quotation Details----------------------------//
  const QuotationFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `quotation/all?status=2&page=${pageNumber}&perPage=${25}&search=${
          searchValue || " "
        }`,
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data);
        settotalCount(response.data?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      QuotationFetchDeatils();
    }
  }, [isLogged, deleteDeatils, pageNumber, searchValue]);

  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      // let senderDetails = booking.senderDetails[0];
      // let receiverDtails = booking.receiverDetails[0];
      let formattedDate = formatDateToISO(formateDate(booking.quotationDate));
      return {
        item01: booking.quotationOrderId || "",
        item02: formattedDate || "",
        item03: booking.fromPersonName || "",
        item04: booking.fromAddress?.slice(0, 10) || "",
        itemto: "to",
        item05: booking.toPersonName || "",
        item06: booking.toAddress?.slice(0, 10) || "",
        item07: booking.mobile || "",
        item08: booking.toCustomerName || "",
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        // itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.quotationOrderId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);

  const Head = [
    "S.No.",
    "Booking ID",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Contact",
    "Action",
  ];

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div className="w-full">
      <PageName pageName="Active Deliveries" />
      <QuotationCard isActive="active" />
      {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
        <TitleFilter />
      </div> */}
      <SearchBar handleInputChange={handleInputChange} value={searchValue} />

      <DynamicTable
        tableHeadRow={Head}
        data={allBooking}
        DeleteUrl={"quotation/delete"}
        eyeButton={RoutePath.EditCustomerQuotationDetailLink}
        editButton={RoutePath.EditCustomerQuotationDetailLink}
      />
      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        dataLength={totalCount}
      />
    </div>
  );
};

export default ActiveQuotation;
