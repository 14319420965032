
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DynamicTable } from "../../../tables/DynamicTable";
import PageName from "../../../component/PageName";
import BookingCard from "../BookingCard";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { userLogOut } from "../../../store/features/userSlice";
import { formateDate, formatDateToISO } from "../../../function/utilies";
import { RoutePath } from "../../../router/RoutePath";
import Pagination from "../../../component/Pagination";

const TotalCanceled = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);

  const [allBooking, setAllBooking] = useState([]);
  const [totalCount, settotalCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
    //------------------------------------All Booking Details----------------------------//
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `booking/all?status=3&page=${pageNumber}&perPage=${25}`,
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data);
        settotalCount(response.data?.totalCount)
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils,pageNumber]);
 
  
  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      // let senderDetails = booking.senderDetails[0];
      // let receiverDtails = booking.receiverDetails[0];
      let formattedDate = formatDateToISO(formateDate(booking.bookingDate));
      return {
        item01: booking.bookingOrderId || '', 
        item02: formattedDate || '', 
        item03: booking.fromPersonName || '', 
        item04: booking.fromAddress?.slice(0, 10) || '', 
        itemto: "to",
        item05: booking.toPersonName || '', 
        item06: booking.toAddress?.slice(0, 10) || '', 
        item07: booking.mobile || '',
        item08: booking.toCustomerName || '',
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        // cancel: <DoNotDisturbOffOutlinedIcon />,
        // itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.bookingOrderId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);
  const Head = [
    "S.No.",
    "Booking ID",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Status",
    "Action",
  ];

  return (
    <div className="w-full">
      <PageName pageName="Canceled Order" />
      <BookingCard isActive="cancelled"/>
      <DynamicTable
        tableHeadRow={Head}
        data={allBooking}
        DeleteUrl={"booking/delete"}
        eyeButton={RoutePath.EditBookOrderLink}
        editButton={RoutePath.EditBookOrderLink}
      />
       <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        dataLength={totalCount}
      />
    </div>
  );
};

export default TotalCanceled;
