import React from "react";
import profile from "../../../img/profile.jpg";
import { useParams } from "react-router-dom";
import { BackButton} from "../../../component/PrimeButton";

const EditStaffDetail = () => {
  const params = useParams();

  return (
    <div>
      <div className="bg-backgroundColor p-3 md:p-8">
        <div className="flex justify-between items-center">
        <BackButton btnName='Edit User Details'/>
        </div>
        <form>
          <div className="md:grid grid-cols-6  gap-4 text-start text-mainTxtColor">
            <div className="col-span-6 gap-6 sm:flex items-center text-center text-mainTxtColor p-3 ">
                <div className="flex justify-center">
                <img
                  src={profile}
                  alt="user.name"
                  className=" w-32 rounded-full bg-slate-100 ring-4 ring-primeryBgColor"
                  loading="lazy"
                />
                </div>
                <div className="text-2xl p-2 text-primeryBgColor">
                  <strong>User Name {params?.username}</strong>
                </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                Contact Number
              </label>
              <input
                value={'Number'}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-3 gap-6 lg:flex items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                Email ID
              </label>
              <input
                value={'Emai@gmail.com'}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className="w-36 md:flex-none text-sm font-medium "
              >
                Locality / Street
              </label>
              <input
                value={'Address'}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                City / Town / Vill
              </label>
              <input
                value={'City / Town / Vill'}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                District
              </label>
              <input
                value={'District'}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                State
              </label>
              <input
                 value={'State'}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                Pin Code
              </label>
              <input
                 value={'Pin Code'}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                for="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                ID Proof
              </label>
              <input
                 value={'Number'}
                 className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                disabled
              />
            </div>
          </div>
          <div className=" text-white mt-4 ">
            <button className="bg-green-800 p-2 px-5 mx-2 rounded-xl">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditStaffDetail;
