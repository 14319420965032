import React, { useEffect, useState } from "react";
// import { TitleFilter } from "../../../component/TitileFilter";
import { Statusbar } from "../../../component/StatusBar";
import Delivered from "../../../img/Delivered.svg";
import Icon_Order from "../../../img/Icon_Order.svg";
// import { DynamicTable } from "../../../tables/DynamicTable";
import PageName from "../../../component/PageName";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../router/RoutePath";
import { PrimeButton } from "../../../component/PrimeButton";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../store/features/userSlice";
import { OrderSelectionTable } from "./OrderSelectionTable";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../../component/InputError";
import DeliveryCard from "../DeliveryCard";

const initialValues = {
  driverId: "",
  vehicleId: "",
  bookingId: "",
  deviceId: "",
};

const AssignQuotationDelivery = () => {
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderIds, setOrderIds] = useState([]);
  const [tableUpdate, setTableUpdate] = useState(false);

  const ConstomerCreateSchema = Yup.object({
    driverId: Yup.string().required("Driver should not be empty"),
    vehicleId: Yup.number().required("vehicle  should not be empty"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    // validationSchema: ConstomerCreateSchema,

    onSubmit: async (values, action) => {
      const data = {
        driverId: values.driverId,
        vehicleId: values.vehicleId,
        quotationId: orderIds,
        deviceId: values.vehicleId,
      };

      // console.log("data", data);
      // return false
      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "assign/driver/to/booking", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setTableUpdate(!tableUpdate);
          action.resetForm();
          // contactData();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
      }
    },
  });

  const tableHead = [
    "Select",
    "S. No",
    "Order Id",
    "Name",
    "Start Station",
    "",
    "Name",
    "Destination Station",
  ];
  //------------------------------------All Booking Details----------------------------//
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [allBooking, setAllBooking] = useState([]);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `quotation/all?&page=${1}&perPage=${25}`,
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils, tableUpdate]);

  //------------------------------------------order -----------------------------------///

  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      // let senderDetails = booking.senderDetails[0];
      // let receiverDtails = booking.receiverDetails[0];
      return {
        item01: booking.quotationOrderId,
        item02: booking.fromPersonName || "",
        // item03: startStation,
        item03: booking.StartStationName,
        itemto: "to",
        item05: booking.toPersonName || "",
        item06: booking.EndStationName,
        checkBox: "checkbox",
        unqiueKey: booking.quotationOrderId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);

  //-------------------------------------------------- get Driver Details -----------------------------------------------//
  const [dataLoading, setDataLoading] = useState(false);
  const [allDriverRaw, setallDriverRaw] = useState([]);
  const driverManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "driver/all?type=available",
    });
    if (response && response.data && response.data.data) {
      setallDriverRaw(response.data.data);
      // setDriverCount(response.data.count || 0);
    } else {
      alertResponseMsgError(response.data.msg);
    }
  };
  useEffect(() => {
    driverManageData();
  }, [dataLoading]);
  //-------------------------------------------------- get Vehicle Details -----------------------------------------------//
  const [allVehicleData, setallVehicleData] = useState([]);

  const vehicleManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "vehicle/all?type=available",
    });
    if (response && response.data && response.data.data) {
      setallVehicleData(response.data.data);
    }
  };

  useEffect(() => {
    vehicleManageData();
  }, [dataLoading]);

  //------------------------------------All station Details----------------------------//
  const [stationDetail, setStationDetail] = useState([]);

  const stationFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "station/all",
      });
      if (response.data.success) {
        setStationDetail(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      stationFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  // console.log("allDriverRaw", allDriverRaw);
  return (
    <div>
      <div>
        <PageName pageName="Manage Delivery" />
        <DeliveryCard isActive="quotaiton" />

        <div>
          <div className="flex flex-wrap justify-evenly gap-2 sm:justify-between items-center px-4">
            <div className="flex items-center gap-2">
              <label className="md:flex-none text-sm font-medium">Driver</label>
              <select
                id="driverId"
                name="driverId"
                value={values.driverId}
                onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allDriverRaw.map((driver) => (
                  <option key={driver.userId} value={driver.userId}>
                    {driver.firstName +
                      " " +
                      driver.middleName +
                      " " +
                      driver.lastName +
                      " (" +
                      driver.userId +
                      ")" +
                      (driver.booked ? " - Booked" : "")}
                  </option>
                ))}
              </select>
              <InputError error={errors.driverId} touched={touched.driverId} />
            </div>
            <div className="flex items-center gap-2">
              <label className=" text-sm font-medium ">Vehicle</label>
              <select
                id="vehicleId"
                name="vehicleId"
                value={values.vehicleId || " "}
                onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allVehicleData.map((vehicle) => (
                  <option
                    className="p-4"
                    kay={vehicle.vehicleId}
                    value={vehicle.vehicleId}
                  >
                    {vehicle.vehicleModel + " (" + vehicle.ownerName + ")"}
                  </option>
                ))}
              </select>
              <InputError
                error={errors.vehicleId}
                touched={touched.vehicleId}
              />
            </div>
            <div className="flex items-center gap-2">
              <label className=" md:flex-none text-sm font-medium ">
                Device
              </label>
              <select
                id="vehicle"
                name="vehicle"
                value={values.manufactureYear}
                onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allVehicleData.map((driver, index) => (
                  <option
                    className="p-4"
                    kay={driver.vehicleId}
                    value={driver.vehicleId}
                  >
                    {driver.vehicleModel + " (" + driver.ownerName + ")"}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="flex items-center gap-2">
              <label className=" md:flex-none text-sm font-medium ">
                Station
              </label>
              <select
                id="station"
                name="station"
                // value={values.manufactureYear}
                // onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {stationDetail.map((station, index) => (
                  <option className="p-4" kay={index} value={station.stionId}>
                    {station.stationName}
                  </option>
                ))}
              </select>
            </div> */}
            <div onClick={handleSubmit} className="flex  gap-4">
              <PrimeButton btnName="Add" />
            </div>
          </div>
          <OrderSelectionTable
            tableHeadRow={tableHead}
            data={allBooking}
            setOrderIds={setOrderIds}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignQuotationDelivery;
