import React, { useState, useEffect } from "react";
import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined';const LoadingTable = () => {
  const [notFound, setNotFound] = useState(false);

  const numDivs = 8;

  const divs = (key) => {
    return (
      <div key={key} className="grid grid-cols-6 gap-4 animate-pulse">
        <div className="h-2 bg-slate-400 col-span-2"></div>
        <div className="h-2 bg-slate-400 col-span-1"></div>
        <div className="h-2 bg-slate-400 col-span-2"></div>
        <div className="h-2 bg-slate-400 col-span-1"></div>
      </div>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotFound(true);
    }, 3000);

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="shadow p-4 w-full mx-auto">
      <div className=" flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="space-y-8">
            {notFound ? (
              <div className="py-24 text-2xl w-full gap-2 text-slate-400">
                <SubtitlesOffOutlinedIcon />
                <h1>Data Not Found</h1>{" "}
              </div>
            ) : (
              [...Array(numDivs)].map((_, index) => divs(index))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingTable;
