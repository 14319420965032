import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
import customer from "../../img/customer.svg";
import totaldriver from "../../img/totaldriver.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";

const DriverCard = ({ isActive,count ,allDriverRaw,allDriverRaw2}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [active, setSctive] = useState();
  const [total, settotal] = useState();
  const [deactivate, setdeactivate] = useState();
  const [blacklist, setblacklist] = useState();

  // console.log("allBookingRaw", allBookingRaw);
  //   console.log("request", request);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=driver",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data.driver);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      setSctive(allBookingRaw.available);
      settotal(allBookingRaw?.total);
      setdeactivate(allBookingRaw.deactivate);
      setblacklist(allBookingRaw?.blacklist);
    }
  }, [allBookingRaw]);
  return (
    <>
      <div className="grid md:grid-cols-4  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div>
          <Link to={RoutePath.Driver}>
            <Statusbar
              src={customer}
              count={active?.count || 0}
              isActive={isActive ==="available"}
              title="Available Drivers"
              rating={`${Math.floor(active?.percentage)}%`}
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.TotalDriver}>
            <Statusbar
            isActive={isActive ==="total"}
              src={totaldriver}
              count={total?.count || 0}
              title="Total Driver"
              rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.DeactiveDriver}>
            <Statusbar
            isActive={isActive ==="deactive"}
              src={totaldriver}
              count={deactivate?.count || 0}
              title="Deactive Driver"
              // rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.BlockDriver}>
            <Statusbar
            isActive={isActive ==="block"}
              src={totaldriver}
              count={blacklist?.count || 0}
              title="Blacklisted Driver"
              // rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default DriverCard;
