import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';
const size = {
  width: 200,
  height: 100,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieCharts({allDataRaw}) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [totalBookings, settotalBookings] = useState([]);
  const [totalQuotations, settotalQuotations] = useState([]);
  const [bookingrevenue, setbookingrevenue] = useState([]);
  const [quotationrevenue, setquotationrevenue] = useState([]);
  const [cancelledOrders, setcancelledOrders] = useState([]);


  // const { isLogged } = useSelector((state) => state.user);
  //------------------------------------------------------------------------------------------------
 useEffect(()=>{
  if(allDataRaw){
    settotalBookings(allDataRaw?.totalBookings);
    settotalQuotations(allDataRaw?.totalQuotations);
    setbookingrevenue(allDataRaw?.totalRevenue?.booking);
    setquotationrevenue(allDataRaw?.totalRevenue?.quotation)
    setcancelledOrders(allDataRaw?.cancelledOrders)

  }
 },[allDataRaw])
  //----------------------------------------------------------------------------------

  const totalBooking = [
    { value: totalBookings?.value1 , color: 'green', },
    { value: totalQuotations?.value1 },
  ];
  const customerGrowth = [
    { value: cancelledOrders?.bookings, color: 'green' },
    { value: cancelledOrders?.quotations},
  ];
  const totalRevenue = [
    { value: bookingrevenue?.total, color: 'green' },
    { value: quotationrevenue?.total },
  ];

  return (
    <div>
        <div className='flex flex-wrap justify-around '>
        {/* <h1>Pie Chart</h1> */}
        <div className="col-span-2"></div>
        <div className='col-span-1 flex justify-center gap-2 text-blue-500'>
        {/* <input type="checkbox" className="default:ring-2 ..." /> */}
        <label>Quotation</label>
        </div>
        <div className='col-span-2 flex justify-center gap-2 text-green-500'>
        {/* <input type="checkbox" className="default:ring-2 ..." /> */}
        <label>Booking</label>
        </div>
        </div>
    <div className='grid sm:grid-cols-3 place-items-start bg-white mt-4 ml-20 sm:ml-10 '>
        <div className='col-span-1 text-blue-500 text-left'>
      <PieChart series={[{ data: totalBooking, innerRadius: 25 }]} {...size}>
        {/* <PieCenterLabel>{`${Math.floor(totalBooking?.request?.percentage)}%`}</PieCenterLabel> */}
      </PieChart>
      <h1 className='py-2 px-4'> Bookings</h1>
      </div>
      <div className='text-blue-500 text-left'>
      <PieChart series={[{ data: customerGrowth, innerRadius: 25 }]}  {...size}>
        {/* <PieCenterLabel>{`${Math.floor(customerData?.total?.percentage)}%`}</PieCenterLabel> */}
      </PieChart>
      <h1 className='py-2 px-4'>Cancelled</h1>
      </div>
      <div className='text-blue-500 text-left'>
      <PieChart series={[{ data: totalRevenue, innerRadius: 25 }]} {...size}>
        {/* <PieCenterLabel>{`${Math.floor(revenueData?.revenue?.percentage)}%`}</PieCenterLabel> */}
      </PieChart>
      <h1 className='p-2'> Revenue</h1>
      </div>
    </div>
    </div>
  );
}
