import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
import TrackVehical from "../../img/TrackVehical.svg";
// import fuel from "../../../img/fuel.svg";
// import maintenance from "../../../img/maintenance.svg";
import TowTruck from "../../img/TowTruck.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";

const VehicleCard = ({ isActive,allRawData,allRawData2}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  // const [active, setSctive] = useState([]);
  // const [cancelled, setcancelled] = useState([]);
  const [total, settotal] = useState();
  const [availabe, setAvailabe] = useState([]);
  const [deactivate, setdeactivate] = useState([]);
  const [blacklist, setblacklist] = useState([]);
  

  // console.log("total", total);
  //   console.log("request", request);

  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=vehicle",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data.vehicle);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      // alertResponseMsgError('An error occurred');
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      // setSctive(allBookingRaw.active);
      // setcancelled(allBookingRaw.cancelled);
      settotal(allBookingRaw?.total);
      setAvailabe(allBookingRaw?.available);
      setdeactivate(allBookingRaw.deactivate);
      setblacklist(allBookingRaw?.blacklist);
    }
  }, [allBookingRaw]);
  return (
    <>
      <div className="grid sm:grid-cols-2 md:grid-cols-4  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div>
          <Link to={RoutePath.Vehicle}>
            <Statusbar
              count={availabe?.count || 0}
              isActive={isActive ==="active"}
              src={TrackVehical}
              title="Available Vehicles"
              // rating="4% "
              rating={`${Math.floor(availabe?.percentage)}%`}
            />
          </Link>
        </div>
        {/* <div>
            <Link to={RoutePath.FuelRefill}>
              <Statusbar
                src={fuel}
                count="120 L"
                title="Fuel Refill"
                rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.Maintenance}>
              <Statusbar
                src={maintenance}
                count="Rs 12800"
                title="Maintenance"
                rating="25% (30 days)"
              />
            </Link>
          </div> */}
        <div>
          <Link to={RoutePath.TotalVehicle}>
            <Statusbar
              src={TowTruck}
              isActive={isActive ==="total"}
              count={total?.count || 0}
              title="Total Vehicles"
              rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.DecativeVehicle}>
            <Statusbar
              src={TowTruck}
              isActive={isActive ==="deactive"}
              count={deactivate?.count || 0}
              title="Deactive Vehicles"
              // rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.BlockVehicle}>
            <Statusbar
              src={TowTruck}
              isActive={isActive ==="block"}
              count={blacklist?.count || 0}
              title="Block"
              // rating={`${Math.floor(total?.percentage)}%`}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default VehicleCard;
