import React, { useEffect, useState } from "react";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

import { DynamicTable } from "../../tables/DynamicTable";
import { PrintButton } from "../../component/PrimeButton";
import PageName from "../../component/PageName";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  formateDate,
  formatDateToISO,
} from "../../function/utilies";
import { RoutePath } from "../../router/RoutePath";

const CustomerInvoice = () => {
  const customerHead = [
    "S.No.",
    "Invoice ID",
    "Date",
    "Name",
    "Order Amount",
    "Total Amount",
    "Remaining Amount",
    "Invoice",
  ];
  const [dataLoading, setDataLoading] = useState(false);

  const [finalData, setFinalData] = useState([]);
  const [allBooking, setAllBooking] = useState([]);

  const [invoiceType, setinvoiceType] = useState();
  const [inputSearch, setInputSearch] = useState();
  const [finalTableData, setFinalTableData] = useState([]);

  console.log("finalData",finalData);
  const submitInvoice = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "invoice/all", // Adjust the API endpoint as needed
        // data: data,
      });
      // API response 200 201
      if (response.data.success) {
        setFinalData(response.data.data);
      } else {
        alertResponseMsgError(response.data.msg);
      }
    } catch (error) {
      console.error(error);
      alertResponseMsgError(error.response.data.msg);
    }
  };
  const handleChange = (e) => {
    setinvoiceType(e.target.value);
    const InputValue = finalData.filter(
      (booking) => booking.firstName === invoiceType
    );
    setInputSearch(InputValue);
    console.log("invoiceType", invoiceType);
  };

  useEffect(() => {
    submitInvoice();
  }, [dataLoading]);

  useEffect(() => {
    if (inputSearch !== undefined) {
      setFinalTableData(inputSearch);
    } else {
      setFinalTableData(finalData);
    }
  }, [inputSearch, finalData]);

  useEffect(() => {
    const modifiedData = finalTableData.map((invoice) => {
      const formattedDate = formatDateToISO(formateDate(invoice.created_at));
      return {
        item01:  invoice.invoiceId,
        item02: formattedDate,
        item03: `${
          invoice.firstName +
            " " +
            (invoice.secondName || " ") +
            " " +
            invoice.lastName || "Paid"
        }`,
        item04: invoice.allOrderAmount,
        item05: invoice.invoiceTotalAmount,
        item06: `${invoice.invoiceRemainingAmount || "Paid"}`,
        item10: <ReceiptLongOutlinedIcon />,
        unqiueKey: invoice.invoiceId,
      };
    });
    setAllBooking(modifiedData);
  }, [finalTableData]);

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-4">
        <div className="flex justify-between items-center px-2">
          <PageName pageName="Customer Invoice" />
          <div className="flex gap-6  items-center">
            <select
              value={invoiceType}
              name="type"
              onChange={handleChange}
              className="w-full h-full rounded-inputBRedius bg-inputBgColor p-2"
            >
              <option value="">Order type</option>
              <option value="book">Booking Order</option>
              <option value="qut">Quotation Order</option>
            </select>
            <input
              type="text"
              value={invoiceType}
              onChange={handleChange}
              className="rounded-inputBRedius bg-inputBgColor p-2"
            />
            <PrintButton id="contentToPrint" />
          </div>
        </div>
        <div id="contentToPrint">
          <DynamicTable
            tableHeadRow={customerHead}
            data={allBooking}
            InvoiceButton={RoutePath.GstInvoiceBillLink}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoice;
