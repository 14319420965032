import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";

const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function TotalRevenueChart({ allDataRaw }) {
  const [currentYear, setCurrentYear] = useState([]);
  const [lastYear, setLastYear] = useState([]);

  // Calculate current and previous year dynamically
  const thisYear = new Date().getFullYear();
  const previousYear = thisYear - 1;

  useEffect(() => {
    if (allDataRaw && allDataRaw.totalRevenue?.monthly) {
      const { currentYear: cy, lastYear: ly } = allDataRaw.totalRevenue.monthly;
      setCurrentYear(Array.isArray(cy) ? cy : []);
      setLastYear(Array.isArray(ly) ? ly : []);
    }
  }, [allDataRaw]);

  return (
    <div className="text-left">
      <h1>Total Revenue</h1>
      {currentYear.length === 0 && lastYear.length === 0 ? (
        <p>No data available</p>
      ) : (
        <LineChart
          height={300}
          series={[
            { data: currentYear, label: `${thisYear}`, color: "#4CAF50" },
            { data: lastYear, label: `${previousYear}`, color: "#FF5B5B" },
          ]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
        />
      )}
    </div>
  );
}
