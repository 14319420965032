import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../axios/AxiosObj";
import { useSelector } from "react-redux";

const Dropdown = ({ label, name, value, SetStationId }) => {
  const {  isLogged } = useSelector((state) => state.user);

  const [allStationRaw, setAllStationRaw] = useState([]);
  const stationData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "station/all",
    });
    if (response && response.data && response.data.data) {
      setAllStationRaw(response.data.data);
    }
  };

  useEffect(() => {
    if(isLogged){

      stationData();
    }
  }, [isLogged]);

  const handleSelectChange = (event) => {
    const selectedStationId = event.target.value; // Get the selected station ID
    SetStationId(selectedStationId); // Send the selected station ID to the parent component
  };

  return (
    <div>
      <select
        value={value}
        name={name}
        onChange={handleSelectChange} // Handle change on select
        className="w-full rounded-inputBRedius bg-inputBgColor p-2"
      >
        <option value="">Select {label}</option>
        {allStationRaw?.map((station) => (
          <option key={station.stationId} value={station.stationId}>
            {station.stationName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
