import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useState, useEffect } from "react";

export default function CustomerMapchart({ allDataRaw }) {
  const [chartData, setChartData] = useState([]);
  const [xLabels, setXLabels] = useState([]);

  console.log("quotationOrderBooking innnnn:", allDataRaw?.chartOrder?.quotationOrder);

  useEffect(() => {
    const bookingOrder = allDataRaw?.chartOrder?.bookingOrder ?? [];

    // Check if bookingOrder is an array and has data
    if (Array.isArray(bookingOrder) && bookingOrder.length > 0) {
      const labels = bookingOrder.map((item) => item.day || "Unknown");
      const values = bookingOrder.map((item) => item.value || 0);

      setXLabels(labels);
      setChartData(values);
    } else {
      console.warn("Invalid or missing bookingOrder data");
      setXLabels([]); // Set empty labels
      setChartData([]); // Set empty data
    }
  }, [allDataRaw]); // Dependency on allDataRaw ensures it updates when data changes

  return (
    <div className="text-left">
      <div className="flex justify-between">
        <h1>Booking Order</h1>
      </div>

      {/* Check if chartData is available before rendering the chart */}
      {chartData.length > 0 && xLabels.length > 0 ? (
        <BarChart
          height={300}
          series={[
            {
              data: chartData, // Dynamically set bar values
              label: "Bookings",
              color: "#4CAF50", // Optional customization
            },
          ]}
          xAxis={[
            {
              data: xLabels, // Dynamically set labels
              scaleType: "band",
              label: "Days of the Week", // Optional customization
            },
          ]}
          yAxis={[{ label: "Bookings", max: Math.max(...chartData, 100) }]} // Adjust max value
        />
      ) : (
        <p>No data available to display</p> // Message if no data is available
      )}
    </div>
  );
}
