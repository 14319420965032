import React, { useEffect, useState } from "react";
import { Statusbar } from "../../component/StatusBar";
import { Link, useNavigate } from "react-router-dom";
import Delivered from "../../img/Delivered.svg";
import Icon_Order from "../../img/Icon_Order.svg";
// import fuel from "../../../img/fuel.svg";
// import maintenance from "../../../img/maintenance.svg";
import { RoutePath } from "../../router/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { userLogOut } from "../../store/features/userSlice";

const DeliveryCard = ({ isActive }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, deleteDeatils } = useSelector((state) => state.user);
  const [allBookingRaw, setAllBookingRaw] = useState([]);
  const [bookingCount, setBookingCount] = useState([]);
  const [qutCount, setQutcount] = useState([]);
  const [finalCount, setFinal] = useState();
  console.log("a")
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "dashboard?type=delivery",
      });
      if (response.data.success) {
        setAllBookingRaw(response.data.data.delivery);
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
      if (
        error.response.status &&
        error.response.status === 401 &&
        error.response.statusText &&
        error.response.statusText === "Unauthorized"
      ) {
        navigate("/");
        try {
          await dispatch(userLogOut());
          // Redirect to root page after successful logout
        } catch (error) {
          console.error("Logout failed:", error);
          // Handle error as needed
        }
      }
    }
  };
  useEffect(() => {
    if (isLogged) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  useEffect(() => {
    if (allBookingRaw) {
      setBookingCount(allBookingRaw?.nonDeliveryBooking);
      setQutcount(allBookingRaw?.nonDeliveryQuotation);
      setFinal(allBookingRaw?.total);
      // setrevenue(allBookingRaw.revenue);
    }
  }, [allBookingRaw]);
  return (
    <>
      <div className="grid sm:grid-cols-3  justify-items-stretch gap-6 p-4 cursor-pointer">
        <Link to={RoutePath.AssignDelivery}>
          <Statusbar
            isActive={isActive === "booking"}
            src={Icon_Order}
            count={bookingCount?.count || 0}
            title="Booking Delivery"
            rating={`${Math.floor(bookingCount?.percentage)}%`}
          />
        </Link>
        <Link to={RoutePath.AssignQuotationDelivery}>
          <Statusbar
            isActive={isActive === "quotaiton"}
            src={Icon_Order}
            count={qutCount?.count || 0}
            title="Quotation Delivery"
            rating={`${Math.floor(qutCount?.percentage)}%`}
          />
        </Link>
        <Link to={RoutePath.FinalDelivery}>
          <Statusbar
            isActive={isActive === "final"}
            src={Delivered}
            count={finalCount?.count || 0}
            title="Final Delivery"
            rating={`${Math.floor(finalCount?.percentage)}%`}
          />
        </Link>
      </div>
    </>
  );
};

export default DeliveryCard;
